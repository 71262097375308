import HorizontalMapSvg from '@/assets/horizontalMap.svg'
import { colors } from '@/constants'
import styled from 'styled-components'
import { MapProps } from '../Map/ComunasChileMap'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

type HorizontalMapProps = {
  regionResults: {
    name: string
    realName: string
    selectedColor: string
    code: number
  }[]
  children?: React.ReactNode
} & MapProps

export default function HorizontalMap({ regionResults }: HorizontalMapProps) {
  return (
    <Wrapper className="min-w-[529px]">
      <Circles>
        {regionResults.map((region) => (
          <TooltipProvider>
            <Tooltip delayDuration={200}>
              <TooltipTrigger>
                <Circle
                  key={`${region.code}-${region.selectedColor}`}
                  selectedColor={region.selectedColor}
                >
                  {region.name}
                </Circle>
              </TooltipTrigger>
              <TooltipContent>
                <p>{region.realName}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ))}
      </Circles>
      <Map />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  max-width: 100%;
  overflow-x: auto;
`

const Map = styled(HorizontalMapSvg).attrs({
  className: 'xl:px-6 mgw:px-8 -mt-6 mgw:-mt-8',
})`
  height: 100%;
  width: 100%;
`

const Circle = styled.div.attrs<{ selectedColor?: string }>({
  className: 'min-w-6 xl:w-8 p-0.5 aspect-square text-[10px] xl:text-sm xl:min-w-8',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ selectedColor }) => selectedColor || colors.whiteDch};
  border: ${({ selectedColor }) => (!selectedColor ? `2px solid ${colors.gray3Dch}` : 'none')};
  color: ${({ selectedColor }) => (!selectedColor ? colors.gray3Dch : colors.whiteDch)};
  font-weight: 600;
`

const Circles = styled.div.attrs({
  className: 'gap-2 justify-between xl:px-2 mb-3 w-[105%]',
})`
  font-family: 'JetBrains Mono', monospace;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 0;
`
