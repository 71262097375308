import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { circunscripcionesProvinciales } from '@/stores/useTerritorios'
import { type SelectProps } from '@radix-ui/react-select'
import { ChevronDown } from 'lucide-react'

type RegionSelectorProps = {
  onClick: (value: string) => void
  className?: string
  showIcon?: boolean
  useGS?: boolean
  comunasName?: string[]
} & SelectProps

export default function ProvinciaSelector({
  onClick,
  value,
  className,
  showIcon = true,
  useGS = true,
}: RegionSelectorProps) {
  const regionesFinal = circunscripcionesProvinciales.sort((a, b) =>
    a.nombre.localeCompare(b.nombre)
  )
  return (
    <Select onValueChange={onClick} value={value}>
      <SelectTrigger
        className={cn(`w-[180px] ${className}`, {
          'justify-start gap-1': !showIcon,
        })}
        showIcon={showIcon}
      >
        <SelectValue placeholder="Regiones" className="underline underline-offset-1" />
        {!showIcon && <ChevronDown className="h-4 w-4 opacity-50" />}
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Regiones</SelectLabel>
          {regionesFinal.map((option) => (
            <SelectItem key={option.codigo} value={String(option.codigo)}>
              <p>{option.nombreCorto || option.nombre}</p>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
