import styled from 'styled-components'
import CountdownBackground from '@/assets/iframe/countdown-background.png'
import CountDownHands from '@/assets/iframe/countdown-hands.png'
import LogoDch from '@/assets/logoDchWhite.svg'
import Title from '@/assets/iframe/elecciones-municipales.svg'
import { colors } from '@/constants'
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown'
import Arrow from '@/assets/arrow.svg'
import ReactGA from 'react-ga4'

function Countdown({
  votationStartDt,
  setHasVotationStarted,
  medio,
}: {
  votationStartDt: Date
  setHasVotationStarted: (status: boolean) => void
  medio: string
}) {
  return (
    <Wrapper>
      <SubWrapper>
        <div className="self-end">
          <a
            className="relative"
            href="https://decidechile.cl/municipales2024"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => ReactGA.event('if_municipales2024_redireccion', { medio: medio })}
          >
            <LogoDch className="w-[176px] ifr:w-[234px] h-auto"></LogoDch>
          </a>
        </div>
        <div className="flex flex-col gap-5 px-4 ifr:px-6">
          <Title className="w-full sifr:w-[368px] ifr:w-[490px] h-auto"></Title>
          <div className="flex flex-col">
            <SubTitle className="pt-1">Sigue el minuto a minuto de estas elecciones</SubTitle>
            <SubTitle className="pb-1">de Gobernadores, Alcaldes, Concejales y Cores</SubTitle>
          </div>
          <StyledFlipClockCountdown
            to={votationStartDt}
            labels={['Días', 'Horas', 'Min.', 'Seg.']}
            showSeparators={false}
            labelStyle={{ color: colors.whiteDch }}
            onComplete={() => setHasVotationStarted(true)}
            digitBlockStyle={{
              backgroundColor: colors.footerDch,
            }}
            dividerStyle={{ color: 'none' }}
          ></StyledFlipClockCountdown>
        </div>
        <div className="flex flex-col gap-2 mt-auto sifr:pr-6 self-end z-50">
          <MoreInfo>Más información y análisis en</MoreInfo>
          <a
            className="relative"
            href="https://decidechile.cl/municipales2024"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => ReactGA.event('if_municipales2024_redireccion', { medio: medio })}
          >
            <FooterBadge>decidechile.cl/municipales2024</FooterBadge>
            <Arrow className="absolute top-[10px] -right-[12px] ifr:top-5 ifr:-right-4 transform scale-75 ifr:scale-100" />
          </a>
        </div>
      </SubWrapper>
      <img
        src={CountDownHands}
        alt="Countdown Hands"
        className="w-full h-auto absolute bottom-[24px] sifr:bottom-[10px]"
      />
    </Wrapper>
  )
}

export default Countdown

const Wrapper = styled.div.attrs({
  className: 'w-full h-full  gap-5',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url(${CountdownBackground});
  background-size: cover;
`
const SubWrapper = styled.div.attrs({
  className: 'w-full h-full p-[16px] ifr:p-[20px] gap-5',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const SubTitle = styled.span.attrs({
  className: 'text-[14px] sifr:text-[17px] ifr:text-[22px] ',
})`
  background-color: ${colors.whiteDch};
  color: ${colors.blueDchDarker};
  display: inline-block;
  box-decoration-break: clone;
  padding-inline: 8px;
  font-weight: 700;
  line-height: 1.1;
  width: fit-content;
`

const StyledFlipClockCountdown = styled(FlipClockCountdown)`
  justify-content: center;
  @media (max-width: 449px) {
    gap: 4px !important;
    height: 86px;
  }

  .fcc__digit_block {
    font-size: 38px;
    width: 32px;
    @media (min-width: 600px) {
      font-size: 50px;
      width: 44px;
    }
    @media (max-width: 449px) {
      font-size: 20px;
      width: 28px;
      height: 60px;
    }
  }
  .fcc__label {
    font-size: 20px;
    @media (min-width: 600px) {
      font-size: 27px;
    }
    @media (max-width: 449px) {
      font-size: 16px;
    }
  }
  .fcc__digit_block {
    margin-right: 0 !important;
    &:nth-child(2) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    &:nth-child(3) {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`

const MoreInfo = styled.div.attrs({
  className: 'text-[12px] ifr:text-[16px] self-center',
})`
  color: ${colors.gray4Dch};
  font-family: 'JetBrains Mono';
  text-transform: uppercase;
  font-weight: 500;
`
const FooterBadge = styled.button.attrs({
  className:
    'sifr:h-[32px] ifr:h-[44px] ifr:leading-[30px] text-[16px] sifr:text-[20px] ifr:text-[25px]',
})`
  background-color: ${colors['blueDch']};
  color: ${colors['whiteDch']};
  border: 1px solid ${colors['whiteDch']};
  border-radius: 55px;
  display: inline-block;
  padding-inline: 2px;
  font-weight: bold;
  padding: 0 16px;
`
