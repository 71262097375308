import GCambioMantienePacto, { type Data } from '@/components/Web/GORE/CambioMantienePacto'
import { colors } from '@/constants'
import { buildCambioMantienePactoGORE } from '@/lib/buildDataViews'
import useElectionData from '@/stores/useElectionData'
import { regiones } from '@/stores/useTerritorios'
import { useMemo } from 'react'

export default function GoreCambioPacto() {
  const {
    files: { gore },
    comparador: { gore: gore2021 },
  } = useElectionData()

  const data: Data[] = useMemo(() => {
    if (!gore || !gore2021) return []
    return buildCambioMantienePactoGORE(gore, gore2021, 'cambia', 18)
  }, [gore, gore2021])

  const regionesData = useMemo(
    () =>
      regiones
        .filter((region) => region.codigo !== 17)
        .sort((a, b) => {
          if (a.orden && b.orden) {
            return a.orden - b.orden
          }
          return 0
        })
        .map((region) => {
          const defaultColor = data.length > 0 ? colors.gray2Dch : colors.gray1Dch
          return {
            realName: region.nombre || '',
            code: region.codigo,
            name: region.acronimo || region.nombre,
            selectedColor:
              data.find((item) => item.region_codigo === region.codigo)?.electo_2024.color ||
              defaultColor,
          }
        }),
    [data]
  )

  return (
    <GCambioMantienePacto
      title="Regiones que Cambian Pacto"
      data={data}
      regiones={regionesData}
      isMantiene={false}
    />
  )
}
