import styled from 'styled-components'
import { ReactNode } from 'react'
import { colors } from '@/constants'
import { cn } from '@/lib/utils'
import InfoToolTip from '../InfoToolTip'
import useRouting from '@/stores/useRouting'
import { ElectionType } from '@/types/Elections'

type ICard = {
  title: string
  statusLabel?: string
  children: ReactNode
  separatorColor?: string
  toggle?: ReactNode
  selector?: ReactNode
  selector2?: ReactNode
  showInfoToolTip?: boolean
  participationInfo?: ReactNode
}

export default function Card({
  title,
  children,
  toggle,
  selector,
  selector2,
  showInfoToolTip,
}: ICard) {
  return (
    <Wrapper className="max-w-full h-auto xl:w-[870px] xl:h-[440px] pb-10 bg-white relative">
      <div
        className={cn('justify-end xl:justify-between w-full items-center flex', {
          'py-1 xl:py-4': toggle || selector,
          'py-0 xl:py-2': !toggle && !selector && title,
        })}
      >
        <Title className="hidden xl:flex text-2xl gap-2">
          {title}
          {showInfoToolTip && (
            <InfoToolTip
              side={'right'}
              content="Se elige 1 Gobernador Regional por cada Región. Es elegido el candidato que saca la mayor votación sobre el 40%. Si ninguno saca esa votación hay segunda vuelta entre las dos primeras mayorías."
            />
          )}
        </Title>
        <div className="absolute -top-[52px] z-20 xl:static xl:top-0">{toggle && toggle}</div>
      </div>
      <div className="flex w-full justify-end xl:justify-between items-center mb-[6px]">
        {selector}
        {selector2}
      </div>
      <div
        className={cn({
          '-translate-y-4 xl:translate-x-0': selector2,
        })}
      >
        {children}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Title = styled.div`
  color: ${colors.blackDch};
  font-weight: 400;
`
