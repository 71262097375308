import { ElectionType } from '@/types/Elections'

export type Territorios = 'region-comuna' | 'circunscripcion-comuna'

type ElectionParams = {
  name: string
  longName: string
  menuName: string
  defaultTerritorio: string | number
  defaultComuna: string | number
  menuIcon?: string
  territorios: Territorios
}

export const electionParams: Record<ElectionType, ElectionParams> = {
  [ElectionType.GORE]: {
    name: 'Gobernadores',
    longName: 'Gobernadores',
    menuName: 'Gores',
    defaultTerritorio: 13,
    defaultComuna: 13101,
    menuIcon: '/menu/eleccion.svg',
    territorios: 'region-comuna',
  },
  [ElectionType.ALCALDES]: {
    name: 'Alcaldes',
    longName: 'Alcaldes',
    menuName: 'Alcaldes',
    defaultTerritorio: 13,
    defaultComuna: 13101,
    menuIcon: '/menu/eleccion.svg',
    territorios: 'region-comuna',
  },
  [ElectionType.CONCEJALES]: {
    name: 'Concejales',
    longName: 'Concejales',
    menuName: 'Concejales',
    defaultTerritorio: 13,
    defaultComuna: 13101,
    menuIcon: '/menu/eleccion.svg',
    territorios: 'region-comuna',
  },
  [ElectionType.CORE]: {
    name: 'Consejeros',
    longName: 'Consejeros Regionales',
    menuName: 'Cores',
    defaultTerritorio: 'Santiago IV',
    defaultComuna: 13101,
    menuIcon: '/menu/eleccion.svg',
    territorios: 'circunscripcion-comuna',
  },
}

export const TOTAL_ELECTOS = {
  [ElectionType.GORE]: 16,
  [ElectionType.ALCALDES]: 345,
  [ElectionType.CONCEJALES]: 2252,
  [ElectionType.CORE]: 302,
}
