import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip'
import { Info } from 'lucide-react'
import { cn } from '@/lib/utils'

export default function InfoToolTip({
  content,
  size = 12,
  side = 'bottom',
  classNameContent = '',
}: {
  classNameContent?: string
  content: string
  size?: number
  side?: 'top' | 'right' | 'bottom' | 'left'
}) {
  return (
    <TooltipProvider delayDuration={300}>
      <Tooltip>
        <TooltipTrigger className="pointer-events-auto xl:flex hidden">
          <Info size={size} color="#3c3c3c" />
        </TooltipTrigger>
        <TooltipContent side={side} align="center">
          <p className={cn('max-w-[150px]', classNameContent)}>{content}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
