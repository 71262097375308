import { Key, ReactNode } from 'react'
import styled from 'styled-components'

import CircleBase, { ICircle } from './Circle'

const range = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, i) => i + start)

export interface TCircleEventReturn<T> {
  data?: T
  key: Key
}

export type TOnCircleClick<T> = (event: TCircleEventReturn<T>) => void

export interface IMeshCircle<T = object> extends ICircle {
  children?: ReactNode
  opaque?: boolean
  width?: string
  key: Key
  data?: T
}

export interface ICircleMesh<T> {
  className?: string
  nrows?: number // Numero filas
  gap?: string // Espacio entre ciruclos
  onCircleClick?: TOnCircleClick<T>
  onCircleMouseEnter?: TOnCircleClick<T>
  onCircleMouseLeave?: TOnCircleClick<T>
  ncolumns?: number
  circles: IMeshCircle<T>[]
  width?: string // ancho circulo
}

const adaptCircles = <T,>({ circles, nrows }: { circles: IMeshCircle<T>[]; nrows: number }) => {
  const newCircles = [...circles]
  const ncolumns = Math.ceil(newCircles.length / nrows)
  const rest = newCircles.length % nrows
  const extraCircles = rest ? nrows - (newCircles.length % nrows) : rest
  const firstExtraCircleColumn = Math.floor((ncolumns - extraCircles) / 2)
  const extraCircleIndex = (column: number) => (column + 1) * nrows - 1
  const extraCirclesIndexes = range(
    firstExtraCircleColumn,
    firstExtraCircleColumn + extraCircles - 1
  ).map(extraCircleIndex)
  extraCirclesIndexes.forEach((i) => {
    newCircles.splice(i, 0, {
      children: '',
      color: 'transparent',
      key: `extra-${i}`,
    })
  })
  return newCircles
}

const CircleMesh = <T,>({
  className,
  gap = '6px',
  nrows = 2,
  circles,
  ncolumns = Math.ceil(circles.length / nrows),
  onCircleClick,
  onCircleMouseEnter,
  onCircleMouseLeave,
  width,
}: ICircleMesh<T>) => {
  const handleCircleClick = (e: TCircleEventReturn<T>) => {
    if (onCircleClick) onCircleClick(e)
  }
  const handleCircleEnter = (e: TCircleEventReturn<T>) => {
    if (onCircleMouseEnter) onCircleMouseEnter(e)
  }
  const handleCircleLeave = (e: TCircleEventReturn<T>) => {
    if (onCircleMouseLeave) onCircleMouseLeave(e)
  }

  return (
    <Wrapper className={className} gap={gap} ncolumns={ncolumns} nrows={nrows}>
      {circles.map(({ children, color, data, key, opaque = false }) => {
        const handleClick = () => {
          handleCircleClick({ data, key })
        }
        const handleMouseEnter = () => {
          handleCircleEnter({ data, key })
        }
        const handleMouseLeave = () => {
          handleCircleLeave({ data, key })
        }
        return (
          <Circle
            color={color}
            key={key}
            onClick={color !== 'transparent' ? handleClick : () => undefined}
            onMouseEnter={color !== 'transparent' ? handleMouseEnter : () => undefined}
            onMouseLeave={color !== 'transparent' ? handleMouseLeave : () => undefined}
            width={width}
          >
            {children}
          </Circle>
        )
      })}
    </Wrapper>
  )
}

export const Wrapper = styled.div<{ nrows: number; ncolumns: number; gap: string }>`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: ${({ nrows }) => `repeat(${nrows}, 1fr)`};
  grid-template-columns: ${({ ncolumns }) => `repeat(${ncolumns}, 1fr)`};
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => gap};
  padding-block: 12px;
`

const Circle = styled(CircleBase).attrs({
  className: '!w-[6px] sifr:!w-[10px] ifr:!w-[13px]',
})<IMeshCircle>`
  transition: opacity 0.1s;
`

export default CircleMesh
