import { PACTOS_ALCALDES, PACTOS_GORE } from '@/constants/pactos'
import { AlcaldesFile, CandidatoGore, ElectosPacto2021, GOREFile } from '@/types/Files'
import { AlcaldesPactoSummary, RegionesData } from '@/types/Iframe'
import { regiones, comunas } from '@/stores/useTerritorios'

export const codigosComunasDestacadas = [
  13101, 13127, 13120, 13108, 13201, 5101, 5109, 8101, 13114, 13123, 13130, 13119, 2101, 4102, 1101,
  9101,
]

export function formatSummaryGore(results: GOREFile | undefined) {
  const regionesDict: RegionesData = {}

  regiones.forEach((region) => {
    if (region.codigo === 17) return // Skip Gran Santiago

    regionesDict[region.codigo] = {
      selectedColor: undefined,
      acronimo: region.acronimo || '',
      nombre: region.nombreCorto || region.nombre,
      currentVotes: 0,
      electo: false,
      segundaVuelta: false,
      currentGanador: undefined,
      currentSegundos: [],
      orden: region.orden || 0,
    }
  })

  if (!results) return regionesDict

  results.forEach((result) => {
    if (result.electo) {
      regionesDict[result.cod_region].electo = true
      regionesDict[result.cod_region].selectedColor = PACTOS_GORE[result.cod_pacto]?.color
      regionesDict[result.cod_region].currentGanador = getCandidatoInfo(result)
      regionesDict[result.cod_region].currentVotes = result.votos
      regionesDict[result.cod_region].currentSegundos = []
    } else if (result.segunda_vuelta) {
      regionesDict[result.cod_region].selectedColor = null
      regionesDict[result.cod_region].segundaVuelta = true
      regionesDict[result.cod_region].currentGanador = null
      regionesDict[result.cod_region].currentSegundos.push(getCandidatoInfo(result))
    } else {
      const currentVotes = regionesDict[result.cod_region].currentVotes
      const hasElecto = regionesDict[result.cod_region].electo
      const hasSegundaVuelta = regionesDict[result.cod_region].segundaVuelta
      if (!hasElecto && !hasSegundaVuelta && result.votos > currentVotes) {
        regionesDict[result.cod_region].currentVotes = result.votos
        regionesDict[result.cod_region].selectedColor = PACTOS_GORE[result.cod_pacto].color
        regionesDict[result.cod_region].currentGanador = getCandidatoInfo(result)
      }
    }
  })

  return regionesDict
}

export function formatSummaryAlcaldes(results: AlcaldesFile | undefined): AlcaldesPactoSummary {
  const electos = results?.filter((result) => result.electo) || []
  return getAlcaldesPactoSummary(electos)
}

function getCandidatoInfo(result: CandidatoGore) {
  return {
    id_persona: result.id_persona,
    cod_pacto: result.cod_pacto,
    nombre: result.nombre,
    porcentaje: result.porcentaje,
    imagen: result.imagen,
  }
}

export function getPactosSummary(summaryGore: CandidatoGore[]) {
  return summaryGore.reduce((acc, candidato) => {
    const { electo, cod_partido, cod_pacto, votos } = candidato
    // Set initial values for pacto
    if (!acc[cod_pacto]) {
      acc[cod_pacto] = {
        electos: 0,
        votos: 0,
      }
    }
    // Set initial values for partido
    if (!acc[cod_pacto][cod_partido]) acc[cod_pacto][cod_partido] = 0

    // Add values
    if (electo) acc[cod_pacto][cod_partido] += 1
    if (electo) acc[cod_pacto].electos += 1
    acc[cod_pacto].votos += votos

    return acc
  }, {} as Record<string, { [key: string]: number }>)
}

export function getAlcaldesPactoSummary(filteredAlcaldes: AlcaldesFile) {
  const pactosSummary = Object.entries(PACTOS_ALCALDES).reduce((acc, [codPacto, pacto]) => {
    const candidatosPacto = filteredAlcaldes.filter((candidato) => candidato.cod_pacto === codPacto)
    const totalVotes = candidatosPacto.reduce((sum, candidato) => sum + (candidato.votos || 0), 0)

    acc[codPacto] = { electos: candidatosPacto.length, votos: totalVotes }
    return acc
  }, {} as { [key: string]: { electos: number; votos: number } })

  return pactosSummary
}

export function getAlcaldesDestacados(alcaldes: AlcaldesFile | undefined) {
  if (!alcaldes) return []

  const filteredAlcaldes = alcaldes.filter((candidato) => candidato.electo)
  return filteredAlcaldes.filter((candidato) =>
    codigosComunasDestacadas.includes(candidato.cod_comuna)
  )
}

export function getPactosDestacados2021ByComuna(
  data: { [territorio: number]: ElectosPacto2021 } | undefined
) {
  if (!data) return undefined
  return Object.fromEntries(
    Object.entries(data).filter(([codComuna]) =>
      codigosComunasDestacadas.includes(Number(codComuna))
    )
  )
}

export function getComunasDestacadas() {
  return Object.fromEntries(
    comunas
      .filter((item) => codigosComunasDestacadas.includes(item.codigo))
      .map((item) => [item.codigo, item])
  )
}
