export const colors = {
  frame: '#0C184D',
  background: '#E5E5E5',
  frameFontDark: '#303030',
  frameFontLight: '#c4c7c5',
  frameBgButton: '#364277',
  frameBgButton2: '#273267',
  frameBorderButton: '#0E0E0E',
  frameBoxShadowCard: '#B7B7B7',
  subMenuBackground: '#32456E',
  menuSelected: '#FAFAFA',
  map: {
    water: '#D2D2D5',
    selectedFill: '#B1B1BB',
    continentFill: '#B1B1BB',
    stroke: '#FAFAFA',
  },
  blackDch: '#3C3C3C',
  highlightedYellow: '#FCBE31',
  gray3: '#828282',
  gray1Dch: '#E0E0E0',
  gray2Dch: '#BDBDBD',
  gray3Dch: '#4F4F4F',
  gray4Dch: '#C4D8FF',
  gray5Dch: '#DCDCDC',
  gray6Dch: '#EDEDED',
  grayTab: '#969696',
  grayVersusDch: '#A8A8A8',
  whiteDch: '#FAFAFA',
  whiteDch2: '#EAEAEA',
  blueDch: '#2150E8',
  statusIfr: '#F2F2F2',
  footerDch: '#002AB4',
  blueDchDarker: '#004FE5',
}
