import useRouting from '@/stores/useRouting'
import { useNavigate } from 'react-router-dom'

import ProvinciaSelector from '@/components/Web/ProvinciaSelector'
import PactoElectos from '@/components/Web/PactoElectos'
import { getRegionResultsByPactoCORE } from '@/lib/dataProcess'
import useElectionData from '@/stores/useElectionData'
import useTerritorios, { comunas } from '@/stores/useTerritorios'
import { useMemo } from 'react'

import { buildRegionResultsByPacto } from '@/lib/buildDataViews'
import { ElectionType } from '@/types/Elections'

export default function CorePactoProvincia() {
  const { currentCircunscripcion } = useRouting()
  const navigate = useNavigate()
  const { setComunaColor } = useTerritorios()
  const {
    files: { core },
    comparador: { core: core2021 },
    static: { escanos_core },
  } = useElectionData()

  const data = useMemo(() => {
    if (!core2021 || !core || !currentCircunscripcion || !escanos_core) return []

    const comunasCircunscripcion = comunas
      .filter((comuna) => comuna.circunscripcionProvincial === currentCircunscripcion)
      .map((comuna) => comuna.codigo)

    const { countElectosByPacto, countElectosByPacto2021, comunasColor } =
      getRegionResultsByPactoCORE(
        core || [],
        core2021,
        currentCircunscripcion || '',
        comunasCircunscripcion
      )

    const totalElectos =
      escanos_core[currentCircunscripcion.toUpperCase()] ||
      escanos_core[currentCircunscripcion] ||
      escanos_core[currentCircunscripcion === 'Aysén' ? 'AISÉN' : ''] ||
      escanos_core[currentCircunscripcion === 'Antártica' ? 'ANTÁRTICA CHILENA' : ''] ||
      1

    const data = buildRegionResultsByPacto(
      countElectosByPacto,
      countElectosByPacto2021,
      totalElectos || 0,
      ElectionType.CORE
    )

    setComunaColor(comunasColor)

    return data.sort((a, b) => b.electos_2024 - a.electos_2024)
  }, [core, core2021, currentCircunscripcion, escanos_core, setComunaColor])

  const handleRegion = (region: string) => {
    navigate(`/${ElectionType.CORE}/territorio/${region}/pactos`)
  }

  return (
    <div className="h-full">
      <PactoElectos
        data={data}
        selection={
          <ProvinciaSelector
            className="w-[50%] hidden xl:flex"
            onClick={handleRegion}
            value={String(currentCircunscripcion)}
          />
        }
        title="Pactos por Provincia"
      />
    </div>
  )
}
