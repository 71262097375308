import HorizontalMap from '@/components/Web/HorizontalMap'
import { colors } from '@/constants'
import Card from '../Card'
import React from 'react'
import styled from 'styled-components'
import { ElectCandidateBadge } from '../CandidateBadge'
import Scroll from '@/assets/scroll.svg'
import DecideChileMono from '@/components/DecideChileMono'
import { Skeleton } from '@/components/ui/skeleton'

type Electo = {
  nombre: string
  imagen: string
  color: string
  partido: string
}

export type Data = {
  region: string
  region_codigo: number
  region_pacto?: string
  electo_2024: Electo
  electo_2021: Electo
}

type CambioPacto = {
  data: Data[]
  title: string
  regiones: {
    name: string
    realName: string
    selectedColor: string
    code: number
  }[]
  isMantiene: boolean
}

export default function CambioMantienePacto({ title, data, regiones, isMantiene }: CambioPacto) {
  const fakeItem: Data = {
    region: '',
    region_codigo: 0,
    electo_2024: {
      nombre: '',
      imagen: 'userProfile.png',
      color: '',
      partido: '',
    },
    electo_2021: {
      nombre: '',
      imagen: 'userProfile.png',
      color: '',
      partido: '',
    },
  }
  return (
    <Card
      title={title}
      separatorColor="#CCCCCC"
      toggle={
        <div className="xl:hidden">
          <Scroll />
        </div>
      }
    >
      <Wrapper>
        <div className="max-w-[870px]">
          <HideScrollbar className="xl:px-[50px] pb-[5px] mb-4 xl:mb-0 xl:pb-[10px] max-w-full xl:w-[867px] xl:mt-3 overflow-y-auto px-3">
            <HorizontalMap
              selectionProperty="cod_comuna"
              position="center"
              regionResults={regiones}
            />
          </HideScrollbar>
          <div className="flex justify-end">
            <DecideChileMono />
          </div>
          <div className="max-h-52 overflow-y-auto relative">
            <Table $isMantiene={isMantiene}>
              <HeaderCell className="sticky top-0 bg-white z-30">REGIÓN</HeaderCell>
              <HeaderCell className="sticky top-0 bg-white z-30">2024</HeaderCell>
              <HeaderCell className="sticky top-0 z-30">2021</HeaderCell>
              <HeaderCell className="xl:block hidden sticky top-0 bg-white z-30">REGIÓN</HeaderCell>
              <HeaderCell className="xl:block hidden sticky top-0 bg-white z-30">2024</HeaderCell>
              <HeaderCell className="xl:block hidden sticky top-0 z-30">2021</HeaderCell>
              {data.length === 0 && (
                <>
                  <div className="absolute bg-white opacity-70 w-full xl:w-[867px] h-full top-0 right-0 z-50 flex justify-center items-center">
                    <div className="xl:h-[40%] font-semibold text-[16px] text-center w-full xl:w-[40%] z-[60] !opacity-100">
                      Con los candidatos electos en primera vuelta, ninguna región mantuvo el mismo
                      pacto político de su actual gobernador.
                    </div>
                  </div>
                  {Array.from({ length: 10 }).map((_, index) => (
                    <React.Fragment key={`gore-tabla-${index}`}>
                      <BodyCellRegion>
                        <Skeleton className="w-[70%] h-2" />
                        {isMantiene && <Skeleton className="w-[80%] h-1 mt-1" />}
                      </BodyCellRegion>
                      <BodyCell>
                        <ElectCandidateBadge small electo={fakeItem.electo_2024} isBlank />
                      </BodyCell>
                      <BodyCell>
                        <ElectCandidateBadge small electo={fakeItem.electo_2021} isBlank />
                      </BodyCell>
                    </React.Fragment>
                  ))}
                </>
              )}
              {data.map((item, index) => (
                <React.Fragment key={`gore-tabla-${index}`}>
                  <BodyCellRegion className="border-b border-b-[#eeeef0]">
                    {item.region}
                    {item.region_pacto && (
                      <div className="font-light text-[10px] -mt-1">{item.region_pacto}</div>
                    )}
                  </BodyCellRegion>
                  <BodyCell className="border-b border-b-[#eeeef0]">
                    <ElectCandidateBadge small electo={item.electo_2024} />
                  </BodyCell>
                  <BodyCell className="border-b border-b-[#eeeef0]">
                    <ElectCandidateBadge small electo={item.electo_2021} />
                  </BodyCell>
                </React.Fragment>
              ))}
            </Table>
          </div>
        </div>
      </Wrapper>
    </Card>
  )
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const Table = styled.div<{ $isMantiene: boolean }>`
  height: 100%;
  width: 100%;
  display: grid;

  @media (min-width: 1100px) {
    grid-template-columns: ${({ $isMantiene }) =>
      $isMantiene ? 'repeat(2, 1fr 1.1fr 1.1fr)' : 'repeat(2, 1fr 1.1fr 1.1fr)'};
  }
  grid-template-columns: 0.8fr 1.1fr 1.1fr;

  & > :nth-of-type(3n) {
    background-color: ${colors.whiteDch};
  }
`

const HeaderCell = styled.div.attrs({
  className: 'xl:text-[13px] text-[10px] xl:leading-[17px] text-[13px] py-1 px-2',
})`
  font-family: 'JetBrains Mono';
  color: ${colors.gray3};
  border-bottom: 0.5px solid ${colors.gray1Dch};
`

const BodyCellRegion = styled.div.attrs({
  className: 'py-1 px-2',
})`
  color: ${colors.blackDch};
  font-size: 13px;
  font-weight: 500;
  line-height: 18.71px;
  text-align: left;
`

const BodyCell = styled.div.attrs({
  className: 'w-full h-full py-1 px-2',
})``

const HideScrollbar = styled.div`
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px; /* Adjust the width of the fade effect */
    pointer-events: none;
    height: 100px;
  }
  &::before {
    left: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
  &::after {
    right: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
  padding-left: 15px;
  padding-right: 20px;
`
