import MPactos, { generateCircles, type PactoResult } from '@/components/MagicWall/Pactos'
import CircleMesh from '@/components/CircleMesh/CircleMesh'
import useElectionData from '@/stores/useElectionData'
import { useMemo } from 'react'
import { getResultsByPacto } from '@/lib/dataProcess'
import { buildPactosViewData } from '@/lib/buildDataViews'
import { ElectionType } from '@/types/Elections'
import { TOTAL_ELECTOS } from '@/constants'

export default function Pactos() {
  const {
    files: { alcaldes },
  } = useElectionData()

  const data: PactoResult[] = useMemo(() => {
    const resultByPactos = getResultsByPacto(alcaldes || [])
    return buildPactosViewData(resultByPactos, ElectionType.ALCALDES, false)
  }, [alcaldes])

  const circles = useMemo(() => {
    const orderedData = data.sort((a, b) => a.orden - b.orden)
    return generateCircles(orderedData, TOTAL_ELECTOS[ElectionType.ALCALDES])
  }, [data])

  return (
    <MPactos
      className="mgw:py-14 mgw:px-10 py-12 px-10"
      election="Alcaldes"
      data={data}
      verticalSeparatorBefore="Otros"
      numberOfPartidosbyColumn={6}
      minWidthPactos="120px"
      minWidthPartidos="150px"
    >
      <CircleMesh circles={circles} nrows={6} gap="5px" />
    </MPactos>
  )
}
