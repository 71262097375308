import CUTJson from '@/assets/files/cut.json'
import { create } from 'zustand'

const CUTs = CUTJson as CUTJson[]

export const GSComunas = [
  // Gran Santiago cod_region 17
  13101, // Santiago
  13102, // Cerrillos
  13103, // Cerro Navia
  13104, // Conchalí
  13105, // El Bosque
  13106, // Estación Central
  13107, // Huechuraba
  13108, // Independencia
  13109, // La Cisterna
  13110, // La Florida
  13111, // La Granja
  13112, // La Pintana
  13113, // La Reina
  13114, // Las Condes
  13115, // Lo Barnechea
  13116, // Lo Espejo
  13117, // Lo Prado
  13118, // Macul
  13119, // Maipú
  13120, // Ñuñoa
  13121, // Pedro Aguirre Cerda
  13123, // Providencia
  13124, // Pudahuel
  13201, // Puente Alto
  13122, // Peñalolén
  13125, // Quilicura
  13126, // Quinta Normal
  13127, // Recoleta
  13128, // Renca
  13401, // San Bernardo
  13129, // San Joaquín
  13130, // San Miguel
  13131, // San Ramón
  13132, // Vitacura
]

type CUTJson = {
  'Código Región': number
  'Nombre Región': string
  'Abreviatura Región': string
  'Código Provincia': number
  'Nombre Provincia': string
  'Código Comuna 2018': number
  'Nombre Comuna': string
  'Circunscripción Senatorial': number
  Distrito: number
  'Circunscripción Provincial': string
  'Nombre Corto'?: string
  Acrónimo?: string
  Orden: number
  'Comuna Defecto'?: number
  'Orden Menu': number
}

type CUT = CUTJson

export type TerritorioData = {
  codigo: number | string
  nombre: string
  nombreCorto?: string
  acronimo?: string
  orden?: number
}

export type RegionData = TerritorioData & {
  codigo: number
  provincias: number[]
  comunas: number[]
  comunaDefecto?: number
  ordenMenu: number
}

export type ComunaData = TerritorioData & {
  codigo: number
  provincia: number
  region: number
  circunscripcionProvincial: string
}

export type CircunscripcionProvincialData = TerritorioData & {
  codigo: string
  region: number
  provincia: number
  comunas: number[]
}

type RegionCustomAttributes = {
  [x: string]: { nombre: string; codigo: number; ordenMenu: number }
}

const regionCustomAttributes: RegionCustomAttributes = {
  15: { nombre: 'Arica', codigo: 15101, ordenMenu: 1 },
  1: { nombre: 'Iquique', codigo: 1101, ordenMenu: 2 },
  2: { nombre: 'Antofagasta', codigo: 2101, ordenMenu: 3 },
  3: { nombre: 'Copiapó', codigo: 3101, ordenMenu: 4 },
  4: { nombre: 'La Serena', codigo: 4101, ordenMenu: 5 },
  5: { nombre: 'Valparaíso', codigo: 5101, ordenMenu: 6 },
  13: { nombre: 'Santiago', codigo: 13101, ordenMenu: 7 },
  17: { nombre: 'Santiago', codigo: 13101, ordenMenu: 8 },
  6: { nombre: 'Rancagua', codigo: 6101, ordenMenu: 9 },
  7: { nombre: 'Talca', codigo: 7101, ordenMenu: 10 },
  16: { nombre: 'Chillán', codigo: 16101, ordenMenu: 11 },
  8: { nombre: 'Concepción', codigo: 8101, ordenMenu: 12 },
  9: { nombre: 'Temuco', codigo: 9101, ordenMenu: 13 },
  14: { nombre: 'Valdivia', codigo: 14101, ordenMenu: 14 },
  10: { nombre: 'Puerto Montt', codigo: 10101, ordenMenu: 15 },
  11: { nombre: 'Coyhaique', codigo: 11101, ordenMenu: 16 },
  12: { nombre: 'Punta Arenas', codigo: 12101, ordenMenu: 17 },
}

export const customOrderMetropolitana: { [x: number]: number } = {
  13101: 1, // Santiago
  13127: 2, // Recoleta
  13120: 3, // Ñuñoa
  13108: 4, // Independecia
  13201: 5, // Puente Alto
  13114: 6, // Las Condes
  13123: 7, // Providencia
  13130: 8, // San Miguel
  13119: 9, // Maipu
  13107: 10, // Huechuraba
  13132: 11, // Vitacura
  13115: 12, // Lo barnechea
  13113: 13, // La reina
}

export const regiones = CUTs.reduce((acc: RegionData[], cut: CUT) => {
  const region = acc.find((r) => r.codigo === cut['Código Región'])
  if (region) {
    region.comunas = [...region.comunas, cut['Código Comuna 2018']]
    region.provincias = [...region.provincias, cut['Código Provincia']]
    region.comunaDefecto = regionCustomAttributes[cut['Código Región']]?.codigo
    region.ordenMenu = regionCustomAttributes[cut['Código Región']]?.ordenMenu
  } else {
    acc.push({
      nombre: cut['Nombre Región'],
      codigo: cut['Código Región'],
      provincias: [cut['Código Provincia']],
      comunas: [cut['Código Comuna 2018']],
      nombreCorto: cut['Nombre Corto'],
      acronimo: cut['Acrónimo'],
      orden: cut['Orden'],
      comunaDefecto: cut['Comuna Defecto'],
      ordenMenu: cut['Orden Menu'],
    })
  }
  return acc
}, [] as RegionData[])
  .sort((a, b) => {
    if (a.orden && b.orden) {
      return a.orden - b.orden
    }
    return 0
  })
  .concat({
    nombre: 'Gran Santiago',
    codigo: 17,
    provincias: [],
    comunas: GSComunas,
    nombreCorto: 'Gran Santiago',
    acronimo: 'GS',
    orden: 17,
    comunaDefecto: 13101,
    ordenMenu: regionCustomAttributes[17].ordenMenu,
  })
export const comunas = CUTs.map((cut: CUT) => ({
  nombre: cut['Nombre Comuna'],
  codigo: cut['Código Comuna 2018'],
  provincia: cut['Código Provincia'],
  region: cut['Código Región'],
  circunscripcionProvincial: cut['Circunscripción Provincial'],
})) as ComunaData[]

export const circunscripcionesProvinciales = CUTs.reduce(
  (acc: CircunscripcionProvincialData[], cut: CUT) => {
    const circunscripcion = acc.find((c) => c.codigo === cut['Circunscripción Provincial'])
    if (circunscripcion) {
      circunscripcion.comunas = [...circunscripcion.comunas, cut['Código Comuna 2018']]
    } else {
      acc.push({
        nombre: cut['Circunscripción Provincial'],
        codigo: cut['Circunscripción Provincial'],
        region: cut['Código Región'],
        provincia: cut['Código Provincia'],
        comunas: [cut['Código Comuna 2018']],
      })
    }
    return acc
  },
  [] as CircunscripcionProvincialData[]
)

export type TeritoriosState = {
  CUTs: CUT[]
  selectedRegiones: number[]
  regionesColorDict: { [key: number]: string }
  selectedComunas: number[]
  comunasColorDict: { [key: number]: string }
  selectedCircunscripciones: string[]
  circunscripcionesColorDict: { [key: string]: string }
  setSelectedRegionesById: (regions: number[]) => void
  setRegionColor: (regionColor: { [key: number]: string }) => void
  setSelectedComunasById: (comunas: number[]) => void
  setComunaColor: (comunaColor: { [key: number]: string }) => void
  setSelectedCircunscripcionesById: (circunscripciones: string[]) => void
  setCircunscripcionColor: (circunscripcionColor: { [key: string]: string }) => void
}

const useTerritorios = create<TeritoriosState>((set) => ({
  CUTs,
  selectedRegiones: [],
  selectedComunas: [],
  regionesColorDict: {},
  comunasColorDict: {},
  circunscripcionesColorDict: {},
  selectedCircunscripciones: [],
  setSelectedRegionesById: (regions: number[]) => {
    const comunas = CUTs.filter((cut: CUT) => regions.includes(cut['Código Región'])).map(
      (cut: CUT) => cut['Código Comuna 2018']
    )
    if (regions.includes(17)) {
      // Add Gran Santiago
      comunas.push(...GSComunas)
    }
    set({ selectedRegiones: regions, selectedComunas: comunas, selectedCircunscripciones: [] })
  },
  setSelectedComunasById: (comunas: number[]) => {
    set({ selectedComunas: comunas, selectedRegiones: [], selectedCircunscripciones: [] })
  },
  setSelectedCircunscripcionesById: (circunscripciones: string[]) => {
    const comunas = CUTs.filter((cut: CUT) =>
      circunscripciones.includes(cut['Circunscripción Provincial'])
    ).map((cut: CUT) => cut['Código Comuna 2018'])
    set({
      selectedCircunscripciones: circunscripciones,
      selectedComunas: comunas,
      selectedRegiones: [],
    })
  },
  setRegionColor: (regionColor) => set({ regionesColorDict: regionColor }),
  setComunaColor: (comunaColor) => set({ comunasColorDict: comunaColor }),
  setCircunscripcionColor: (circunscripcionColor) =>
    set({ circunscripcionesColorDict: circunscripcionColor }),
}))

export default useTerritorios
