import { colors } from '@/constants'
import * as d3 from 'd3'
import * as topojsonTools from 'topojson'
import { ChileFeature, ChileTopoGeometries, ChileTopoJSON, ChileTopoProperties } from './types'

type MapProps = {
  topoJson: ChileTopoJSON
  onClick?: (feature?: ChileFeature) => void
  object?: keyof ChileTopoGeometries
  rotate?: [number, number, number]
  waterColor?: string
}

export default ({
  topoJson,
  onClick,
  object = 'comunas',
  rotate = [0, 0, 0],
  waterColor,
}: MapProps) => {
  // Rotate Chile 90 degrees to the right
  const projection = d3.geoMercator().rotate(rotate)
  const path = d3.geoPath().projection(projection)
  const objects = topoJson.objects[object]
  const geoFeatures = topojsonTools.feature(topoJson, objects) as GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    ChileTopoProperties
  >
  const features = geoFeatures.features

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function clicked(event: any, feature?: ChileFeature) {
    event.stopPropagation()
    if (onClick) onClick(feature)
  }

  const mapaSvg = d3.create('svg').attr('width', '100%').attr('height', '100%')

  const comunas = mapaSvg
    .append('g')
    .attr('id', 'comunas')
    .attr('fill', waterColor || colors.map.water)
    .attr('cursor', 'pointer')
    .attr('stroke', 'none')
    .selectAll('path')
    .data(features)
    .join('path')
    .on('click', clicked)
    .attr('d', path)

  return { mapaSvg, comunas }
}
