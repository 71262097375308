import HorizontalMapSvg from '@/assets/horizontalMap.svg'
import { colors } from '@/constants'
import styled from 'styled-components'
import { MapProps } from './Map/ComunasChileMap'

type HorizontalMapProps = {
  regionResults: {
    name: string
    selectedColor: string
    code: number
  }[]
  children?: React.ReactNode
} & MapProps

export default function HorizontalMap({ regionResults }: HorizontalMapProps) {
  return (
    <Wrapper>
      <Circles>
        {regionResults.map((region) => (
          <Circle
            key={`${region.code}-${region.selectedColor}`}
            selectedColor={region.selectedColor}
          >
            {region.name}
          </Circle>
        ))}
      </Circles>
      <Map />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  width: 100%;
`

const Map = styled(HorizontalMapSvg).attrs({
  className: 'px-6 mgw:px-8 -mt-6 mgw:-mt-8',
})`
  height: 100%;
  width: 100%;
`

const Circle = styled.div.attrs<{ selectedColor?: string }>({
  className:
    'w-8 sm:w-10 xl:w-14 mgw:w-14 p-0.5 sm:p-1 mgw:p-2 aspect-square text-sm xl:text-xl mgw:text-2xl',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ selectedColor }) => selectedColor || colors.whiteDch};
  border: ${({ selectedColor }) => (!selectedColor ? `2px solid ${colors.gray3Dch}` : 'none')};
  color: ${({ selectedColor }) => (!selectedColor ? colors.gray3Dch : colors.whiteDch)};
  font-weight: 600;
`

const Circles = styled.div.attrs({
  className: 'gap-1 justify-between',
})`
  font-family: 'JetBrains Mono', monospace;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 0;
`
