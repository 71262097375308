import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import PByUnholsterBase from '@/assets/pByUnholsterWhite.svg'
import CNNLogoBase from '@/assets/logoCNNWhite.svg'
import { colors } from '@/constants'
import { Loader } from 'lucide-react'

export default function MagicWall({ isError = false }: { isError?: boolean }) {
  const navigate = useNavigate()
  return (
    <Wrapper onClick={() => navigate('/magic/elecciones')}>
      <CNNLogo />
      <VerticalLine />
      <PByUnholster />
      {isError && (
        <div className="absolute bottom-48 left-1/2 translate-x-1/2">
          <Loader size={40} color="#4A5277" className="animate-spin-slow" />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div.attrs({
  className: 'mgw:pb-[80px] mgw:gap-[60px] pb-[60px] gap-[40px]',
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: ${colors.frame};
`

const CNNLogo = styled(CNNLogoBase).attrs({
  className: 'mgw:w-[280px] w-[200px]',
})``

const PByUnholster = styled(PByUnholsterBase).attrs({
  className: 'mgw:h-[87px] mgw:w-[423px] h-[62px] w-[302px]',
})``

const VerticalLine = styled.div.attrs({
  className: 'mgw:h-[105px] h-[74px] mgw:w-[3px] w-[2px]',
})`
  background-color: ${colors.gray1Dch}50;
`
