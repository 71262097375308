import { calculateSubRegion, getRealRegionCode } from '@/app/MagicWall/GORE/ElectosRegion'
import RegionSelector from '@/components/RegionSelector'
import { PACTOS } from '@/constants/pactos'
import { getTerritorioColorDictByMajority, jointCandidatosColor } from '@/lib/dataProcess'
import useElectionData from '@/stores/useElectionData'
import useRouting from '@/stores/useRouting'
import useTerritorios, { GSComunas, regiones } from '@/stores/useTerritorios'
import { ElectionType } from '@/types/Elections'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import ElectosVersus, { type Data } from '@/components/Web/ElectosVersus'
import { Search, X } from 'lucide-react'
import { MonoLabel } from '../ParticipationBanner'

export default function GoreElectosRegion() {
  const { currentRegion } = useRouting()
  const {
    files: { gore },
  } = useElectionData()
  const { setComunaColor } = useTerritorios()
  const navigate = useNavigate()

  const [isSearch, setIsSearch] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredData, setFilteredData] = useState<Data[]>([])
  const isSearching = useMemo(() => searchValue !== '', [searchValue])

  const region = regiones.find((region) => region.codigo === currentRegion)
  const orderedGores = useMemo(
    () =>
      gore
        ? gore
            .filter(
              (c) => c.cod_region.toString() === (getRealRegionCode(currentRegion) ?? '').toString()
            )
            .sort((a, b) => b.porcentaje - a.porcentaje)
        : [],
    [gore, currentRegion]
  )

  const processedData = useMemo(() => {
    if (orderedGores.length === 0) {
      return []
    }
    if (currentRegion === 17) {
      const GSGores = calculateSubRegion(GSComunas, orderedGores)
      return jointCandidatosColor(GSGores, ElectionType.GORE).map((electo, i) => ({
        ...electo,
        pacto: PACTOS[ElectionType.GORE][electo.cod_pacto]?.shortName || electo.cod_pacto,
        order: i + 1,
        imagen: electo.imagen || '/userProfile.png',
      }))
    }

    return jointCandidatosColor(orderedGores, ElectionType.GORE).map((electo, i) => ({
      ...electo,
      order: i + 1,
      pacto: PACTOS[ElectionType.GORE][electo.cod_pacto]?.shortName || electo.cod_pacto,
      imagen: electo.imagen || '/userProfile.png',
      monoLabel: electo.partido,
    }))
  }, [orderedGores, currentRegion])

  // Process color for each comuna
  useEffect(() => {
    if (gore && currentRegion) {
      const colorDict = getTerritorioColorDictByMajority(
        gore,
        currentRegion as number,
        ElectionType.GORE
      )
      setComunaColor(colorDict)
    }
  }, [gore, currentRegion, setComunaColor])

  useEffect(() => {
    if (searchValue !== '') {
      const filtered = processedData.filter((c) =>
        c.nombre.toLowerCase().includes(searchValue.toLowerCase())
      )
      setFilteredData(filtered)
    } else {
      setFilteredData(processedData)
    }
  }, [searchValue, processedData])

  const handleRegion = (region: string) => {
    navigate(`/${ElectionType.GORE}/territorio/${region}`)
  }

  return (
    <div className="h-full">
      <ElectosVersus
        title={`Electos por Región`}
        data={filteredData}
        isSearching={isSearching}
        selection={
          <div className="w-full xl:mt-[-5px] ">
            <div className="flex gap-3 w-full">
              <RegionSelector
                className="w-[50%] xl:flex hidden"
                onClick={handleRegion}
                value={String(currentRegion)}
              />
            </div>
            {isSearch ? (
              <div className="relative w-full">
                <Search
                  size={12}
                  className="scale-x-[-1] left-2 absolute top-1/2 -translate-y-1/2"
                />
                <Input
                  className="border-none shadow-none mt-1 pl-7 font-mono uppercase text-[12px] h-7"
                  placeholder="Buscar por nombre"
                  autoFocus
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <X
                  size={13}
                  className="right-2 absolute top-1/2 -translate-y-1/2 cursor-pointer hover:bg-gray-100 rounded-[2px]"
                  onClick={() => {
                    setIsSearch(false)
                    setSearchValue('')
                  }}
                />
              </div>
            ) : (
              <div className="flex justify-between mt-2 items-center">
                <MonoLabel className="text-[11px] font-light text-gray3 uppercase">
                  Candidatos {processedData.length} / SE ELIGEN 1
                </MonoLabel>

                <Button
                  variant={'ghost'}
                  className="gap-1"
                  size={'ghostSmall'}
                  onClick={() => setIsSearch(true)}
                >
                  <Search size={12} className="scale-x-[-1] text-gray3" />
                  <MonoLabel className="font-light text-gray3 uppercase">Buscar</MonoLabel>
                </Button>
              </div>
            )}
          </div>
        }
      />
    </div>
  )
}
