import MantienePacto from '@/components/MagicWall/MantienePacto'
import useTerritorios from '@/stores/useTerritorios'
import useElectionData from '@/stores/useElectionData'
import useRouting from '@/stores/useRouting'
import { regiones } from '@/stores/useTerritorios'
import { useMemo } from 'react'
import { buildCambioMantienePactoAlcalde } from '@/lib/buildDataViews'

export default function AMantienePacto() {
  const {
    files: { alcaldes },
    comparador: { alcaldes: alcaldes2021 },
  } = useElectionData()

  const { currentRegion } = useRouting()
  const { setComunaColor } = useTerritorios()

  const data = useMemo(() => {
    const region = regiones.find((r) => r.codigo === currentRegion)
    if (!alcaldes || !alcaldes2021 || !region) {
      return []
    }
    const { data, comunasColor } = buildCambioMantienePactoAlcalde(
      region,
      alcaldes,
      alcaldes2021,
      'mantiene',
      15
    )

    setComunaColor(comunasColor)
    return data.sort((a, b) => a.comuna.localeCompare(b.comuna))
  }, [alcaldes, alcaldes2021, currentRegion, setComunaColor])

  return <MantienePacto data={data} pageSize={5} />
}
