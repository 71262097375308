import GCambioMantienePacto, { type Data } from '@/components/Web/GORE/CambioMantienePacto'
import { useMemo } from 'react'
import { buildCambioMantienePactoGORE } from '@/lib/buildDataViews'
import useElectionData from '@/stores/useElectionData'
import { regiones } from '@/stores/useTerritorios'

export default function GoreMantienePacto() {
  const {
    files: { gore },
    comparador: { gore: gore2021 },
  } = useElectionData()

  const data: Data[] = useMemo(() => {
    if (!gore || !gore2021) return []
    return buildCambioMantienePactoGORE(gore, gore2021, 'mantiene', 10)
  }, [gore, gore2021])

  const regionesData = regiones
    .filter((region) => region.codigo !== 17)
    .sort((a, b) => {
      if (a.orden && b.orden) {
        return a.orden - b.orden
      }
      return 0
    })
    .map((region) => ({
      code: region.codigo,
      name: region.acronimo || region.nombre,
      selectedColor:
        data.find((item) => item.region_codigo === region.codigo)?.electo_2024.color ?? '#BDBDBD',
      realName: region.nombre,
    }))

  return (
    <GCambioMantienePacto
      title="Regiones que Mantienen Pacto"
      data={data}
      regiones={regionesData}
      isMantiene
    />
  )
}
