import useUpdateFromRoute from '@/hooks/useUpdateFromRoute'
import { useUpdateElectionData } from '@/stores/useElectionData'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

export default function Layout() {
  useUpdateFromRoute()
  useUpdateElectionData()

  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
