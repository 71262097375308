import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { colors } from '@/constants'
import { ElectCandidateBadge, NameCandidateBadge } from '@/components/MagicWall/CandidateBadget'
import PaginatedCard from '@/components/MagicWall/PaginatedCard'
import useRouting from '@/stores/useRouting'
import { Skeleton } from '../ui/skeleton'
import { fakeItem } from './Reelecto'

type Electo = {
  nombre: string
  partido: string
  imagen: string
  color: string
}

export type Data = {
  comuna: string
  pacto: string
  electo_2024: Electo
  electo_2021: Electo
}

type MantienePacto = {
  data: Data[]
  pageSize: number
}

export default function MantienePacto({ data, pageSize = 5 }: MantienePacto) {
  const { currentRegion } = useRouting()
  const [currentPage, setCurrentPage] = useState(0)

  const totalPages = Math.ceil(data.length / pageSize)
  const paginatedData = data.slice(currentPage * pageSize, (currentPage + 1) * pageSize)

  useEffect(() => {
    setCurrentPage(0)
  }, [currentRegion])

  return (
    <PaginatedCard
      title="Mantiene Pacto"
      statusLabel="PRELIMINAR"
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      totalPages={totalPages}
    >
      <GridTable>
        <GridHeaderCell className="text-left">COMUNA</GridHeaderCell>
        <GridHeaderCell>2024</GridHeaderCell>
        <GridHeaderCell>2021</GridHeaderCell>
        {paginatedData.slice(0, pageSize).map((item, index) => (
          <React.Fragment key={index}>
            <GridCell className="flex flex-col items-start">
              <div>{item.comuna}</div>
              <div className="font-medium">{item.pacto}</div>
            </GridCell>
            <GridCell className="flex items-center">
              <ElectCandidateBadge electo={item.electo_2024} />
            </GridCell>
            <GridCell className="flex items-center">
              <ElectCandidateBadge small electo={item.electo_2021} />
            </GridCell>
          </React.Fragment>
        ))}
        {currentPage === 0 &&
          Array.from({ length: pageSize - data.length }).map((_, index) => (
            <React.Fragment key={index}>
              <GridCell>
                <Skeleton className="w-[70%] h-6" />
                <Skeleton className="w-[75%] h-7 mt-2" />
              </GridCell>
              <GridCell>
                <NameCandidateBadge electo={fakeItem} isBlank />
              </GridCell>
              <GridCell>
                <NameCandidateBadge electo={fakeItem} isBlank small />
              </GridCell>
            </React.Fragment>
          ))}
      </GridTable>
    </PaginatedCard>
  )
}

const GridTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: min-content;
`

const GridHeaderCell = styled.div.attrs({
  className: 'mgw:text-[1.5em] text-[1.25em]',
})`
  font-family: 'JetBrains Mono';
  font-weight: 600;
  line-height: 1em;

  color: ${colors.blackDch};
  border-bottom: 2px solid ${colors.gray3Dch};

  padding: 24px 15px 15px;
  &:nth-of-type(3n + 1) {
    padding-left: 40px;
  }
  &:nth-of-type(3n) {
    background-color: ${colors.gray1Dch};
  }
`

const GridCell = styled.div.attrs({
  className: 'mgw:text-[1.5em] text-[1.25em] mgw:py-[15px] py-[12px]',
})`
  font-weight: 700;

  color: ${colors.blackDch};
  border-bottom: 2px solid ${colors.gray3Dch};

  padding-left: 10px;
  padding-right: 10px;
  &:nth-of-type(3n + 1) {
    padding-left: 40px;
  }
  &:nth-of-type(3n) {
    background-color: ${colors.gray1Dch};
  }
`
