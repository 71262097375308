import { ChileFeature } from './types'
import * as d3 from 'd3'

const projection = d3.geoMercator()
const path = d3.geoPath().projection(projection)

export function getBoundingBox({
  nodes,
  xLimit = 277, // Mas o menos donde empieza el mar
}: {
  nodes: d3.Selection<SVGPathElement, ChileFeature, SVGSVGElement, undefined>
  xLimit?: number
}) {
  const data = nodes.data()
  const bounds = data
    .map((node) => path.bounds(node as d3.GeoPermissibleObjects))
    .filter((bound) => (xLimit && data.length !== 1 ? bound[0][0] > xLimit : true))

  const [[x0, y0], [x1, y1]] = [
    [d3.min(bounds, (bound) => bound[0][0]), d3.min(bounds, (bound) => bound[0][1])],
    [d3.max(bounds, (bound) => bound[1][0]), d3.max(bounds, (bound) => bound[1][1])],
  ]

  return { x0, y0, x1, y1 }
}
