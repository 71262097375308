import Hamburger from './Hamburger'
import NavigationLinks from './NavigationLinks'
import styled from 'styled-components'

export default function Menu({ className }: { className?: string }) {
  return (
    <Wrapper className={className}>
      <Navigation className="px-[20px] py-[5px]">
        <Hamburger />
        <NavigationLinks />
      </Navigation>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 1350px;
  align-items: center;
  background-color: #ffffff;
  border-bottom: #e0e1e0 solid 1px;
  border-top: #e0e1e0 solid 1px;
`

const Navigation = styled.div`
  display: grid;
  grid: auto / min-content 1fr max-content;
  gap: 36px;
  align-items: center;
`
