import styled from 'styled-components'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { formatFullName, hexToRGBA, numberToPercent } from '@/helpers/formatters'
import { PACTOS_GORE } from '@/constants/pactos'
import CheckIcon from '@/assets/checkIcon.svg'
import { RegionData, RegionesData } from '@/types/Iframe'
import { colors } from '@/constants/colors'

export default function ElectionResultsTable({ summaryGore }: { summaryGore: RegionesData }) {
  return (
    <StyledTable>
      <TableHeader>
        <TableRow>
          <StyledTableHead className="w-[70px] sifr:w-[90px] ifr:w-[120px]">
            REGIONES
          </StyledTableHead>
          <StyledTableHead>ELECTO/A</StyledTableHead>
          <StyledTableHead>PASAN A 2° VUELTA</StyledTableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {summaryGore &&
          Object.entries(summaryGore)
            .sort(([, a], [, b]) => (a as RegionData).orden - (b as RegionData).orden)
            .map(([, region]) => {
              const typedRegion = region as RegionData
              return (
                <StyledTableRow key={typedRegion.nombre}>
                  <StyledTableCell className="align-top">{typedRegion.nombre}</StyledTableCell>
                  <StyledTableCell>
                    {typedRegion.currentGanador && (
                      <Candidate>
                        <div className="relative">
                          <StyledAvatar
                            color={
                              PACTOS_GORE[typedRegion.currentGanador.cod_pacto]?.color || '#1E9724'
                            }
                            className="h-[26px] sifr:h-[30px] ifr:h-[40px] w-[26px] sifr:w-[30px] ifr:w-[40px]"
                          >
                            <AvatarImage
                              src={typedRegion.currentGanador.imagen || '/userProfile.png'}
                              alt={typedRegion.currentGanador.nombre}
                            />
                            <AvatarFallback>
                              {typedRegion.currentGanador.nombre.charAt(0)}
                            </AvatarFallback>
                          </StyledAvatar>
                          <CheckIcon
                            className="absolute w-[8px] h-[8px] sifr:w-[10px] sifr:h-[10px] ifr:w-[12px] ifr:h-[12px] 
                          top-[18px] left-[18px] sifr:top-[20px] sifr:left-[20px] ifr:top-[28px] ifr:left-[28px] "
                          />
                        </div>
                        <CandidateInfo className="flex flex-col gap-[2px] ifr:gap-[4px]">
                          <div className="text-balance leading-[1.07]">
                            <b>{formatFullName(typedRegion.currentGanador.nombre)}</b>
                          </div>
                          <CandidateDetail>
                            {numberToPercent(typedRegion.currentGanador.porcentaje, 1)}{' '}
                            <StyledBadge
                              color={
                                PACTOS_GORE[typedRegion.currentGanador.cod_pacto]?.color ||
                                '#1E9724'
                              }
                              darkColor={
                                PACTOS_GORE[typedRegion.currentGanador.cod_pacto]?.darkColor ||
                                '#1E9724'
                              }
                            >
                              {PACTOS_GORE[typedRegion.currentGanador.cod_pacto]?.initials ||
                                'OTROS'}
                            </StyledBadge>
                          </CandidateDetail>
                        </CandidateInfo>
                      </Candidate>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="flex flex-col gap-[6px] ifr:gap-[10px]">
                      {typedRegion.currentSegundos.map((candidate, idx) => (
                        <Candidate className="relative">
                          <StyledAvatar
                            color={PACTOS_GORE[candidate.cod_pacto]?.color || '#1E9724'}
                            className="h-[20px] sifr:h-[30px] ifr:h-[40px] w-[20px] sifr:w-[30px] ifr:w-[40px]"
                          >
                            <AvatarImage
                              src={candidate.imagen || '/userProfile.png'}
                              alt={candidate.nombre}
                            />
                            <AvatarFallback>{candidate.nombre.charAt(0)}</AvatarFallback>
                          </StyledAvatar>
                          <CandidateInfo className="flex flex-col gap-[2px] ifr:gap-[4px]">
                            <div className="text-balance leading-[1.07]">
                              <b>{formatFullName(candidate.nombre)}</b>
                            </div>
                            <CandidateDetail>
                              {numberToPercent(candidate.porcentaje, 1)}{' '}
                              <StyledBadge
                                color={PACTOS_GORE[candidate.cod_pacto]?.color || '#1E9724'}
                                darkColor={PACTOS_GORE[candidate.cod_pacto]?.darkColor || '#1E9724'}
                              >
                                {PACTOS_GORE[candidate.cod_pacto]?.initials || 'OTROS'}
                              </StyledBadge>
                            </CandidateDetail>
                          </CandidateInfo>
                        </Candidate>
                      ))}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
      </TableBody>
    </StyledTable>
  )
}

const StyledTable = styled(Table).attrs({
  className: `text-[10px] sifr:text-[12px] ifr:text-[16px]`,
})`
  border-collapse: separate;
  border-spacing: 0;
  color: #3c3c3c;
`

const StyledTableHead = styled(TableHead).attrs({
  className: `text-[8px] sifr:text-[10px] ifr:text-[13px]
    h-[24px] ifr:h-[30px]
  `,
})`
  font-weight: 600;
  font-family: 'Jetbrains Mono', monospace;
  color: #3c3c3c;
  position: sticky;
  top: 0px;
  z-index: 100;
  border-bottom: 1px solid ${colors.gray3Dch};
  background-color: white;
  &:first-child {
    background-color: ${colors.gray6Dch};
  }
`

const StyledTableCell = styled(TableCell)`
  border-bottom: 1px solid ${colors.gray3Dch};
  border-left: none;
  border-right: none;

  &:first-child {
    font-weight: 700;
    background-color: ${colors.gray6Dch};
  }
`

const StyledTableRow = styled(TableRow)`
  &:last-child ${StyledTableCell} {
    border-bottom: none;
  }
`

const Candidate = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const CandidateInfo = styled.div.attrs({
  className: `text-[10px] sifr:text-[12px] ifr:text-[16px]`,
})``

const CandidateDetail = styled.div.attrs({
  className: `text-[9px] sifr:text-[10px] ifr:text-[13px]`,
})`
  color: #3c3c3c;
  display: flex;
  gap: 6px;
  align-items: center;
`

const StyledAvatar = styled(Avatar).attrs({
  className: 'h-10 w-10 border-[1px] sifr:border-2',
})<{ color: string }>`
  border-color: ${(props) => props.color};
`

const StyledBadge = styled.div.attrs({
  className: 'text-[8px] ifr:text-[11px]',
})<{ color: string; darkColor: string | undefined }>`
  background-color: ${(props) => hexToRGBA(props.color, 0.1)};
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.darkColor};
  padding: 0px 4px;
  font-weight: 400;
  border-radius: 4px;
  height: min-content;
  line-height: 1.6;
`
