import Alianza from './Alianza'
import Elecciones from './Elecciones'
import Participacion from './Participacion'
import {
  ACambioPacto,
  AComparacion,
  AElectosComuna,
  AElectosRegion,
  AMantienePacto,
  APactos,
  ARanking,
  AReelecto,
} from './Alcaldes'
import {
  GGanadores,
  GPactos,
  GCambioPacto,
  GMantienePacto,
  GReelecto,
  GRanking,
  GComparacion,
  GElectosComuna,
  GElectosRegion,
} from './GORE'
import { CJPactos, CJElectosComuna, CJElectosRegion, CJComparacion, CJRanking } from './Concejales'
import { CRPactos, CRElectosComuna, CRElectosRegion, CRComparacion, CRRanking } from './CORE'
import MagicFrame from '@/components/MagicFrame/MagicFrame'
import { electionParams } from '@/constants'
import { ElectionType } from '@/types/Elections'
import { redirect, RouteObject } from 'react-router-dom'
import SubTema from './ChileEnDatos/SubTema'
import LayoutResumen from './LayoutResumen'
import LayoutTerritorio from './LayoutTerritorio'
import ChileEnDatos from './ChileEnDatos'
import ImgStatic from './ChileEnDatos/ImgStatic'
import MainMagicLayout from './MainMagicLayout'
export default [
  {
    element: <MainMagicLayout />,
    children: [
      {
        path: 'magic',
        element: <Alianza />,
      },
      {
        path: 'magic/elecciones',
        element: <Elecciones />,
      },
      {
        path: 'magic/participacion/:eleccion',
        element: <Participacion />,
      },
      {
        path: 'magic/chile-en-datos',
        element: <ChileEnDatos />,
      },
      {
        path: 'magic',
        element: <MagicFrame />,
        children: [
          {
            path: 'chile-en-datos/:tema/:subtema/:comuna',
            element: <SubTema />,
            loader: ({ params }) => {
              const { tema, subtema } = params
              if (
                (tema === 'politica' &&
                  (subtema === 'ranking_alcaldes_votos' ||
                    subtema === 'ranking_gore_votos' ||
                    subtema === 'ranking_alcaldes_porcentaje' ||
                    subtema === 'ranking_gore_porcentaje')) ||
                tema === 'analisis'
              )
                return redirect(`/magic/chile-en-datos/${tema}/${subtema}/`)
              return null
            },
          },
          {
            path: 'chile-en-datos/:tema/:subtema',
            element: <ImgStatic />,
            loader: ({ params }) => {
              const { tema, subtema } = params
              if (
                (tema === 'politica' &&
                  (subtema === 'ranking_alcaldes_votos' ||
                    subtema === 'ranking_gore_votos' ||
                    subtema === 'ranking_alcaldes_porcentaje' ||
                    subtema === 'ranking_gore_porcentaje')) ||
                tema === 'analisis'
              )
                return null
              // Default is always Santiago
              return redirect(`/magic/chile-en-datos/${tema}/${subtema}/13101`)
            },
          },
          {
            path: `${ElectionType.ALCALDES}`,
            element: <LayoutResumen />,
            children: [
              {
                index: true,
                element: <ARanking />,
              },
              {
                path: 'pactos',
                element: <APactos />,
              },
              {
                path: 'comparacion',
                element: <AComparacion />,
              },
            ],
          },
          {
            path: `${ElectionType.ALCALDES}/territorio`,
            loader: () =>
              redirect(
                `/magic/${ElectionType.ALCALDES}/territorio/${
                  electionParams[ElectionType.ALCALDES].defaultTerritorio
                }`
              ),
          },
          {
            path: `${ElectionType.ALCALDES}/territorio`,
            element: <LayoutTerritorio />,
            children: [
              {
                path: ':region',
                element: <AElectosRegion />,
              },
              {
                path: ':region/:comuna',
                element: <AElectosComuna />,
              },
              {
                path: ':region/cambio-pacto',
                element: <ACambioPacto />,
              },
              {
                path: ':region/mantiene-pacto',
                element: <AMantienePacto />,
              },
              {
                path: ':region/reelectos',
                element: <AReelecto />,
              },
            ],
          },
          {
            path: `${ElectionType.GORE}`,
            element: <LayoutResumen />,
            children: [
              {
                index: true,
                element: <GRanking />,
              },
              {
                path: 'pactos',
                element: <GPactos />,
              },
              {
                path: 'comparacion',
                element: <GComparacion />,
              },
              {
                path: 'ganadores',
                element: <GGanadores />,
              },
              {
                path: 'cambio-pacto',
                element: <GCambioPacto />,
              },
              {
                path: 'mantiene-pacto',
                element: <GMantienePacto />,
              },
              {
                path: 'reelectos',
                element: <GReelecto />,
              },
            ],
          },
          {
            path: `${ElectionType.GORE}/territorio`,
            loader: () =>
              redirect(
                `/magic/${ElectionType.GORE}/territorio/${
                  electionParams[ElectionType.GORE].defaultTerritorio
                }`
              ),
          },
          {
            path: `${ElectionType.GORE}/territorio`,
            element: <LayoutTerritorio />,
            children: [
              {
                path: ':region',
                element: <GElectosRegion />,
              },
              {
                path: ':region/:comuna',
                element: <GElectosComuna />,
              },
              {
                path: ':region/:comuna',
                loader: ({ params }) => {
                  const { region } = params
                  return redirect(`/magic/${ElectionType.GORE}/territorio/${region}`)
                },
              },
            ],
          },
          {
            path: `${ElectionType.CONCEJALES}`,
            element: <LayoutResumen />,
            children: [
              {
                path: 'pactos',
                element: <CJPactos />,
              },
              {
                path: 'comparacion',
                element: <CJComparacion />,
              },
              {
                element: <CJRanking />,
                index: true,
              },
            ],
          },
          {
            path: `${ElectionType.CONCEJALES}/territorio`,
            loader: () =>
              redirect(
                `/magic/${ElectionType.CONCEJALES}/territorio/${
                  electionParams[ElectionType.CONCEJALES].defaultTerritorio
                }`
              ),
          },
          {
            path: `${ElectionType.CONCEJALES}/territorio`,
            element: <LayoutTerritorio />,
            children: [
              {
                path: ':region',
                element: <CJElectosRegion />,
              },
              {
                path: ':region/:comuna',
                element: <CJElectosComuna />,
              },
            ],
          },
          {
            path: `${ElectionType.CORE}`,
            element: <LayoutResumen />,
            children: [
              {
                path: 'pactos',
                element: <CRPactos />,
              },
              {
                path: 'comparacion',
                element: <CRComparacion />,
              },
              {
                index: true,
                element: <CRRanking />,
              },
            ],
          },
          {
            path: `${ElectionType.CORE}/territorio`,
            loader: () =>
              redirect(
                `/magic/${ElectionType.CORE}/territorio/${
                  electionParams[ElectionType.CORE].defaultTerritorio
                }`
              ),
          },
          {
            path: `${ElectionType.CORE}/territorio`,
            element: <LayoutTerritorio />,
            children: [
              {
                path: ':circunscripcion',
                element: <CRElectosRegion />,
              },
              {
                path: ':circunscripcion/electos',
                element: <CRElectosComuna />,
              },
              {
                path: ':circunscripcion/:comuna',
                loader: ({ params }) => {
                  const { circunscripcion } = params
                  return redirect(
                    `/magic/${ElectionType.CORE}/territorio/${circunscripcion}/electos`
                  )
                },
              },
            ],
          },
        ],
      },
    ],
  },
] as RouteObject[]
