import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'

import { Info } from 'lucide-react'
import { cn } from '@/lib/utils'

export default function InfoPopoverForMobile({
  content,
  size = 12,
  side = 'bottom',
  classNameContent = '',
}: {
  classNameContent?: string
  content: string
  size?: number
  side?: 'top' | 'right' | 'bottom' | 'left'
}) {
  return (
    <Popover>
      <PopoverTrigger className="xl:hidden block">
        <Info size={size} color="#3c3c3c" />
      </PopoverTrigger>
      <PopoverContent
        side={side}
        align="center"
        className="bg-black xl:hidden text-white text-xs px-3 py-1.5 rounded-md overflow-hidden w-fit"
      >
        <p className={cn('max-w-[150px] w-fit', classNameContent)}>{content}</p>
      </PopoverContent>
    </Popover>
  )
}
