export const TEMAS = {
  poblacion: {
    name: 'Población',
    icon: '/menu/poblacion.svg',
    subtemas: {
      genero_edad: {
        name: 'Género y edad',
      },
      hombres: {
        name: 'Proyección Hombres',
      },
      mujeres: {
        name: 'Proyección Mujeres',
      },
    },
  },
  extranjeros: {
    name: 'Extranjeros',
    icon: '/menu/inmigracion.svg',
    subtemas: {
      voto_extranjero: {
        name: 'Voto extranjero',
      },
    },
  },
  vivienda: {
    name: 'Vivienda',
    icon: '/menu/vivienda.svg',
    subtemas: {
      hacinamiento: {
        name: 'Hacinamiento',
      },
      subsidio: {
        name: 'Subsidio',
      },
    },
  },
  educacion: {
    name: 'Educación',
    icon: '/menu/educacion.svg',
    subtemas: {
      dependencia: {
        name: 'Dependencia',
      },
      matricula: {
        name: 'Matrícula',
      },
      simce: {
        name: 'Simce',
      },
      ingreso_ed_superior: {
        name: 'Ingreso E. Superior',
      },
      titulacion_ed_superior: {
        name: 'Titulación E. Superior',
      },
    },
  },
  politica: {
    name: 'Política',
    icon: '/menu/politica.svg',
    subtemas: {
      alcaldes_pactos: {
        name: 'Alcaldes y Pactos',
      },
      candidatos_gore: {
        name: 'Candidatos GORE',
      },
      candidatos_alcaldes: {
        name: 'Candidatos Alcaldes',
      },
      gore_2021: {
        name: 'Resultados GORE',
      },
      ranking_alcaldes_votos: {
        name: 'Ranking Alcaldes',
      },
      ranking_gore_votos: {
        name: 'Ranking GORE',
      },
      ranking_alcaldes_porcentaje: {
        name: 'Ranking Alcaldes %',
      },
      ranking_gore_porcentaje: {
        name: 'Ranking GORE %',
      },
    },
  },
  analisis: {
    name: 'Análisis',
    icon: '/menu/analisis.svg',
    subtemas: {
      numero_candidatos_region: {
        name: 'Candidatos región',
      },
      candidatos_alcaldes_pacto: {
        name: 'Candidatos alcaldes',
      },
      porcentaje_extranjeros: {
        name: 'Extranjeros comuna',
      },
      irrupcion_independientes: {
        name: 'Independientes',
      },
      extranjeros_inscritos: {
        name: 'Extranjeros inscritos',
      },
      numero_candidatos_comuna: {
        name: 'Candidatos comuna',
      },
      evolucion_participacion: {
        name: 'Participación',
      },
      resultados_elecciones_rm: {
        name: 'Elecciones RM',
      },
      resultados_elecciones_nunoa: {
        name: 'Elecciones Nuñoa',
      },
    },
  },
  salud: {
    name: 'Salud',
    icon: '/menu/salud.svg',
    subtemas: {
      establecimientos: {
        name: 'Establecimientos',
      },
    },
  },
  seguridad: {
    name: 'Seguridad',
    icon: '/menu/seguridad.svg',
    subtemas: {
      denuncias: {
        name: 'Denuncias',
      },
      detenciones: {
        name: 'Detenciones',
      },
    },
  },
  ninez: {
    name: 'Niñez',
    icon: '/menu/ninez.svg',
    subtemas: {
      ed_parvularia_establecimientos: {
        name: 'E. Parvularia Establecimientos',
      },
      ed_parvularia_matricula: {
        name: 'E. Parvularia Matrículas ',
      },
    },
  },
}

export const DATOSCOMUNALESTEMAS = {
  poblacion: {
    name: 'Población',
    icon: '/menu/poblacion.svg',
    gaName: 'live2024_datoscomunales_poblacion_dropdown',
    subtemas: {
      genero_edad: {
        name: 'Género y edad',
        gaName: 'live2024_datoscomunales_poblacion_generoyedad',
      },
      hombres: {
        name: 'Proyección Hombres',
        gaName: 'live2024_datoscomunales_poblacion_proyeccion_hombres',
      },
      mujeres: {
        name: 'Proyección Mujeres',
        gaName: 'live2024_datoscomunales_poblacion_proyeccion_mujeres',
      },
    },
  },
  extranjeros: {
    name: 'Extranjeros',
    icon: '/menu/inmigracion.svg',
    gaName: 'live2024_datoscomunales_extranjeros_dropdown',
    subtemas: {
      voto_extranjero: {
        name: 'Voto extranjero',
        gaName: 'live2024_datoscomunales_extranjeros_voto_extranjero',
      },
    },
  },
  vivienda: {
    name: 'Vivienda',
    icon: '/menu/vivienda.svg',
    gaName: 'live2024_datoscomunales_vivienda_dropdown',
    subtemas: {
      hacinamiento: {
        name: 'Hacinamiento',
        gaName: 'live2024_datoscomunales_vivienda_hacinamiento',
      },
      subsidio: {
        name: 'Subsidio',
        gaName: 'live2024_datoscomunales_vivienda_subsidio',
      },
    },
  },
  educacion: {
    name: 'Educación',
    icon: '/menu/educacion.svg',
    gaName: 'live2024_datoscomunales_educacion_dropdown',
    subtemas: {
      dependencia: {
        name: 'Dependencia',
        gaName: 'live2024_datoscomunales_educacion_dependencia',
      },
      matricula: {
        name: 'Matrícula',
        gaName: 'live2024_datoscomunales_educacion_matricula',
      },
      simce: {
        name: 'Simce',
        gaName: 'live2024_datoscomunales_educacion_simce',
      },
      ingreso_ed_superior: {
        name: 'Ingreso E. Superior',
        gaName: 'live2024_datoscomunales_educacion_ingreso_ed_superior',
      },
      titulacion_ed_superior: {
        name: 'Titulación E. Superior',
        gaName: 'live2024_datoscomunales_educacion_titulacion_ed_superior',
      },
    },
  },
  politica: {
    name: 'Política',
    icon: '/menu/politica.svg',
    gaName: 'live2024_datoscomunales_politica_dropdown',
    subtemas: {
      alcaldes_pactos: {
        name: 'Alcaldes y Pactos',
        gaName: 'live2024_datoscomunales_politica_alcaldes_pactos',
      },
    },
  },
  salud: {
    name: 'Salud',
    icon: '/menu/salud.svg',
    gaName: 'live2024_datoscomunales_salud_dropdown',
    subtemas: {
      establecimientos: {
        name: 'Establecimientos',
        gaName: 'live2024_datoscomunales_salud_establecimientos',
      },
    },
  },
  seguridad: {
    name: 'Seguridad',
    icon: '/menu/seguridad.svg',
    gaName: 'live2024_datoscomunales_seguridad_dropdown',
    subtemas: {
      denuncias: {
        name: 'Denuncias',
        gaName: 'live2024_datoscomunales_seguridad_denuncias',
      },
      detenciones: {
        name: 'Detenciones',
        gaName: 'live2024_datoscomunales_seguridad_detenciones',
      },
    },
  },
  ninez: {
    name: 'Niñez',
    icon: '/menu/ninez.svg',
    gaName: 'live2024_datoscomunales_ninez_dropdown',
    subtemas: {
      ed_parvularia_establecimientos: {
        name: 'E. Parvularia Establecimientos',
        gaName: 'live2024_datoscomunales_ninez_parvularia_establecimientos',
      },
      ed_parvularia_matricula: {
        name: 'E. Parvularia Matrículas ',
        gaName: 'live2024_datoscomunales_ninez_parvularia_matricula',
      },
    },
  },
}

export type Tema = keyof typeof TEMAS

export type Subtema =
  | keyof typeof TEMAS.poblacion.subtemas
  | keyof typeof TEMAS.extranjeros.subtemas
  | keyof typeof TEMAS.vivienda.subtemas
  | keyof typeof TEMAS.educacion.subtemas
  | keyof typeof TEMAS.politica.subtemas
  | keyof typeof TEMAS.salud.subtemas
  | keyof typeof TEMAS.seguridad.subtemas
  | keyof typeof TEMAS.ninez.subtemas

export type FileChileEnDatos = {
  // [tema in Tema]: {
  [x: string]: {
    // [subtema in Subtema]: {
    [x: string]: {
      [key: string]: {
        link_img_mg: string | null
        link_img_web: string | null
        link_img_mobile: string | null
      }
    }
  }
}

export const Comunas10Mil = [
  '9101',
  '7308',
  '9117',
  '3103',
  '13303',
  '2301',
  '9118',
  '8111',
  '9210',
  '8312',
  '14101',
  '3301',
  '5101',
  '9211',
  '4106',
  '9119',
  '7407',
  '5804',
  '9120',
  '5109',
  '13132',
  '7408',
  '8313',
  '16109',
  '5405',
  '5602',
  '1107',
  '10202',
  '4103',
  '9201',
  '2101',
  '8202',
  '15101',
  '11201',
  '13402',
  '16102',
  '5402',
  '8303',
  '2201',
  '10102',
  '3102',
  '5502',
  '13403',
  '5302',
  '8203',
  '9102',
  '5603',
  '5102',
  '10201',
  '5702',
  '7201',
  '13102',
  '13103',
  '3201',
  '6302',
  '8103',
  '16101',
  '16103',
  '6303',
  '9121',
  '10203',
  '6102',
  '16203',
  '16302',
  '7402',
  '13301',
  '9202',
  '6104',
  '4302',
  '8101',
  '13104',
  '5103',
  '7102',
  '3101',
  '4102',
  '8102',
  '11101',
  '9103',
  '9203',
  '13503',
  '8205',
  '7103',
  '7301',
  '10205',
  '3202',
  '6105',
  '13105',
  '16104',
  '13602',
  '5604',
  '5605',
  '13106',
  '8104',
  '9105',
  '10104',
  '10105',
  '14202',
  '9106',
  '9107',
  '6106',
  '5503',
  '7302',
  '8112',
  '8105',
  '13107',
  '4201',
  '13108',
  '1101',
  '13603',
  '13109',
  '5504',
  '13110',
  '13111',
  '5401',
  '13112',
  '13113',
  '4101',
  '14201',
  '14203',
  '8304',
  '13302',
  '14103',
  '6107',
  '13114',
  '9108',
  '8201',
  '5802',
  '7401',
  '10107',
  '5703',
  '13115',
  '13116',
  '13117',
  '9109',
  '7403',
  '9205',
  '8206',
  '5301',
  '8301',
  '14104',
  '10106',
  '4203',
  '8106',
  '6108',
  '13118',
  '13119',
  '6109',
  '13504',
  '14106',
  '7105',
  '10108',
  '2102',
  '13501',
  '7304',
  '4303',
  '6110',
  '8305',
  '8306',
  '6305',
  '12401',
  '16303',
  '5506',
  '9111',
  '13120',
  '6111',
  '5803',
  '10301',
  '4301',
  '13604',
  '9112',
  '14107',
  '13404',
  '6306',
  '14108',
  '7404',
  '13121',
  '13605',
  '13122',
  '7107',
  '8107',
  '6307',
  '6112',
  '6113',
  '6201',
  '16106',
  '13202',
  '9114',
  '1401',
  '13123',
  '5105',
  '9115',
  '13124',
  '13201',
  '10101',
  '10109',
  '4304',
  '12101',
  '9208',
  '10303',
  '5705',
  '10304',
  '10208',
  '13125',
  '16107',
  '5501',
  '5801',
  '6114',
  '13126',
  '5107',
  '16201',
  '6101',
  '7305',
  '13127',
  '13128',
  '6115',
  '6116',
  '7405',
  '5303',
  '14204',
  '7108',
  '10305',
  '7306',
  '9116',
  '7307',
  '4204',
  '5601',
  '13401',
  '16301',
  '7109',
  '5304',
  '5701',
  '6301',
  '16108',
  '7406',
  '13129',
  '13203',
  '13130',
  '16305',
  '10307',
  '8108',
  '13131',
  '6117',
  '8311',
  '6310',
  '8109',
  '5706',
  '13101',
  '5606',
  '13601',
  '7101',
  '8110',
  '2104',
]
