import { useEffect } from 'react'
import useRefDimensions from './useRefDimensions'
import { useLocation, useNavigate } from 'react-router-dom'
import useRouting from '@/stores/useRouting'

type useIframeSyncProps = {
  parentRef: React.RefObject<HTMLIFrameElement>
}

const useIframeSync = ({ parentRef }: useIframeSyncProps) => {
  const { setIsIframe } = useRouting()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dimensions = useRefDimensions({ ref: parentRef })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const props = { type: 'iframeMessage', content: { pathname, dimensions } }
      const isIframe = window.self !== window.top
      if (isIframe) setIsIframe(true)
      if (isIframe) window.parent.postMessage(props, '*')
    }
  }, [pathname, dimensions, setIsIframe])

  // Listen for message from parent
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const message = event.data
      if (message.type === 'parentMessage') {
        const { pathname } = message.content
        navigate(pathname)
      }
    }
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [navigate])
}

export default useIframeSync
