import styled from 'styled-components'
import { Fragment, useState } from 'react'
import Card from './Card'
import ToggleSwitch from './ToggleSwitch'
import ContentPercentageBar, {
  DefaultBar,
  ContentLabel,
  SupPercentageLabel,
} from '../Web/PercentageBar'
import { colors } from '@/constants'
import MobileComparacionBar from './MobileComparacionBar'
import DecideChileMono from '../DecideChileMono'
import InfoToolTip from '../InfoToolTip'
export type Data = {
  pacto: string
  color: string
  electos_2021: number
  electos_2024: number
  porcentaje_2021: number
  porcentaje_2024: number
  orden: number
}

type Comparacion = {
  data: Data[]
  verticalSeparatorBefore?: string
}

function ComparisonBarItem({
  isChecked,
  item,
  maxValueElectos,
  showTooltip,
}: {
  isChecked: boolean
  item: Data
  maxValueElectos: number
  showTooltip?: boolean
}) {
  const formatDifference = (value: number, formatter: (num: number) => string) => {
    const sign = value > 0 ? '+' : ''
    const formattedValue = formatter(value)
    return `${sign}${formattedValue}`
  }

  const percentageDifference = (
    <SupPercentageLabel percentage={item.porcentaje_2024 - item.porcentaje_2021} includeSign isWeb/>
  )
  const amountDifference = formatDifference(item.electos_2024 - item.electos_2021, (num) =>
    num.toString()
  )

  return (
    <BarItemContainer>
      <PercentageBarContainer>
        <ContentPercentageBar
          percentage={isChecked ? item.porcentaje_2021 : item.electos_2021}
          color={`${item.color}50`}
          default_={<DefaultBar className="text-[12px]" bordered />}
          maxHeight={80}
          maxPercentage={isChecked ? 100 : maxValueElectos}
          bordered
          outerContent={
            <ContentLabel className="text-[12px] font-medium " color={`${item.color}90`}>
              {isChecked ? (
                <SupPercentageLabel
                  hideTrailingZero
                  especialSupForBar
                  percentage={item.porcentaje_2021}
                />
              ) : (
                item.electos_2021.toString()
              )}
            </ContentLabel>
          }
        />
        <ContentPercentageBar
          outerContent={
            <ContentLabel className="text-[12px] font-medium" color={item.color}>
              {isChecked ? (
                <SupPercentageLabel
                  hideTrailingZero
                  especialSupForBar
                  percentage={item.porcentaje_2024}
                />
              ) : (
                item.electos_2024.toString()
              )}
            </ContentLabel>
          }
          maxHeight={80}
          maxPercentage={isChecked ? 100 : maxValueElectos}
          percentage={isChecked ? item.porcentaje_2024 : item.electos_2024}
          color={item.color}
          bordered
        />
      </PercentageBarContainer>
      <CategorieLabel>
        {item.pacto}
        {showTooltip && (
          <InfoToolTip content="Conjunto de candidatos que no pertenecen a las listas anteriores." />
        )}
      </CategorieLabel>

      <div className="flex justify-center mgw:h-[52px] h-[40px]">
        {item.porcentaje_2021 !== 0 && (
          <DifferenceTag>{isChecked ? percentageDifference : amountDifference}</DifferenceTag>
        )}
      </div>
    </BarItemContainer>
  )
}

export default function Comparacion({ data, verticalSeparatorBefore = '' }: Comparacion) {
  const [isChecked, setIsChecked] = useState(false)

  const handleChange = () => {
    setIsChecked((prev) => !prev)
  }

  const sortData = [...data].sort((a, b) => a.orden - b.orden)
  const maxElectos = Math.max(
    ...data.map((item) => item.electos_2021),
    ...data.map((item) => item.electos_2024)
  )

  return (
    <Card
      title={`Comparación Pactos 2021 y 2024`}
      separatorColor="#CCCCCC"
      toggle={
        <ToggleSwitch
          checkedLabel="N°"
          uncheckedLabel="%"
          checked={isChecked}
          handleToggle={handleChange}
        />
      }
    >
      <div className="flex flex-col h-auto xl:h-[360px]">
        <Legend className="justify-center xl:justify-start">
          <LegendItem>
            <ColorBox color={`${colors.gray3Dch}40`} />
            <span>2021</span>
          </LegendItem>
          <LegendItem>
            <ColorBox color={colors.blackDch} />
            <span>2024</span>
          </LegendItem>
        </Legend>
        <div className="flex w-full justify-center xl:justify-start mt-[6px] mb-[-6px]">
          <DecideChileMono />
        </div>
        <ComparisonGrid $number={data.length} className="hidden xl:grid">
          {sortData.map((item, index) => (
            <Fragment key={`pactos-${item.pacto}`}>
              {item.pacto === verticalSeparatorBefore && <VSeparatorPactos />}
              <ComparisonBarItem
                key={index}
                item={item}
                isChecked={isChecked}
                maxValueElectos={maxElectos}
                showTooltip={item.pacto === 'Otros'}
              />
            </Fragment>
          ))}
        </ComparisonGrid>

        <div className="grid grid-cols-4 gap-4 xl:hidden mt-4">
          {sortData.map((item, index) => (
            <MobileComparacionBar
              key={index}
              data={item}
              isChecked={isChecked}
              maxValueElectos={maxElectos}
            />
          ))}
        </div>
      </div>
    </Card>
  )
}

const ComparisonGrid = styled.div<{ $number: number }>`
  height: 100%;
  grid-template-columns: repeat(${({ $number }) => $number - 3}, 1fr) 1.04fr min-content 1.04fr 1fr;
  --dataSize: ${({ $number }) => $number};
`

const Legend = styled.div`
  display: flex;
  gap: 20px;
`

const LegendItem = styled.div.attrs({
  className: 'text-[15px]',
})`
  display: flex;
  align-items: center;
  gap: 10px;
`

const ColorBox = styled.div.attrs<{ color: string }>({
  className: 'w-[16px] h-[16px] rounded-[2px]',
})`
  background-color: ${({ color }) => color};
`

const PercentageBarContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: 0 calc(100% / var(--dataSize));
  gap: calc(40% / var(--dataSize));
  border-bottom: 0.6px solid #e0e0e0;
`

const BarItemContainer = styled.div`
  display: flex;
  height: 330px;
  flex-direction: column;
  position: relative;
  color: ${colors.blackDch};
  &:nth-last-of-type(4) ${PercentageBarContainer} {
    padding-right: calc(150% / var(--dataSize));
  }
  &:nth-last-of-type(2) ${PercentageBarContainer} {
    padding-left: calc(150% / var(--dataSize));
  }
`

const CategorieLabel = styled.div.attrs({
  className: 'text-[12px] h-[50px]',
})`
  padding: 10px calc(70% / var(--dataSize));
  text-align: center;
  line-height: 16px;
  font-weight: 500;
  flex-direction: column;
  display: flex;
  align-items: center;
`

const DifferenceTag = styled.div.attrs({
  className: 'w-[50px] h-[22px]',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 6px;
  border: 1px solid ${colors.gray3Dch};
  padding: 0 8px;
`

const VSeparatorPactos = styled.div`
  height: 100%;
  border-right: 0.6px solid #e0e0e0;
`
