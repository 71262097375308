import useRouting from '@/stores/useRouting'

const IMG_STATIC = {
  ranking_alcaldes_votos: '/politica/ranking_alcaldes_2021_votos.png',
  ranking_gore_votos: '/politica/ranking_gore_2021_votos.png',
  ranking_alcaldes_porcentaje: '/politica/ranking_alcaldes_2021_porcentaje.png',
  ranking_gore_porcentaje: '/politica/ranking_gore_2021_porcentaje.png',

  candidatos_alcaldes_pacto: '/analisis/candidatos_alcaldes_pacto.png',
  evolucion_participacion: '/analisis/evolucion_participacion.png',
  extranjeros_inscritos: '/analisis/extranjeros_inscritos.png',
  irrupcion_independientes: '/analisis/irrupcion_independientes.png',
  numero_candidatos_comuna: '/analisis/numero_candidatos_comuna.png',
  porcentaje_extranjeros: '/analisis/porcentaje_extranjeros.png',
  numero_candidatos_region: '/analisis/numero_candidatos_region.png',
  resultados_elecciones_rm: '/analisis/resultados_elecciones_rm.png',
  resultados_elecciones_nunoa: '/analisis/resultados_elecciones_nunoa.png',
}

export default function ImgStatic() {
  const { currentSubtema } = useRouting()
  if (!currentSubtema) return null

  const img = IMG_STATIC[currentSubtema as keyof typeof IMG_STATIC]

  return (
    <div className="h-full w-full">
      <img src={img} alt="img_static_chile_en_datos" height={980} width={1640} />
    </div>
  )
}
