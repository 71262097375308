import Ranking from '@/components/Web/Ranking'
import useElectionData from '@/stores/useElectionData'
import { useMemo } from 'react'
import { PACTOS } from '@/constants/pactos'
import { ElectionType } from '@/types/Elections'
import useRouting from '@/stores/useRouting'

export default function CoreRanking({ option = 'top' }: { option?: 'top' | 'bottom' }) {
  const {
    files: { core },
  } = useElectionData()
  const { currentCircunscripcion } = useRouting()

  const electos = useMemo(() => {
    return core || []
  }, [core])

  const processedData = useMemo(() => {
    if (electos.length === 0) {
      return []
    }
    const uniqueComunas = new Set()
    const oneElectoforCircunscripcion = electos
      .sort((a, b) => (option === 'bottom' ? a.votos - b.votos : b.votos - a.votos))
      .filter((item) => {
        if (uniqueComunas.has(item.cod_circunscripcion)) return false
        uniqueComunas.add(item.cod_circunscripcion)
        return true
      })

    return oneElectoforCircunscripcion.map((electo) => ({
      ...electo,
      pacto: PACTOS[ElectionType.CORE][electo.cod_pacto]?.shortName || electo.cod_pacto,
      color: PACTOS[ElectionType.CORE][electo.cod_pacto]?.color || 'gray',
      imagen: electo.imagen || '/userProfile.png',
      partido: electo.cod_partido,
      cod_comuna: electo.cod_circunscripcion,
      comuna: electo.circunscripcion,
      region: '',
      darkColor: PACTOS[ElectionType.CORE][electo.cod_pacto]?.darkColor || '',
      color_texto: PACTOS[ElectionType.CORE][electo.cod_pacto]?.darkColor || '',
      monoLabel: currentCircunscripcion || electo.cod_circunscripcion,
    }))
  }, [currentCircunscripcion, electos, option])

  return <Ranking data={processedData} option={option} />
}
