import styled from 'styled-components'
import React from 'react'
import { colors } from '@/constants'
import { ElectCandidateBadge } from '@/components/Web/CandidateBadge'
import Card from '@/components/Web/Card'
import { Skeleton } from '../ui/skeleton'

type Electo = {
  nombre: string
  partido: string
  imagen: string
  color: string
}

export type Data = {
  comuna: string
  electo_2024: Electo
  electo_2021: Electo
}

type CambioPacto = {
  data: Data[]
  selection?: React.ReactNode
}

export default function CambioPacto({ data, selection }: CambioPacto) {
  const fakedata: Electo = {
    nombre: '',
    partido: '',
    imagen: 'userProfile.png',
    color: '',
  }
  return (
    <Card title="Comunas que Cambian Pacto" selector={selection}>
      <GridContainer>
        <div className="max-h-96 overflow-y-auto">
          <GridTable>
            <GridHeaderCell className="text-left sticky top-0 bg-white z-30">COMUNA</GridHeaderCell>
            <GridHeaderCell className="sticky top-0 bg-white z-30">2024</GridHeaderCell>
            <GridHeaderCell className="sticky top-0 z-30">2021</GridHeaderCell>
            {data.length === 0 &&
              Array.from({ length: 8 }).map((_, index) => (
                <React.Fragment key={index}>
                  <GridCell className="flex">
                    <Skeleton className="w-[80%] h-3" />
                  </GridCell>
                  <GridCell className="flex items-center ">
                    <ElectCandidateBadge small electo={fakedata} isBlank />
                  </GridCell>
                  <GridCell className="flex items-center">
                    <ElectCandidateBadge small electo={fakedata} isBlank />
                  </GridCell>
                </React.Fragment>
              ))}
            {data.map((item, index) => (
              <React.Fragment key={index}>
                <GridCell className="text-[13px] border-b border-b-[#eeeef0]">
                  {item.comuna}
                </GridCell>
                <GridCell className="border-b border-b-[#eeeef0]">
                  <ElectCandidateBadge small electo={item.electo_2024} />
                </GridCell>
                <GridCell className="border-b border-b-[#eeeef0]">
                  <ElectCandidateBadge small electo={item.electo_2021} />
                </GridCell>
              </React.Fragment>
            ))}
          </GridTable>
        </div>
      </GridContainer>
    </Card>
  )
}

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const GridTable = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.8fr 1.2fr 1.2fr;
  grid-auto-rows: min-content;
`

const GridHeaderCell = styled.div`
  font-family: 'JetBrains Mono';
  font-size: 12px;
  color: ${colors.blackDch};
  border-bottom: 1px solid ${colors.gray1Dch};
  padding: 10px 0;
  &:nth-of-type(3n) {
    padding-left: 6px;
    background-color: #fafafa;
  }
`

const GridCell = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  color: ${colors.blackDch};
  &:nth-of-type(3n) {
    padding-left: 6px;
    background-color: #fafafa;
  }
`
