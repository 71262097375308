import Reelecto from '@/components/MagicWall/Reelecto'
import { buildReelectosAlcalde } from '@/lib/buildDataViews'
import useElectionData from '@/stores/useElectionData'
import useRouting from '@/stores/useRouting'
import useTerritorios from '@/stores/useTerritorios'
import { useMemo } from 'react'

export default function AReelecto() {
  const {
    files: { alcaldes },
    comparador: { alcaldes: alcaldes2021 },
  } = useElectionData()

  const { currentRegion } = useRouting()
  const { setComunaColor } = useTerritorios()

  const data = useMemo(() => {
    if (!alcaldes || !alcaldes2021 || !currentRegion) {
      return []
    }
    const { data, comunasColor } = buildReelectosAlcalde(currentRegion, alcaldes, alcaldes2021)

    setComunaColor(comunasColor)
    return data.sort((a, b) => a.comuna.localeCompare(b.comuna))
  }, [alcaldes, alcaldes2021, currentRegion, setComunaColor])

  return <Reelecto data={data} pageSize={5} />
}
