import HorizontalMapSvg from '@/assets/horizontalMap.svg'
import { colors } from '@/constants'
import styled from 'styled-components'
import { MapProps } from '@/components/Map/ComunasChileMap'

type HorizontalMapProps = {
  regionResults: {
    name: string
    selectedColor: string
    code: number
  }[]
  children?: React.ReactNode
} & MapProps

export default function IfrHorizontalMap({ regionResults }: HorizontalMapProps) {
  return (
    <Wrapper>
      <Circles>
        {Object.entries(regionResults).map(([key, region]) => (
          <Circle key={`${key}-${region.selectedColor}`} selectedcolor={region.selectedColor}>
            {region.name}
          </Circle>
        ))}
      </Circles>
      <Map />
      <div className="flex text-[10px] ifr:text-[12px] items-center gap-1">
        <Circle selectedcolor={null} className="!h-[8px] ifr:!h-[10px] !w-[8px] ifr:!w-[10px]" />
        2da Vuelta
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  width: 100%;
`

const Map = styled(HorizontalMapSvg).attrs({
  className: 'px-[6px] sifr:px-[10px] ifr:px-[12px] -mt-[2px] ifr:-mt-[4px]',
})`
  height: 100%;
  width: 100%;
`

const Circle = styled.div.attrs<{ selectedcolor?: string | null | undefined }>({
  className: `
    w-[14px] sifr:w-[20px] ifr:w-[26px]
    h-[14px] sifr:h-[20px] ifr:h-[26px]
    text-[7px] sifr:text-[9px] ifr:text-[12px]
    aspect-square
`,
})`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ selectedcolor }) =>
    selectedcolor === undefined ? colors.gray2Dch : selectedcolor || colors.whiteDch};
  border: ${({ selectedcolor }) =>
    selectedcolor === undefined || selectedcolor ? 'none' : `1px solid ${colors.gray3Dch}`};
  color: ${({ selectedcolor }) =>
    selectedcolor === undefined || selectedcolor ? colors.whiteDch : colors.gray3Dch};
  font-weight: 600;
`

const Circles = styled.div.attrs({
  className: 'gap-[2px] sifr:gap-1 justify-between',
})`
  font-family: 'JetBrains Mono', monospace;
  display: flex;
  width: 100%;
  z-index: 0;
`
