import useRouting from '@/stores/useRouting'
import { useNavigate } from 'react-router-dom'

import RegionSelector from '@/components/RegionSelector'
import PactoElectos from '@/components/Web/PactoElectos'
import { getRegionResultsByPactoConcejales } from '@/lib/dataProcess'
import useElectionData from '@/stores/useElectionData'
import useTerritorios, { regiones } from '@/stores/useTerritorios'
import { useMemo } from 'react'

import { PACTOS_CONCEJALES } from '@/constants'
import { buildRegionResultsByPacto } from '@/lib/buildDataViews'
import { ElectionType } from '@/types/Elections'

export default function CPactoRegion() {
  const { currentRegion } = useRouting()
  const { setComunaColor } = useTerritorios()
  const {
    files: { concejales },
    comparador: { concejales: concejales2021 },
    static: { escanos_concejales },
  } = useElectionData()
  const navigate = useNavigate()

  const data = useMemo(() => {
    if (!concejales2021 || !concejales || !currentRegion || !escanos_concejales) return []
    const region = regiones.find((r) => r.codigo === Number(currentRegion))
    const { countElectosByPacto, countElectosByPacto2021 } = getRegionResultsByPactoConcejales(
      concejales,
      concejales2021,
      region?.comunas || []
    )
    const totalElectos = region?.comunas.reduce((acc, comuna) => {
      return acc + escanos_concejales[comuna]
    }, 0)

    // TODO: Check if totalElectos is the same for both years
    const data = buildRegionResultsByPacto(
      countElectosByPacto,
      countElectosByPacto2021,
      totalElectos || 0,
      ElectionType.CONCEJALES
    )

    // TODO: Add filter of electos
    const comunasColor: Record<number, string> = {}
    region?.comunas.forEach((comuna) => {
      const candidatosComuna = concejales.filter(
        (candidato) => Number(candidato.cod_comuna) === comuna
      )
      const orderedByVotos = candidatosComuna.sort((a, b) => b.votos - a.votos)
      comunasColor[comuna] =
        orderedByVotos.length > 1 ? PACTOS_CONCEJALES[orderedByVotos[0]?.cod_pacto]?.color : ''
    })

    setComunaColor(comunasColor)

    return data.sort((a, b) => b.electos_2024 - a.electos_2024)
  }, [concejales, concejales2021, currentRegion, escanos_concejales, setComunaColor])

  const handleRegion = (region: string) => {
    navigate(`/${ElectionType.CONCEJALES}/territorio/${region}/pactos`)
  }

  return (
    <div className="h-full">
      <PactoElectos
        data={data}
        selection={
          <RegionSelector
            className="w-[50%] hidden xl:flex"
            onClick={handleRegion}
            value={String(currentRegion)}
          />
        }
        title="Pactos por Territorio"
      />
    </div>
  )
}
