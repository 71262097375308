import MCambioPacto, { type Data } from '@/components/MagicWall/GORE/CambioMantienePacto'
import { buildCambioMantienePactoGORE } from '@/lib/buildDataViews'
import useElectionData from '@/stores/useElectionData'
import { regiones } from '@/stores/useTerritorios'
import { useMemo } from 'react'

export default function GCambioPacto() {
  const {
    files: { gore },
    comparador: { gore: gore2021 },
  } = useElectionData()

  const data: Data[] = useMemo(() => {
    if (!gore || !gore2021) return []
    return buildCambioMantienePactoGORE(gore, gore2021, 'cambia', 5)
  }, [gore, gore2021])

  const regionesData = regiones
    .filter((region) => region.codigo !== 17)
    .sort((a, b) => {
      if (a.orden && b.orden) {
        return a.orden - b.orden
      }
      return 0
    })
    .map((region) => ({
      code: region.codigo,
      name: region.acronimo || region.nombre,
      selectedColor:
        data.find((item) => item.region_codigo === region.codigo)?.electo_2024.color || '#B1B1BB',
    }))

  return <MCambioPacto title="Cambia Pacto" pageSize={4} data={data} regiones={regionesData} />
}
