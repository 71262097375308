import useRouting from '@/stores/useRouting'
import useTerritorios, { regiones } from '@/stores/useTerritorios'
import { getRegionResultsByPactoAlcalde, getTerritorioColorDictSimple } from '@/lib/dataProcess'
import { useEffect, useMemo } from 'react'
import useElectionData from '@/stores/useElectionData'
import ElectosPacto from '@/components/MagicWall/ElectosPacto'
import { ElectionType } from '@/types/Elections'
import { buildRegionResultsByPacto } from '@/lib/buildDataViews'

export default function AElectosRegion() {
  const { currentRegion } = useRouting()
  const { setComunaColor } = useTerritorios()
  const {
    files: { alcaldes },
    comparador: { alcaldes: alcaldes2021 },
  } = useElectionData()

  const data = useMemo(() => {
    if (!alcaldes2021 || !alcaldes || !currentRegion) return []
    const region = regiones.find((r) => r.codigo === Number(currentRegion))
    const { countElectosByPacto, countElectosByPacto2021 } = getRegionResultsByPactoAlcalde(
      alcaldes,
      alcaldes2021,
      region?.comunas || []
    )
    const data = buildRegionResultsByPacto(
      countElectosByPacto,
      countElectosByPacto2021,
      region?.comunas.length || 0,
      ElectionType.ALCALDES
    )
    return data.sort((a, b) => b.electos_2024 - a.electos_2024)
  }, [alcaldes, alcaldes2021, currentRegion])

  const colorDict = useMemo(
    () => getTerritorioColorDictSimple(alcaldes ?? [], ElectionType.ALCALDES),
    [alcaldes]
  )

  useEffect(() => {
    setComunaColor(colorDict)
  }, [colorDict, setComunaColor])

  return (
    <div className="h-full">
      <ElectosPacto data={data} pageSize={8} />
    </div>
  )
}
