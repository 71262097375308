import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { colors } from '@/constants'
import { NameCandidateBadge } from '@/components/MagicWall/CandidateBadget'
import PaginatedCard from '@/components/MagicWall/PaginatedCard'
import useRouting from '@/stores/useRouting'
import { Skeleton } from '../ui/skeleton'

export type Data = {
  comuna: string
  pacto: string
  nombre: string
  partido: string
  imagen: string
  color: string
  porcentaje_2021: string
  porcentaje_2024: string
}

export const fakeItem: Data = {
  comuna: '',
  pacto: '',
  nombre: '',
  partido: '',
  imagen: 'userProfile.png',
  color: '#3c3c3c',
  porcentaje_2021: '',
  porcentaje_2024: '',
}

type Reelecto = {
  data: Data[]
  pageSize?: number
}

export default function Reelecto({ data, pageSize = 5 }: Reelecto) {
  const { currentRegion } = useRouting()
  const [currentPage, setCurrentPage] = useState(0)

  const totalPages = Math.ceil(data.length / pageSize)
  const paginatedData = data.slice(currentPage * pageSize, (currentPage + 1) * pageSize)

  useEffect(() => {
    setCurrentPage(0)
  }, [currentRegion])

  return (
    <PaginatedCard
      title="Reelectos"
      statusLabel="PRELIMINAR"
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      totalPages={totalPages}
    >
      <GridTable>
        <GridHeaderCell className="text-left">COMUNA</GridHeaderCell>
        <GridHeaderCell />
        <GridHeaderCell className="items-center">
          2024
          <GridHeaderText>OBLIGATORIO</GridHeaderText>
        </GridHeaderCell>
        <GridHeaderCell className="items-center">
          2021
          <GridHeaderText>VOLUNTARIO</GridHeaderText>
        </GridHeaderCell>
        {paginatedData.slice(0, pageSize).map((item, index) => (
          <React.Fragment key={index}>
            <GridCell className="flex flex-col items-start">
              <div className="font-bold">{item.comuna}</div>
              <div className="font-medium">{item.pacto}</div>
            </GridCell>
            <GridCell className="flex items-center">
              <NameCandidateBadge electo={item} />
            </GridCell>
            <PercentageGridCell className="mgw:text-[2em] text-[1.7em] font-semibold">
              {item.porcentaje_2024}
            </PercentageGridCell>
            <PercentageGridCell className="mgw:text-[1.5em] text-[1.2em] font-medium">
              {item.porcentaje_2021}
            </PercentageGridCell>
          </React.Fragment>
        ))}
        {currentPage === 0 &&
          Array.from({ length: pageSize - data.length }).map((_, index) => (
            <React.Fragment key={index}>
              <GridCell className="flex flex-col items-start justify-center">
                <Skeleton className="w-[70%] h-5" />
                <Skeleton className="w-[75%] h-5 mt-3" />
              </GridCell>
              <GridCell className="flex items-center">
                <NameCandidateBadge electo={fakeItem} isBlank />
              </GridCell>
              <PercentageGridCell className="mgw:text-[2em] text-[1.7em] font-semibold">
                <Skeleton className="w-[70%] h-8" />
              </PercentageGridCell>
              <PercentageGridCell className="mgw:text-[1.5em] text-[1.2em] font-medium">
                <Skeleton className="w-[70%] h-6" />
              </PercentageGridCell>
            </React.Fragment>
          ))}
      </GridTable>
    </PaginatedCard>
  )
}

const GridTable = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr 0.6fr 0.6fr;
  grid-auto-rows: min-content;
`

const GridHeaderCell = styled.div.attrs({
  className: 'mgw:text-[1.5em] text-[1.25em]',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;

  font-family: 'JetBrains Mono';
  font-weight: 600;
  line-height: 1em;

  color: ${colors.blackDch};
  border-bottom: 2px solid ${colors.gray3Dch};

  padding: 10px 10px 10px;
  &:nth-of-type(4n + 1) {
    padding-left: 40px;
  }
  &:nth-of-type(4n) {
    background-color: ${colors.gray1Dch};
  }
`

const GridHeaderText = styled.div.attrs({
  className: 'mgw:text-[0.8em] text-[0.8em]',
})`
  font-weight: 500;
`

const GridCell = styled.div.attrs({
  className: 'mgw:text-[1.5em] text-[1.25em] mgw:py-[15px] py-[12px]',
})`
  color: ${colors.blackDch};
  border-bottom: 2px solid ${colors.gray3Dch};

  padding-left: 5px;
  padding-right: 5px;
  &:nth-of-type(4n + 1) {
    padding-left: 40px;
  }
  &:nth-of-type(4n) {
    background-color: ${colors.gray1Dch};
  }
`

const PercentageGridCell = styled(GridCell)`
  display: flex;
  justify-content: center;
  align-items: center;
`
