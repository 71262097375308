import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { Button } from '../ui/button'
import { cn } from '@/lib/utils'
import PbyUnholsterGray from '@/assets/pbyUnholsterGray.svg'
import { electionParams, Territorios } from '@/constants'
import useRouting from '@/stores/useRouting'
import { regiones } from '@/stores/useTerritorios'
import { ElectionType } from '@/types/Elections'
import { Link, matchPath, NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { DATOSCOMUNALESTEMAS } from '@/constants'
import ReactGA from 'react-ga4'

ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID)

type AccordionOption = {
  label: string
  value: string
  toNavigate?: string
  selected?: boolean
  onClick?: () => void
  territorio?: Territorios | 'region'
  hideIn?: ElectionType[]
  displayLabel?: string
  gaName?: string
}

export type AccordionMenuButton = {
  label: string
  tema: string
  options: AccordionOption[]
  expand?: boolean
  gaName?: string
}

const DatosComunalesOptions: AccordionMenuButton[] = Object.entries(DATOSCOMUNALESTEMAS).map(
  ([temaKey, tema]) => ({
    label: tema.name,
    tema: temaKey,
    gaName: tema.gaName,
    options: Object.entries(tema.subtemas).map(([key, subtema]) => ({
      label: subtema.name,
      value: key,
      toNavigate: `/datos-comunales/${temaKey}/${key}`,
      gaName: subtema.gaName,
    })),
  })
)

const RESULTS_OPTIONS: AccordionMenuButton[] = [
  {
    label: 'RESUMEN',
    tema: 'resumen',
    gaName: 'live2024_eleccion_resumen_dropdown',
    options: [
      {
        label: 'Electos por Pacto',
        value: 'pactos',
        toNavigate: '/:eleccion',
        displayLabel: 'Electos por Pactos',
        gaName: 'live2024_eleccion_resumen_electosporpacto',
      },
      {
        label: 'Comparación',
        value: 'comparacion',
        toNavigate: '/:eleccion/comparacion',
        displayLabel: 'Comparación 2021 y 2024',
        gaName: 'live2024_eleccion_resumen_comparacion',
      },
      {
        label: 'Ganadores',
        value: 'ganadores',
        toNavigate: '/:eleccion/ganadores',
        displayLabel: 'Ganadores',
        gaName: 'live2024_eleccion_resumen_ganadores',
        hideIn: [ElectionType.CONCEJALES, ElectionType.ALCALDES, ElectionType.CORE],
      },
      {
        label: 'Mayorías',
        value: 'mayoria',
        toNavigate: '/:eleccion/ranking/top',
        displayLabel: 'Mayorías Nacionales',
        gaName: 'live2024_eleccion_resumen_mayorias',
      },
      {
        label: 'Minorías',
        value: 'minoría',
        toNavigate: '/:eleccion/ranking/bottom',
        displayLabel: 'Minorías Nacionales',
        gaName: 'live2024_eleccion_resumen_minorias',
      },
    ],
  },
  {
    label: 'TERRITORIO',
    tema: 'territorio',
    gaName: 'live2024_eleccion_territorio_dropdown',
    options: [
      {
        label: 'Pactos por Región',
        value: 'pactoPorRegion',
        toNavigate: '/:eleccion/territorio/:territorio/pactos',
        displayLabel: 'Pactos por Región',
        gaName: 'live2024_eleccion_territorio_pactosregion',
        hideIn: [ElectionType.GORE, ElectionType.CORE],
      },
      {
        label: 'Pactos por Provincia',
        value: 'pactoPorRegion',
        toNavigate: '/:eleccion/territorio/:territorio/pactos',
        gaName: 'live2024_eleccion_territorio_provincia',
        hideIn: [ElectionType.GORE, ElectionType.CONCEJALES, ElectionType.ALCALDES],
      },
      {
        label: 'Candidatos por Región',
        value: 'candidatosPorRegion',
        toNavigate: '/:eleccion/territorio/:territorio',
        gaName: 'live2024_eleccion_territorio_candidatosregion',
        hideIn: [ElectionType.GORE, ElectionType.CONCEJALES, ElectionType.CORE],
      },
      {
        label: 'Electos por Provincia',
        value: 'candidatosPorRegion',
        toNavigate: '/:eleccion/territorio/:territorio',
        gaName: 'live2024_eleccion_territorio_candidatosprovincia',
        hideIn: [ElectionType.GORE, ElectionType.CONCEJALES, ElectionType.ALCALDES],
      },
      {
        label: 'Electos por comuna',
        value: 'electosPorComuna',
        toNavigate: '/:eleccion/territorio/:territorio/:comuna',
        gaName: 'live2024_eleccion_territorio_electoscomuna',
        hideIn: [ElectionType.CORE, ElectionType.GORE, ElectionType.CORE],
        displayLabel: 'Electos Comuna',
      },
      {
        label: 'Reelectos',
        value: 'reelectos',
        toNavigate: '/:eleccion/territorio/:territorio/reelectos',
        gaName: 'live2024_eleccion_territorio_reelectos',
        hideIn: [ElectionType.CONCEJALES, ElectionType.GORE, ElectionType.CORE],
      },
      {
        label: 'Cambio Pacto',
        value: 'cambio-pacto',
        toNavigate: '/:eleccion/territorio/:territorio/cambio-pacto',
        territorio: 'region',
        gaName: 'live2024_eleccion_territorio_cambiapacto',
        hideIn: [ElectionType.CONCEJALES, ElectionType.CORE, ElectionType.GORE],
      },
      {
        label: 'Mantiene Pacto',
        value: 'mantiene-pacto',
        toNavigate: '/:eleccion/territorio/:territorio/mantiene-pacto',
        gaName: 'live2024_eleccion_territorio_mantienepacto',
        territorio: 'region',
        hideIn: [ElectionType.CONCEJALES, ElectionType.CORE, ElectionType.GORE],
      },
      // Gore
      {
        label: 'Electos por Región',
        value: 'electos-gore',
        toNavigate: '/:eleccion/territorio/:territorio',
        hideIn: [ElectionType.CONCEJALES, ElectionType.CORE, ElectionType.ALCALDES],
        displayLabel: 'Electos por región',
        gaName: 'live2024_eleccion_territorio_electosregion',
      },
      {
        label: 'Votación por Comuna',
        value: 'electos-gore',
        toNavigate: '/:eleccion/territorio/:territorio/:comuna',
        hideIn: [ElectionType.CONCEJALES, ElectionType.ALCALDES, ElectionType.CORE],
        gaName: 'live2024_eleccion_territorio_votacioncomuna',
        displayLabel: 'Votación por comuna',
      },
      {
        label: 'Reelectos',
        value: 'reelectos-gore',
        toNavigate: '/:eleccion/reelectos',
        territorio: 'region',
        hideIn: [ElectionType.CONCEJALES, ElectionType.CORE, ElectionType.ALCALDES],
        gaName: 'live2024_eleccion_territorio_reelectos',
      },
      {
        label: 'Cambio Pacto',
        value: 'cambio-pacto-gore',
        toNavigate: '/:eleccion/cambio-pacto',
        territorio: 'region',
        hideIn: [ElectionType.CONCEJALES, ElectionType.CORE, ElectionType.ALCALDES],
        gaName: 'live2024_eleccion_territorio_cambiopacto',
      },
      {
        label: 'Mantiene Pacto',
        value: 'mantiene-pacto-gore',
        toNavigate: '/:eleccion/mantiene-pacto',
        territorio: 'region',
        hideIn: [ElectionType.CONCEJALES, ElectionType.CORE, ElectionType.ALCALDES],
        gaName: 'live2024_eleccion_territorio_mantienepacto',
      },
    ],
  },
]

function adaptOptions({ path }: { path: string }) {
  const {
    currentRegion,
    currentElection,
    currentComuna,
    currentCircunscripcion,
    currentTema,
    currentSubtema,
  } = useRouting.getState()
  const options = RESULTS_OPTIONS
  if (currentElection)
    return options.map(({ label, options, expand, gaName }) => ({
      label,
      expand,
      gaName: gaName?.replace('eleccion', currentElection),
      options: options
        .filter(({ hideIn }) => {
          return !hideIn?.includes(currentElection)
        })
        .map(({ label, toNavigate, displayLabel, gaName: gaNameSubOption }) => {
          const comunaRegion = regiones.find(
            ({ codigo }) => codigo === currentRegion
          )?.comunaDefecto
          const adaptedToNavigate = toNavigate
            ?.replace(
              ':territorio',
              String(
                currentRegion ||
                  currentCircunscripcion ||
                  electionParams[currentElection].defaultTerritorio
              )
            )
            .replace(
              ':comuna',
              String(currentComuna || comunaRegion || electionParams[currentElection].defaultComuna)
            )
            .replace(':eleccion', currentElection)
          return {
            label,
            gaName: gaNameSubOption?.replace('eleccion', currentElection) || '',
            toNavigate: adaptedToNavigate,
            selected: adaptedToNavigate ? !!matchPath(adaptedToNavigate, decodeURI(path)) : false,
            displayLabel: displayLabel,
          }
        }),
    })) as AccordionMenuButton[]
  if (!currentElection && currentTema) {
    return DatosComunalesOptions.map(({ label, options, tema, gaName }) => ({
      label,
      tema,
      gaName,
      options: options.map(({ label, toNavigate, value, gaName }) => ({
        label,
        toNavigate: `${toNavigate}/${currentComuna}`,
        selected: currentSubtema === value,
        value,
        gaName,
      })),
    })) as AccordionMenuButton[]
  }
  return []
}

export function MobileNavigation() {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { currentElection, currentComuna } = useRouting.getState()

  const finalOptions = adaptOptions({
    path: pathname,
  })

  const selectedButton = finalOptions.find(({ options }) =>
    options.some(({ selected }) => selected)
  )

  if (!selectedButton) return

  const selectedSubOption = selectedButton?.options.find(({ selected }) => selected)

  return (
    <div className="w-full xl:hidden mt-4 h-auto overflow-visible mb-2 z-10 ">
      {!currentElection && (
        <div className="flex w-full gap-2 justify-around flex-wrap">
          {finalOptions.map(({ label, options, tema }) => (
            <div
              key={label}
              className={cn(
                'border-r w-20 flex justify-center items-center text-[12px] text-[#a8a8a8] rounded-sm border cursor-pointer select-none py-2',
                {
                  'font-bold bg-[#f0f3ff] text-[#2150eb] border-[#0631ba]':
                    selectedButton.label === label,
                }
              )}
              onClick={() =>
                navigate(`/datos-comunales/${tema}/${options[0].value}/${currentComuna}`)
              }
            >
              {label}
            </div>
          ))}
        </div>
      )}
      {currentElection && (
        <>
          <div className="flex w-full rounded-sm gap-2 justify-center h-[30px]">
            <div
              className={cn(
                'border-r w-20 flex justify-center items-center text-[12px] text-[#a8a8a8] rounded-sm border cursor-pointer select-none',
                {
                  'font-bold bg-[#f0f3ff] text-[#2150eb] border-[#0631ba]':
                    selectedButton.label === 'RESUMEN',
                }
              )}
              onClick={() => navigate(`/${currentElection}`)}
            >
              Resumen
            </div>
            <div
              className={cn(
                'border-r w-20 flex justify-center items-center text-[12px] text-[#a8a8a8] rounded-sm border cursor-pointer select-none',
                {
                  'font-bold bg-[#f0f3ff] text-[#2150eb] border-[#0631ba]':
                    selectedButton.label === 'TERRITORIO',
                }
              )}
              onClick={() => navigate(`/${currentElection}/territorio`)}
            >
              Territorio
            </div>
          </div>
          <Select
            value={selectedSubOption?.label}
            onValueChange={(value) => {
              const option = selectedButton.options.find(({ label }) => label === value)
              if (option?.toNavigate) {
                navigate(option.toNavigate)
              }
            }}
          >
            <SelectTrigger className="w-[210px] mt-4 text-[14px] z-20">
              <SelectValue placeholder="Sección" />
            </SelectTrigger>
            <SelectContent
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              {selectedButton.options.map(({ label, toNavigate, hideIn, displayLabel }) => {
                const isHidden = currentElection && hideIn?.includes(currentElection)
                if (isHidden) return null
                return (
                  <SelectItem key={label} value={label} className="text-[12px]">
                    {displayLabel || label}
                  </SelectItem>
                )
              })}
            </SelectContent>
          </Select>
        </>
      )}
    </div>
  )
}

export default function Sidebar() {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { currentElection } = useRouting.getState()

  const finalOptions = adaptOptions({
    path: pathname,
  })

  const selectedButton = finalOptions.find(({ options }) =>
    options.some(({ selected }) => selected)
  )

  if (!selectedButton) return

  return (
    <div className="w-[165px] hidden xl:flex flex-col justify-between">
      <div className="w-full h-[70%] border-l-[0.6px] border-[#e0e0e0]">
        <Accordion
          type="single"
          collapsible
          className="w-full"
          defaultValue={selectedButton?.label}
        >
          {finalOptions.map((section, i) => (
            <AccordionItem value={section.label} key={`section ${i}`} className="border-none">
              <AccordionTrigger
                className={cn('px-4 py-2 text-[14px] font-semibold text-wrap', {
                  'font-medium text-[#a8a8a8]': section.label !== selectedButton.label,
                  'text-[#3C3C3C]': section.label === selectedButton.label,
                })}
                onClick={() => {
                  if (section.gaName) ReactGA.event(section.gaName)
                }}
              >
                {section.label}
              </AccordionTrigger>
              <AccordionContent className="pb-1">
                <Options
                  options={section.options}
                  currentElection={currentElection}
                  navigate={navigate}
                />
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
      <Link
        to={'https://www.unholster.com/'}
        onClick={() => ReactGA.event('live2024_unholster_redireccion')}
        target="_blank"
        rel="noopener noreferrer"
      >
        <PbyUnholsterGray />
      </Link>
    </div>
  )
}

function Options({
  options,
  currentElection,
  navigate,
}: {
  options: AccordionOption[]
  currentElection?: ElectionType
  navigate: NavigateFunction
}) {
  return options.map(({ label, selected, toNavigate, hideIn, gaName }) => {
    const isHidden = currentElection && hideIn?.includes(currentElection)
    if (isHidden) return null
    return (
      <Button
        variant="ghost"
        className={cn(
          'w-full justify-start px-4 py-2 text-left text-[13px] font-light relative rounded-none text-wrap',
          selected ? 'text-blue-600 font-medium' : 'text-gray-600'
        )}
        onClick={() => {
          if (gaName) ReactGA.event(gaName)
          if (toNavigate) navigate(toNavigate)
        }}
        key={label}
      >
        <div
          className={cn(
            'absolute left-0 w-1 h-full bg-transparent',
            selected ? 'bg-blue-600' : 'bg-transparent'
          )}
        />
        {label}
      </Button>
    )
  })
}
