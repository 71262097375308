import * as React from 'react'
import * as ProgressPrimitive from '@radix-ui/react-progress'

import { cn } from '@/lib/utils'
import { useEffect, useRef } from 'react'

type ProgressProps = React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
  indicatorColor?: string
  indicatorClassName?: string
  showInnerIndicator?: boolean
  showIndicator?: boolean
  indicatorValue?: string | number
  isPecentageIndicator?: boolean
  outerContent?: React.ReactNode
}
const Progress = React.forwardRef<React.ElementRef<typeof ProgressPrimitive.Root>, ProgressProps>(
  (
    {
      className,
      value,
      indicatorColor,
      indicatorClassName,
      showInnerIndicator,
      showIndicator,
      indicatorValue,
      isPecentageIndicator,
      outerContent,
      ...props
    },
    ref
  ) => {
    return (
      <ProgressPrimitive.Root
        ref={ref}
        className={cn('relative h-2 w-full overflow-hidden', className)}
        {...props}
      >
        <ProgressPrimitive.Indicator
          className={cn(
            'h-full w-full flex-1 transition-all rounded-tr-sm rounded-br-sm relative',
            indicatorClassName
          )}
          style={{
            transform: `translateX(-${100 - (value || 0)}%)`,
            backgroundColor: indicatorColor,
          }}
        >
          {showInnerIndicator && (
            <div
              className={cn(
                'absolute top-1/2 right-2 transform text-[10px] xl:text-[13px] -translate-y-1/2',
                'transition-all'
              )}
              style={{
                color: '#fafafa',
              }}
            >
              {indicatorValue}
            </div>
          )}
          {showIndicator && (
            <div
              className={cn('absolute top-0 right-[-18px] transform text-[9px]', 'transition-all', {
                'translate-x-3': isPecentageIndicator,
              })}
              style={{
                color: indicatorColor,
              }}
            >
              {indicatorValue}
            </div>
          )}
          {outerContent && (
            <div
              className={cn(
                'absolute top-0 right-[-89%] bigPhone:right-[-75%] sm:right-[-65%] smd:right-[-50%] md:right-[-38%] transform text-[9px] transition-all'
              )}
            >
              {outerContent}
            </div>
          )}
        </ProgressPrimitive.Indicator>
      </ProgressPrimitive.Root>
    )
  }
)
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
