import HorizontalMap from '@/components/HorizontalMap'
import MPactos, { type PactoResult } from '@/components/MagicWall/Pactos'
import { colors, PACTOS } from '@/constants'
import { regiones } from '@/stores/useTerritorios'
import styled from 'styled-components'
import useElectionData from '@/stores/useElectionData'
import { useMemo } from 'react'
import { ElectionType } from '@/types/Elections'
import { getResultsByPacto } from '@/lib/dataProcess'
import { buildPactosViewData } from '@/lib/buildDataViews'

export default function Pactos() {
  const {
    files: { gore },
  } = useElectionData()

  const data: PactoResult[] = useMemo(() => {
    const resultByPactos = getResultsByPacto(gore || [])
    return buildPactosViewData(resultByPactos, ElectionType.GORE, true)
  }, [gore])

  // Set color of each region but GS
  const regionesData = useMemo(() => {
    const orderedRegiones = regiones
      // Remove GS
      .filter((region) => region.codigo !== 17)
      // Sort by order of appearance in the map
      .sort((a, b) => {
        if (a.orden && b.orden) {
          return a.orden - b.orden
        }
        return 0
      })

    return orderedRegiones.map((region) => {
      // Check if region has an electo
      const electosRegion = (gore || []).filter(
        (electo) => Number(electo.cod_region) === Number(region.codigo) && electo.electo
      )

      const selectedColor =
        electosRegion.length === 1
          ? // If there is only one electo, get the color of the pacto
            PACTOS[ElectionType.GORE][electosRegion[0].cod_pacto].color
          : // if no data, use gray, else empty string for 2 vuelta
            ''
      return {
        name: region.acronimo || region.nombre,
        selectedColor,
        code: region.codigo,
      }
    })
  }, [gore])

  return (
    <MPactos
      className="mgw:py-14 mgw:px-6 py-12 px-5"
      election="Gobernadores"
      data={data}
      verticalSeparatorBefore="Otros"
      numberOfPartidosbyColumn={7}
      minWidthPactos="100px"
      minWidthPartidos="100px"
    >
      <div className="mgw:px-[80px] px-[50px]">
        <HorizontalMap
          selectionProperty="cod_comuna"
          position="center"
          regionResults={regionesData}
        />
        <div className="flex w-full h-full justify-center gap-2">
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="10.5"
              cy="10.5"
              r="9.18979"
              fill="#FAFAFA"
              stroke="#4F4F4F"
              stroke-width="2.62042"
            />
          </svg>
          <Text>2DA VUELTA</Text>
        </div>
      </div>
    </MPactos>
  )
}

const Text = styled.div`
  font-size: 18px;
  color: ${colors.blackDch};
  font-weight: 500;
`
