import Ranking from '@/components/MagicWall/Ranking'
import { PACTOS } from '@/constants/pactos'
import { jointCandidatosColor } from '@/lib/dataProcess'
import useElectionData from '@/stores/useElectionData'
import { ElectionType } from '@/types/Elections'
import { useMemo } from 'react'

export default function CJRanking() {
  const {
    files: { concejales },
  } = useElectionData()

  const electos = useMemo(() => {
    return concejales ? concejales.filter((c) => c.electo) : []
  }, [concejales])

  const processedData = useMemo(() => {
    if (electos.length === 0) {
      return []
    }
    const uniqueComunas = new Set()
    const oneElectoforComuna = electos
      .sort((a, b) => b.votos - a.votos)
      .filter((item) => {
        if (uniqueComunas.has(item.cod_comuna)) return false
        uniqueComunas.add(item.cod_comuna)
        return true
      })

    return jointCandidatosColor(oneElectoforComuna, ElectionType.CONCEJALES).map((electo) => ({
      ...electo,
      partido: electo.cod_partido,
      pacto: PACTOS[ElectionType.CONCEJALES][electo.cod_pacto]?.shortName || electo.cod_pacto,
      imagen: electo.imagen || '/userProfile.png',
      color_texto: PACTOS[ElectionType.CONCEJALES][electo.cod_pacto]?.darkColor || '',
    }))
  }, [electos])

  return <Ranking data={processedData} />
}
