import useRouting from '@/stores/useRouting'
import { useNavigate } from 'react-router-dom'

import CambioPacto from '@/components/Web/CambioPacto'
import useTerritorios, { regiones } from '@/stores/useTerritorios'
import RegionSelector from '@/components/RegionSelector'
import { ElectionType } from '@/types/Elections'
import { buildCambioMantienePactoAlcalde } from '@/lib/buildDataViews'
import useElectionData from '@/stores/useElectionData'
import { useMemo } from 'react'

export default function ACambioPacto() {
  const {
    files: { alcaldes },
    comparador: { alcaldes: alcaldes2021 },
  } = useElectionData()

  const { setComunaColor } = useTerritorios()
  const { currentRegion } = useRouting()
  const data = useMemo(() => {
    const region = regiones.find((r) => r.codigo === currentRegion)
    if (!alcaldes || !alcaldes2021 || !region) {
      return []
    }
    const { data, comunasColor } = buildCambioMantienePactoAlcalde(
      region,
      alcaldes,
      alcaldes2021,
      'cambia',
      17
    )

    setComunaColor(comunasColor)
    return data.sort((a, b) => a.comuna.localeCompare(b.comuna))
  }, [alcaldes, alcaldes2021, currentRegion, setComunaColor])

  const navigate = useNavigate()

  const handleRegion = (region: string) => {
    navigate(`/${ElectionType.ALCALDES}/territorio/${region}/cambio-pacto`)
  }

  return (
    <CambioPacto
      selection={
        <RegionSelector
          className="w-[50%] hidden xl:flex"
          onClick={handleRegion}
          value={String(currentRegion)}
        />
      }
      data={data}
    />
  )
}
