import styled from 'styled-components'
import { numberToPercent, voteToMillionString } from '@/helpers/formatters'
import { colors } from '@/constants'
import { cn } from '@/lib/utils'
import { Progress } from '../ui/progress'
import { Data as RankingData } from './Ranking'
import { FullCandidateBadge } from './CandidateBadge'

type IPercentageBar = {
  className?: string
  bordered?: boolean
  percentage: number
  innerContent?: React.ReactNode
  outerContent?: React.ReactNode
  color: string
  maxPercentage?: number
  default_?: React.ReactNode
  maxHeight?: number
  minHeight?: number
}

type ISupPercentageLabel = {
  percentage: number
  fixed?: number
  includeSign?: boolean
  hideTrailingZero?: boolean
  especialSupForBar?: boolean
  isWeb?: boolean
  size?: string
}

export function DefaultBar({
  className,
  bordered = false,
}: {
  className?: string
  bordered?: boolean
}) {
  return (
    <>
      <ContentLabel className={className} color={colors.gray3Dch}>
        n/a
      </ContentLabel>
      <Bar $color={colors.gray2Dch} $percentage={1} $bordered={bordered} />
    </>
  )
}

export function SupPercentageLabel({
  percentage,
  fixed = 1,
  includeSign = false,
  hideTrailingZero = false,
  especialSupForBar = false, // For comparison bars
  size = 'normal',
  isWeb = false,
}: ISupPercentageLabel) {
  return (
    <div className="flex items-center justify-center">
      <div
        className={cn('flex justify-end items-center', {
          relative: especialSupForBar,
        })}
      >
        {(includeSign && percentage > 0 ? '+' : '') +
          numberToPercent(percentage, fixed, false, hideTrailingZero)}
        <sup
          className={cn('text-[0.6em]', {
            'absolute top-2 right-[-6px]': especialSupForBar === true,
            'absolute top-1 right-[-10px]': especialSupForBar === true && size === 'big',
            'absolute top-3  right-[-9.5px] bigPhone:top-4 bigPhone:right-[-12px]':
              especialSupForBar === true && isWeb, // web versus
            'mt-1': isWeb && !especialSupForBar,
          })}
        >
          %
        </sup>
      </div>
    </div>
  )
}

export default function ContentPercentageBar({
  className,
  percentage,
  color,
  innerContent,
  outerContent,
  default_,
  maxPercentage = 100,
  maxHeight = 100,
  minHeight = 0,
  bordered = false,
}: IPercentageBar) {
  const topPercentage = minHeight + (percentage / maxPercentage) * maxHeight
  return (
    <BarContainer className={className}>
      {default_ && percentage === 0 ? (
        default_
      ) : (
        <>
          <OuterContentContainer percentage={topPercentage}>{outerContent}</OuterContentContainer>
          <Bar $percentage={topPercentage} $color={color} $bordered={bordered}>
            {innerContent}
          </Bar>
        </>
      )}
    </BarContainer>
  )
}

export function MobileRankingBar({
  data,
  isChecked,
  maxPorcentage,
  maxVotos,
}: {
  data: RankingData
  isChecked: boolean
  maxVotos: number
  maxPorcentage: number
}) {
  const value = isChecked ? (data.porcentaje * 100) / maxPorcentage : (data.votos * 100) / maxVotos
  const valueReal = Math.max(25, value)
  return (
    <Progress
      value={valueReal}
      className="h-[38px]"
      indicatorColor={data.color}
      showInnerIndicator
      indicatorValue={
        isChecked ? numberToPercent(data.porcentaje, 1) : voteToMillionString(data.votos)
      }
      outerContent={<FullCandidateBadge electo={data} monoLabel={data.monoLabel} />}
      indicatorClassName="w-[55%] bigPhone:w-[60%]"
    />
  )
}

const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
`

const OuterContentContainer = styled.div<{ percentage: number }>`
  position: absolute;
  bottom: ${({ percentage }) => `${percentage}%`};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
`

const Bar = styled.div<{ $color: string; $percentage: number; $bordered?: boolean }>`
  width: 100%;
  background-color: ${({ $color }) => $color};
  height: ${({ $percentage }) => `${$percentage}%`};
  border-radius: ${({ $bordered }) => ($bordered ? '6px 6px 0 0' : '5px 5px 0 0')};
  transition: height 0.3s ease;
`

export const ContentLabel = styled.div<{ color?: string }>`
  text-align: center;
  color: ${({ color }) => color || colors.blackDch};
  width: 100%;
`

export const BetweenContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`
