import useRouting from '@/stores/useRouting'
import { useNavigate } from 'react-router-dom'

import Reelecto from '@/components/Web/Reelecto'
import useTerritorios from '@/stores/useTerritorios'

import RegionSelector from '@/components/RegionSelector'
import { buildReelectosAlcalde } from '@/lib/buildDataViews'
import useElectionData from '@/stores/useElectionData'
import { ElectionType } from '@/types/Elections'
import { useMemo } from 'react'

export default function AReelecto() {
  const {
    files: { alcaldes },
    comparador: { alcaldes: alcaldes2021 },
  } = useElectionData()

  const { setComunaColor } = useTerritorios()
  const navigate = useNavigate()
  const { currentRegion } = useRouting()

  const data = useMemo(() => {
    if (!alcaldes || !alcaldes2021 || !currentRegion) {
      return []
    }
    const { data, comunasColor } = buildReelectosAlcalde(currentRegion, alcaldes, alcaldes2021)

    setComunaColor(comunasColor)
    return data.sort((a, b) => a.comuna.localeCompare(b.comuna))
  }, [alcaldes, alcaldes2021, currentRegion, setComunaColor])

  const handleRegion = (region: string) => {
    navigate(`/${ElectionType.ALCALDES}/territorio/${region}/reelectos`)
  }
  return (
    <Reelecto
      selection={
        <RegionSelector
          className="w-[50%] hidden xl:flex"
          onClick={handleRegion}
          value={String(currentRegion)}
        />
      }
      data={data}
    />
  )
}
