import { colors } from '@/constants'
import * as d3 from 'd3'
import { Selected } from './ComunasChileMap'
import { getBoundingBox } from './getBoundingBox'
import { ChileFeature } from './types'

export type ZoomSelectionProps = {
  mapSvg?: d3.Selection<SVGSVGElement, undefined, null, undefined>
  selected: Selected[]
  dimensions: { width: number; height: number }
  transitionDuration?: number
  onClick?: (feature?: ChileFeature) => void
  position?: 'center' | 'right-center'
  scale?: [number, number]
  translate?: [number, number]
  selectionColor?: string
  continentColor?: string
}

export default function zoomSelection({
  mapSvg,
  selected,
  dimensions,
  transitionDuration = 750,
  onClick,
  position = 'right-center',
  scale: userScale = [1, 1],
  translate: userTranslate = [0, 0],
  selectionColor,
  continentColor,
}: ZoomSelectionProps) {
  if (!(mapSvg && selected.length)) return
  const someFocused = selected.some(({ focused }) => focused)
  const selectedPaths = mapSvg.selectAll<SVGPathElement, ChileFeature>('path').filter((d) => {
    const { cod_comuna } = d.properties
    return selected.some(({ cut }) => cut === cod_comuna)
  })

  const focused = mapSvg.selectAll<SVGPathElement, ChileFeature>('path').filter((d) => {
    const { cod_comuna } = d.properties
    if (someFocused) return selected.some(({ cut, focused }) => cut === cod_comuna && focused)
    return selected.some(({ cut }) => cut === cod_comuna)
  })
  const boundingBox = getBoundingBox({ nodes: focused })
  const { x0 = 0, y0 = 0, x1 = 0, y1 = 0 } = boundingBox
  const mapBaseDimensions = [x1 - x0, y1 - y0]
  const mapBaseCenter = [(x0 + x1) * 0.5, (y0 + y1) * 0.5]
  const parentCenter = [dimensions.width * 0.5, dimensions.height * 0.5]

  // Get scale
  const maxMapDimensions = (() => {
    if (position === 'center') return [dimensions.width, dimensions.height]
    if (position === 'right-center') return [dimensions.width * 0.5, dimensions.height]
    return [0, 0]
  })() as [number, number]
  const scale = Math.min(
    (maxMapDimensions[0] / mapBaseDimensions[0]) * userScale[0],
    (maxMapDimensions[1] / mapBaseDimensions[1]) * userScale[1]
  )

  // Get translate
  const mapScaledDimensions = [mapBaseDimensions[0] * scale, mapBaseDimensions[1] * scale]
  const mapScaledCenter = [
    mapBaseCenter[0] * scale + userTranslate[0],
    mapBaseCenter[1] * scale + userTranslate[1],
  ]
  const translate = (() => {
    if (position === 'center')
      return [parentCenter[0] - mapScaledCenter[0], parentCenter[1] - mapScaledCenter[1]]
    if (position === 'right-center') {
      const newCenter = [dimensions.width - mapScaledDimensions[0] / 2, parentCenter[1]]
      return [newCenter[0] - mapScaledCenter[0], newCenter[1] - mapScaledCenter[1]]
    }
    return [0, 0]
  })()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function clicked(event: any, feature?: ChileFeature) {
    event.stopPropagation()
    if (onClick) onClick(feature)
  }

  const zoom = d3.zoom().on('zoom', (event) => {
    const { transform } = event
    const group = mapSvg.on('click', clicked).select('g')
    group.attr('transform', transform)
    const paths = group.selectAll('path')
    paths.attr('stroke', 'none')
    paths.attr('fill', continentColor || colors.map.continentFill)
    paths.attr('opacity', 1)

    selectedPaths.attr('stroke', colors.map.stroke)
    selectedPaths.attr('stroke-width', 2 / transform.k)
    selectedPaths.attr('fill', selectionColor || colors.map.selectedFill)
    focused.attr('fill', (d) => {
      const { cod_comuna } = d.properties
      const selectedProperty = selected.find(({ cut }) => cut === cod_comuna)
      return selectedProperty?.color || colors.map.selectedFill
    })
    focused.attr('opacity', (d) => {
      const { cod_comuna } = d.properties
      const selectedProperty = selected.find(({ cut }) => cut === cod_comuna)
      return selectedProperty?.opacity || 1
    })
  }).transform

  mapSvg
    .transition()
    .duration(transitionDuration)
    // @ts-expect-error: I don't know how to type zoom
    .call(zoom, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale))
}
