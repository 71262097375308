import React from 'react'
import { Progress } from '../ui/progress'
import type { Data } from './Comparacion'
import styled from 'styled-components'

export default function MobileComparacionBar({
  data,
  isChecked,
  maxValueElectos,
}: {
  data: Data
  isChecked: boolean
  maxValueElectos: number
}) {
  const value2021 = isChecked ? data.porcentaje_2021 : (data.electos_2021 / maxValueElectos) * 80
  const value2024 = isChecked ? data.porcentaje_2024 : (data.electos_2024 / maxValueElectos) * 80
  const indicatorValue2021 = isChecked
    ? `${data.porcentaje_2021.toFixed(1).replace('.', ',')}%`
    : data.electos_2021
  const indicatorValue2024 = isChecked
    ? `${data.porcentaje_2024.toFixed(1).replace('.', ',')}%`
    : data.electos_2024
  return (
    <>
      <div className="col-span-2 h-[32px] flex items-center justify-end text-end text-[13px] font-medium">
        {data.pacto}
      </div>
      <div className="col-span-2 h-[32px] flex flex-col justify-center gap-1">
        {value2021 === 0 ? (
          <Progress
            className="h-[15px] rounded-none rounded-tr-[1.5px] rounded-br-[1.5px]"
            indicatorColor={`#BDBDBD`}
            value={1}
            showIndicator
            isPecentageIndicator={false}
            indicatorValue={'n/a'}
          />
        ) : (
          <Progress
            className="h-[15px] rounded-none rounded-tr-[1.5px] rounded-br-[1.5px]"
            indicatorColor={`${data.color}90`}
            value={value2021}
            showIndicator
            isPecentageIndicator={isChecked}
            indicatorValue={indicatorValue2021}
          />
        )}

        <Progress
          className="h-[15px] rounded-none rounded-tr-[1.5px] rounded-br-[1.5px]"
          indicatorColor={data.color}
          value={value2024}
          showIndicator
          isPecentageIndicator={isChecked}
          indicatorValue={indicatorValue2024}
        />
      </div>
    </>
  )
}
