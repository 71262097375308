import PaginatedCard from '@/components/MagicWall/PaginatedCard'
import { useState } from 'react'
import { FullCandidateBadge } from './CandidateBadget'
import { Progress } from '../ui/progress'
import { useEffect } from 'react'
import useRouting from '@/stores/useRouting'
import styled from 'styled-components'

export type Candidato = {
  nombre: string
  partido: string
  imagen: string
  color: string
  porcentaje: number
  pacto: string
  cod_partido: string
}

interface ElectosTabProps {
  title: string
  data: Candidato[]
  pageSize: number
}

export default function ElectosTab({ data, pageSize, title }: ElectosTabProps) {
  const { currentComuna, currentRegion } = useRouting()
  const [currentPage, setCurrentPage] = useState(0)

  const totalPages = Math.min(Math.ceil(data.length / pageSize), 8)
  const paginatedData = data.slice(currentPage * pageSize, (currentPage + 1) * pageSize)

  useEffect(() => {
    setCurrentPage(0)
  }, [currentComuna, currentRegion])
  return (
    <PaginatedCard
      title={title}
      statusLabel="PRELIMINAR"
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      totalPages={totalPages}
    >
      <Grid rows={pageSize}>
        {paginatedData.map((candidato, index) => (
          <div
            className="w-full flex flex-grow py-4 border-y-[1px] border-gris3Dch items-center px-10 justify-between"
            key={index}
          >
            <FullCandidateBadge electo={candidato} size="big" />
            <div className="flex items-center">
              <div className="flex items-start">
                <p className="font-bold text-4xl mgw:text-big ">
                  {candidato.porcentaje.toString().replace('.', ',')}
                </p>
                <span className="text-2xl font-bold mt-0 mgw:mt-1">%</span>
              </div>
              <Progress
                value={Number(candidato.porcentaje.toString().replace(',', '.'))}
                className="w-20 h-9 ml-3 bg-[#BDBDBD] rounded-tr-[3px] rounded-br-[3px]"
                indicatorColor={candidato.color}
              />
            </div>
          </div>
        ))}
      </Grid>
    </PaginatedCard>
  )
}

const Grid = styled.div<{ rows: number }>`
  display: grid;
  grid-template-rows: repeat(${({ rows }) => rows}, 1fr);
`
