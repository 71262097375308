import styled from 'styled-components'
import React from 'react'
import { CandidateBigBadge, FullCandidateBadge } from '@/components/Web/CandidateBadge'
import Card from '@/components/Web/Card'
import ContentPercentageBar, {
  ContentLabel,
  SupPercentageLabel,
} from '@/components/Web/PercentageBar'
import { numberToPercent, numberWithDots, voteToMillionString } from '@/helpers/formatters'
import { Progress } from '../ui/progress'
import { Skeleton } from '../ui/skeleton'

export type Data = {
  pacto: string
  cod_pacto: string
  color: string
  nombre: string
  electo: boolean
  partido: string
  cod_partido: string
  imagen: string
  votos: number
  porcentaje: number
  order: number
  comuna: string
  monoLabel?: string
  region?: string
}

type ElectosComuna = {
  data: Data[]
  title: string
  isSearching?: boolean
  cod_region?: number
  selection: React.ReactNode
}

function CustomPercentageBar({ item }: { item: Data }) {
  return (
    <ContentPercentageBar
      className="max-w-[85px]"
      outerContent={
        <CustomContentLabel color={item.color}>
          <SupPercentageLabel percentage={item.porcentaje} especialSupForBar isWeb />
        </CustomContentLabel>
      }
      innerContent={
        <>
          <div className="font-light text-[11px] w-full text-center absolute bottom-[-18px] text-gris3Dch hidden xl:block">
            {numberWithDots(item.votos)}
          </div>
          <div className="font-light text-[10px] w-full text-center absolute bottom-[-18px] text-gris3Dch block xl:hidden text-nowrap -translate-x-1">
            {voteToMillionString(item.votos)}
          </div>
        </>
      }
      maxHeight={80}
      percentage={item.porcentaje}
      color={item.color}
    />
  )
}

export default function ElectosVersus({
  data,
  title,
  selection,
  cod_region,
  isSearching,
}: ElectosComuna) {
  const sortData = [...data].sort((a, b) => a.order - b.order)
  const fakeItem: Data = {
    nombre: '',
    partido: '',
    imagen: 'userProfile.png',
    color: '#3c3c3c',
    porcentaje: 0,
    pacto: '',
    cod_partido: '',
    electo: false,
    comuna: 'Unknown',
    votos: 0,
    cod_pacto: '',
    order: 0,
  }
  if (sortData.length === 0 && !isSearching) {
    return (
      <Card title={title} selector={selection}>
        <div className="flex flex-col h-auto xl:max-h-[330px] xl:overflow-y-auto xl:pb-2 xl:px-1">
          {!isSearching && (
            <ComparativeCard className="flex-grow gap-[10px] text-[5px] h-[185px]">
              <CandidateBigBadge
                electo={fakeItem}
                monoLabel={fakeItem.partido}
                winner={fakeItem.electo}
                labeled
                className="w-[120px] xl:w-[140px]"
                isBlank
                showSkeleton
              />
              <div className="flex gap-5 border-b-2 border-[lightgray] w-full">
                <CustomPercentageBar item={fakeItem} />
                <CustomPercentageBar item={fakeItem} />
              </div>

              <CandidateBigBadge
                electo={fakeItem}
                monoLabel={fakeItem.partido}
                winner={fakeItem.electo}
                labeled
                className="w-[120px] xl:w-[140px]"
                isBlank
                showSkeleton
              />
            </ComparativeCard>
          )}
          <GridTable className="mt-auto gap-4">
            {Array.from({ length: 3 }).map((_, index) => (
              <React.Fragment key={index}>
                <GridCell>
                  <FullCandidateBadge
                    electo={fakeItem}
                    winner={fakeItem.electo}
                    showMonoLabel={false}
                    isColored={false}
                    isBlank
                  />
                </GridCell>
                <GridCell className="justify-end text-[12px] gap-2 py-1 items-center">
                  <Skeleton className="w-[20%] h-3" />
                  <Progress
                    value={0}
                    className="bg-[#eeeef0] w-[35px] h-3"
                    indicatorClassName="bg-[#4f4f4f] rounded-none"
                  />
                </GridCell>
              </React.Fragment>
            ))}
          </GridTable>
        </div>
      </Card>
    )
  }

  const listCandidates = isSearching ? sortData : sortData.slice(2, sortData.length)
  return (
    <Card title={title} selector={selection}>
      <div className="flex flex-col h-auto xl:max-h-[330px] xl:overflow-y-auto xl:pb-2 xl:px-1">
        {!isSearching && (
          <ComparativeCard className="flex-grow gap-[10px] text-[5px] h-[185px]">
            <CandidateBigBadge
              electo={sortData[0]}
              monoLabel={sortData[0]?.monoLabel || sortData[0]?.partido}
              winner={sortData[0].electo}
              labeled
              className="w-[120px] xl:w-[140px]"
            />
            <div className="flex gap-5 border-b-2 border-[lightgray] w-full">
              <CustomPercentageBar item={sortData[0]} />
              {sortData.length > 1 && <CustomPercentageBar item={sortData[1]} />}
            </div>

            {sortData.length > 1 && (
              <CandidateBigBadge
                electo={sortData[1]}
                monoLabel={sortData[1]?.monoLabel || sortData[1]?.partido}
                winner={sortData[1].electo}
                labeled
                className="w-[120px] xl:w-[140px]"
              />
            )}
          </ComparativeCard>
        )}
        <GridTable className="mt-auto">
          {listCandidates.map((item, index) => (
            <React.Fragment key={index}>
              <GridCell className="border-b-[#eeeef0] border-b">
                <FullCandidateBadge
                  electo={item}
                  winner={item.electo}
                  showMonoLabel={false}
                  isColored={false}
                />
              </GridCell>
              <GridCell className="justify-end text-[12px] gap-2 py-1 items-start border-b-[#eeeef0] border-b">
                <div className="text-end">
                  <p className="text-[12px]">{numberToPercent(item.porcentaje, 2, false)}%</p>
                  <p className="text-gris3Dch font-light text-nowrap text-[9px] xl:text-[12px]">
                    {item.votos.toLocaleString().replace(',', '.')} Votos
                  </p>
                </div>

                <Progress
                  value={Math.max(18, item.porcentaje)}
                  className="bg-[#eeeef0] w-[35px] h-3 mt-1"
                  indicatorClassName="bg-[#4f4f4f] rounded-none"
                />
              </GridCell>
            </React.Fragment>
          ))}
        </GridTable>
      </div>
    </Card>
  )
}

const ComparativeCard = styled.div`
  display: grid;
  padding: 25px 0;
  justify-items: center;
  align-content: center;
  grid-template-columns: 1fr 1.2fr 1fr;
`

const GridTable = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-radius: 0 0 8px 8px;
`

const GridCell = styled.div.attrs({})`
  display: flex;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
`

const CustomContentLabel = styled(ContentLabel).attrs({
  className: 'text-[20px] bigPhone:text-[25px] font-semibold',
})``
