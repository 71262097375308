import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { comunas } from '@/stores/useTerritorios'
import { type SelectProps } from '@radix-ui/react-select'
import { ChevronDown } from 'lucide-react'
import { GSComunas } from '@/stores/useTerritorios'

type ComunaSelectorProps = {
  onClick: (value: string) => void
  region?: number
  className?: string
  showIcon?: boolean
} & SelectProps
export default function ComunaSelector({
  onClick,
  region,
  value,
  className,
  showIcon = true,
}: ComunaSelectorProps) {
  const orderedComunas = comunas.sort((a, b) => {
    if (a.nombre > b.nombre) {
      return 1
    }
    if (a.nombre < b.nombre) {
      return -1
    }
    return 0
  })

  return (
    <Select onValueChange={onClick} value={value}>
      <SelectTrigger
        className={cn(`w-[180px] ${className}`, {
          'justify-start gap-1': !showIcon,
        })}
        showIcon={showIcon}
      >
        <SelectValue placeholder="Comunas" className="underline underline-offset-1" />
        {!showIcon && <ChevronDown className="h-4 w-4 opacity-50" />}
      </SelectTrigger>
      <SelectContent onClick={(e) => e.stopPropagation()}>
        <SelectGroup>
          <SelectLabel>Comunas</SelectLabel>
          {
            // Add Gran Santiago
            region === 17
              ? orderedComunas
                  .filter((c) => GSComunas.includes(c.codigo))
                  .map((option) => (
                    <SelectItem key={option.codigo} value={String(option.codigo)}>
                      {option.nombre}
                    </SelectItem>
                  ))
              : orderedComunas
                  .filter((option) => option.region === region)
                  .map((option) => (
                    <SelectItem key={option.codigo} value={String(option.codigo)}>
                      {option.nombre}
                    </SelectItem>
                  ))
          }
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
