import styled from 'styled-components'
import { useMemo, useState } from 'react'
import Card from '@/components/MagicWall/Card'
import ToggleSwitch from '@/components/MagicWall/ToggleSwitch'
import ContentPercentageBar, { SupPercentageLabel } from '@/components/PercentageBar'
import { CandidateBigBadge } from '@/components/MagicWall/CandidateBadget'
import { numberWithDots } from '@/helpers/formatters'
import { colors } from '@/constants'
import useRouting from '@/stores/useRouting'
import { ElectionType } from '@/types/Elections'

export type Data = {
  pacto: string
  color: string
  imagen: string
  nombre: string
  partido: string
  votos: number
  porcentaje: number
  cod_comuna: string | number
  comuna: string
  region: string
  reelecto?: boolean
  color_texto?: string
  segunda_vuelta?: boolean
  electo?: boolean
}

type Ranking = {
  data: Data[]
}

function RankingItem({
  item,
  isChecked,
  max,
  isBlank = false,
}: {
  item: Data
  isChecked: boolean
  max: number
  isBlank?: boolean
}) {
  const HeightBreakpoint = isChecked ? 20 : 28
  const { currentElection } = useRouting()

  const Element = ({ isChecked, inner = false }: { isChecked: boolean; inner?: boolean }) => {
    const color = inner ? colors.whiteDch : item.color
    return isChecked ? (
      <LabelTag className="mgw:text-[26px] text-[20px] py-[6px]" $color={color}>
        {numberWithDots(item.votos)}
      </LabelTag>
    ) : (
      <LabelTag className="mgw:text-[46px] text-[38px] py-[4px]" $color={color}>
        <SupPercentageLabel percentage={item.porcentaje || 0} size="big" />
      </LabelTag>
    )
  }

  const relativePercentage = !isChecked ? (item.porcentaje / max) * 100 : (item.votos / max) * 100

  const monoLabel = (currentElection: string | undefined, item: Data) => {
    switch (currentElection) {
      case ElectionType.GORE:
        return item.region
      default:
        return item.comuna
    }
  }

  return (
    <div className="flex flex-col h-full items-center w-[137px]">
      <ContentPercentageBar
        className="text-[6px]"
        maxHeight={50}
        outerContent={
          <>
            <CandidateBigBadge
              className="mb-1"
              electo={item}
              reelecto={item.reelecto}
              monoLabel={monoLabel(currentElection, item)}
              full
              isRanking
              isBlank={isBlank}
              winner={item.electo}
              segundaVuelta={item?.segunda_vuelta || false}
            />
            {/* Removed because we now need to show the number inside the bar, even if it is too small */}
            {/* Don't want to delete in case we need it again */}
            {/* {relativePercentage < HeightBreakpoint && <Element isChecked={isChecked} />} */}
          </>
        }
        // Removed because we now need to show the number inside the bar, even if it is too small
        // Don't want to delete in case we need it again
        // innerContent={
        //   relativePercentage >= HeightBreakpoint && <Element isChecked={isChecked} inner />
        // }
        innerContent={<Element isChecked={isChecked} inner />}
        percentage={relativePercentage < HeightBreakpoint ? HeightBreakpoint : relativePercentage}
        color={item.color}
      />
      <BottomTag $color={item.color} $letra={item.color_texto || item.color}>
        {item.pacto}
      </BottomTag>
    </div>
  )
}

export default function Ranking({ data }: Ranking) {
  const [isChecked, setIsChecked] = useState(false)
  const fakeItem: Data = {
    pacto: '',
    color: '#3c3c3c',
    imagen: 'userProfile.png',
    nombre: '',
    partido: '',
    votos: 0,
    porcentaje: 0,
    cod_comuna: '',
    comuna: '',
    region: '',
    reelecto: false,
  }

  const handleChange = () => {
    setIsChecked((prev) => !prev)
  }

  const sortedData = useMemo(() => {
    if (isChecked) return data.sort((a, b) => b.votos - a.votos).slice(0, 10)
    return data.sort((a, b) => b.porcentaje - a.porcentaje).slice(0, 10)
  }, [data, isChecked])

  const maxValue = isChecked ? sortedData[0]?.votos || 0 : sortedData[0]?.porcentaje || 0
  const title = isChecked
    ? '10 candidatos con mayor número de votos'
    : '10 candidatos con mayor porcentaje de votos'

  return (
    <Card
      title={title}
      separatorColor="#CCCCCC"
      statusLabel="PRELIMINAR"
      toggle={
        <ToggleSwitch
          checkedLabel="%"
          uncheckedLabel="N°"
          checked={isChecked}
          handleToggle={handleChange}
        />
      }
    >
      <div className="flex flex-col h-full p-[30px]">
        <RankingGrid number={10}>
          {sortedData.map((item, index) => (
            <RankingItem key={index} item={item} isChecked={isChecked} max={maxValue} />
          ))}

          {Array.from({ length: 10 - sortedData.length }).map((_, index) => (
            <RankingItem key={index} item={fakeItem} isChecked={isChecked} max={100} isBlank />
          ))}
        </RankingGrid>
      </div>
    </Card>
  )
}

const RankingGrid = styled.div<{ number: number }>`
  display: grid;
  height: 100%;
  min-height: 550px;
  gap: 12px;
  grid-template-columns: ${({ number }) => `repeat(${number}, 1fr)`};
`

const BottomTag = styled.div.attrs<{ $color: string; $letra: string }>({
  className: 'mgw:text-[18px] text-[16px] min-h-[50px] w-full p-2',
})`
  background-color: ${({ $color }) => `${$color}50`};
  color: ${({ $letra }) => $letra};
  text-transform: uppercase;
  text-align: center;
  line-height: 100%;
  font-weight: 700;
`

const LabelTag = styled.div<{ $color: string }>`
  color: ${({ $color }) => $color};
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  font-weight: 700;
`
