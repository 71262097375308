import useRouting from '@/stores/useRouting'
import { useNavigate } from 'react-router-dom'

import useTerritorios, { regiones } from '@/stores/useTerritorios'
import { getRegionResultsByPactoAlcalde, getTerritorioColorDictSimple } from '@/lib/dataProcess'
import { useEffect, useMemo } from 'react'
import useElectionData from '@/stores/useElectionData'
import PactoElectos from '@/components/Web/PactoElectos'
import RegionSelector from '@/components/RegionSelector'

import { ElectionType } from '@/types/Elections'
import { buildRegionResultsByPacto } from '@/lib/buildDataViews'

export default function APactoElectos() {
  const { currentRegion } = useRouting()
  const {
    files: { alcaldes },
    comparador: { alcaldes: alcaldes2021 },
  } = useElectionData()

  const navigate = useNavigate()

  const data = useMemo(() => {
    if (!alcaldes2021 || !alcaldes || !currentRegion) return []
    const region = regiones.find((r) => r.codigo === Number(currentRegion))
    const { countElectosByPacto, countElectosByPacto2021 } = getRegionResultsByPactoAlcalde(
      alcaldes,
      alcaldes2021,
      region?.comunas || []
    )
    const data = buildRegionResultsByPacto(
      countElectosByPacto,
      countElectosByPacto2021,
      region?.comunas.length || 0,
      ElectionType.ALCALDES
    )
    return data.sort((a, b) => b.electos_2024 - a.electos_2024)
  }, [alcaldes, alcaldes2021, currentRegion])

  const { setComunaColor } = useTerritorios()
  const colorDict = useMemo(
    () => getTerritorioColorDictSimple(alcaldes ?? [], ElectionType.ALCALDES),
    [alcaldes]
  )

  useEffect(() => {
    setComunaColor(colorDict)
  }, [colorDict, setComunaColor])

  const handleRegion = (region: string) => {
    navigate(`/${ElectionType.ALCALDES}/territorio/${region}/pactos`)
  }

  return (
    <div className="h-full">
      <PactoElectos
        data={data}
        selection={
          <RegionSelector
            className="w-[50%] hidden xl:flex"
            onClick={handleRegion}
            value={String(currentRegion)}
          />
        }
        title="Pactos por Región"
      />
    </div>
  )
}
