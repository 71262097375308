import styled from 'styled-components'

export interface ICircle {
  color?: string
}

export const Circle = styled.div<ICircle>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color = '#323232' }) => color};
  border-radius: 50%;
  width: 100%;
  aspect-ratio: 1 / 1;
`

export default Circle
