import MPactos, { generateCircles, PactoResult } from '@/components/MagicWall/Pactos'
import { getResultsByPacto } from '@/lib/dataProcess'
import { buildPactosViewData } from '@/lib/buildDataViews'
import { ElectionType } from '@/types/Elections'
import { TOTAL_ELECTOS } from '@/constants'
import CircleMesh from '@/components/CircleMesh/CircleMesh'
import useElectionData from '@/stores/useElectionData'
import { useMemo } from 'react'

export default function CRPactos() {
  const {
    files: { core },
  } = useElectionData()

  const data: PactoResult[] = useMemo(() => {
    const resultByPactos = getResultsByPacto(core || [])
    return buildPactosViewData(resultByPactos, ElectionType.CORE)
  }, [core])

  const circles = useMemo(() => {
    const orderedData = data.sort((a, b) => a.orden - b.orden)
    return generateCircles(orderedData, TOTAL_ELECTOS[ElectionType.CORE])
  }, [data])

  return (
    <MPactos
      className="mgw:py-14 mgw:px-6 py-12 px-5"
      election="Consejeros Regionales"
      data={data}
      verticalSeparatorBefore="Otros"
      numberOfPartidosbyColumn={7}
      minWidthPactos="85px"
      minWidthPartidos="85px"
    >
      <CircleMesh circles={circles} nrows={5} gap="5px" />
    </MPactos>
  )
}
