import HorizontalMap from '@/components/Web/HorizontalMap'
import MPactos, { type PactoResult } from '@/components/Web/MPactos'
import { colors, PACTOS } from '@/constants'
import { regiones } from '@/stores/useTerritorios'
import styled from 'styled-components'
import useElectionData from '@/stores/useElectionData'
import { useMemo, useState } from 'react'
import { ElectionType } from '@/types/Elections'
import { getResultsByPacto } from '@/lib/dataProcess'
import { buildPactosViewData } from '@/lib/buildDataViews'
import { Circle } from 'lucide-react'
import Scroll from '@/assets/scroll.svg'
import DecideChileMono from '@/components/DecideChileMono'
import { Button } from '@/components/ui/button'
import { Eye, EyeOff } from 'lucide-react'

export default function Pactos() {
  const {
    files: { gore },
  } = useElectionData()

  const [showPartidosMobile, setShowPartidosMobile] = useState(false)

  const data: PactoResult[] = useMemo(() => {
    const resultByPactos = getResultsByPacto(gore || [])
    return buildPactosViewData(resultByPactos, ElectionType.GORE, true)
  }, [gore])

  // Set color of each region but GS
  const regionesData = useMemo(() => {
    const orderedRegiones = regiones
      // Remove GS
      .filter((region) => region.codigo !== 17)
      // Sort by order of appearance in the map
      .sort((a, b) => {
        if (a.orden && b.orden) {
          return a.orden - b.orden
        }
        return 0
      })

    return orderedRegiones.map((region) => {
      // Check if region has an electo
      const electosRegion = (gore || []).filter(
        (electo) => Number(electo.cod_region) === Number(region.codigo) && electo.electo
      )

      const selectedColor =
        electosRegion.length === 1
          ? // If there is only one electo, get the color of the pacto
            PACTOS[ElectionType.GORE][electosRegion[0].cod_pacto].color
          : // if no data, use gray, else empty string for 2 vuelta
            ''
      return {
        name: region.acronimo || region.nombre,
        selectedColor,
        code: region.codigo,
        realName: region.nombre,
      }
    })
  }, [gore])

  return (
    <MPactos
      data={data}
      verticalSeparatorBefore="Otros"
      numberOfPartidosbyColumn={5}
      minWidthPactos="71px"
      className="relative"
      showPartidosMobile={showPartidosMobile}
      toggle={
        <div className="xl:hidden">
          <Scroll />
        </div>
      }
    >
      <HideScrollbar className="xl:px-[50px] pb-[5px] mb-4 xl:mb-0 xl:pb-[10px] max-w-full xl:w-[867px] xl:mt-3 overflow-y-auto px-3">
        <HorizontalMap
          selectionProperty="cod_comuna"
          position="center"
          regionResults={regionesData}
        />
      </HideScrollbar>
      <div className="flex w-full h-full justify-center gap-2 items-center -mt-3">
        <Circle color="#4f4f4f" className="w-[12px] h-[12px]" />
        <Text>2da Vuelta</Text>
      </div>
      <div className="flex w-full justify-between xl:justify-end items-center mb-1 -mt-3">
        <DecideChileMono />
        <Button
          className="gap-2 flex xl:hidden uppercase"
          variant={'outline'}
          size={'ghostSmall'}
          onClick={() => setShowPartidosMobile(!showPartidosMobile)}
        >
          {showPartidosMobile ? <EyeOff size={13} /> : <Eye size={13} />}
          Partidos
        </Button>
      </div>
    </MPactos>
  )
}

const Text = styled.div`
  font-size: 12px;
  color: ${colors.blackDch};
  font-weight: 400;
`

const HideScrollbar = styled.div`
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px; /* Adjust the width of the fade effect */
    pointer-events: none;
    height: 100px;
  }
  &::before {
    left: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
  &::after {
    right: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
  padding-left: 15px;
  padding-right: 20px;
`
