import Ranking from '@/components/Web/Ranking'
import useElectionData from '@/stores/useElectionData'
import { jointCandidatosColor } from '@/lib/dataProcess'
import { useMemo } from 'react'
import { PACTOS } from '@/constants/pactos'
import { PARTIDOS } from '@/constants/pactos'
import { ElectionType } from '@/types/Elections'
import { Comunas10Mil } from '@/constants/chileEnDatos'
export default function CRanking({ option = 'top' }: { option?: 'top' | 'bottom' }) {
  const {
    files: { concejales },
  } = useElectionData()

  const electos = useMemo(() => {
    return (
      concejales?.filter((c) => c.votos > 0 && Comunas10Mil.includes(c.cod_comuna.toString())) || []
    )
  }, [concejales])

  const processedData = useMemo(() => {
    if (electos.length === 0) {
      return []
    }
    return jointCandidatosColor(electos, ElectionType.CONCEJALES).map((electo) => {
      return {
        ...electo,
        partido: PARTIDOS[electo.cod_partido]?.shortName,
        pacto: PACTOS[ElectionType.CONCEJALES][electo.cod_pacto]?.shortName || electo.cod_pacto,
        imagen: electo.imagen || '/userProfile.png',
        monoLabel: electo.comuna,
      }
    })
  }, [electos])

  return <Ranking data={processedData} option={option} />
}
