import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { regiones } from '@/stores/useTerritorios'
import { type SelectProps } from '@radix-ui/react-select'
import { ChevronDown } from 'lucide-react'
type RegionSelectorProps = {
  onClick: (value: string) => void
  className?: string
  showIcon?: boolean
  useGS?: boolean
  side?: 'top' | 'bottom' | 'left' | 'right'
} & SelectProps
export default function RegionSelector({
  onClick,
  value,
  className,
  showIcon = true,
  useGS = true,
  side = 'bottom',
}: RegionSelectorProps) {
  const regionesFinal = useGS ? regiones : regiones.filter((region) => region.codigo !== 17)
  return (
    <Select onValueChange={onClick} value={value}>
      <SelectTrigger
        className={cn(`w-[180px] ${className}`, {
          'justify-start gap-1': !showIcon,
        })}
        showIcon={showIcon}
      >
        <SelectValue placeholder="Regiones" className="underline underline-offset-1" />
        {!showIcon && <ChevronDown className="h-4 w-4 opacity-50" />}
      </SelectTrigger>
      <SelectContent
        side={side}
        className="h-[250px] xl:h-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <SelectGroup>
          <SelectLabel>Regiones</SelectLabel>
          {regionesFinal.map((option) => (
            <SelectItem key={option.codigo} value={String(option.codigo)}>
              <p>{option.nombreCorto || option.nombre}</p>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
