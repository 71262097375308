import Electos from '@/components/MagicWall/Electos'
import useRouting from '@/stores/useRouting'
import { regiones } from '@/stores/useTerritorios'
import useElectionData from '@/stores/useElectionData'
import { useEffect, useMemo } from 'react'
import { jointCandidatosColor } from '@/lib/dataProcess'
import { getTerritorioColorDictByMajority } from '@/lib/dataProcess'
import useTerritorios from '@/stores/useTerritorios'
import { GSComunas } from '@/stores/useTerritorios'
import { CandidatoGore } from '@/types/Files'
import { ElectionType } from '@/types/Elections'
import { PACTOS } from '@/constants'

export function getRealRegionCode(regionCode: number | undefined) {
  switch (regionCode) {
    case 17:
      return 13
    default:
      return regionCode as number
  }
}

export function calculateSubRegion(comunas: number[], originalCandidates: CandidatoGore[]) {
  const total_votos_GS = comunas.reduce((acc, comuna) => {
    const comuna_string = comuna.toString()
    return (
      acc +
      originalCandidates[0].resultados_comuna[comuna_string]?.votos /
        (originalCandidates[0].resultados_comuna[comuna_string]?.porcentaje / 100)
    )
  })
  const SubRegionGores = originalCandidates.map((gore) => {
    const voto_GS = GSComunas.reduce((acc, comuna) => {
      return acc + gore.resultados_comuna[comuna]?.votos
    })
    const porcentaje_GS = (voto_GS / total_votos_GS) * 100
    return {
      ...gore,
      porcentaje: porcentaje_GS || 0,
      votos: voto_GS || 0,
    }
  })

  //sort by porcentaje
  return SubRegionGores.sort((a, b) => b.porcentaje - a.porcentaje)
}

export default function GElectosRegion() {
  const { currentRegion } = useRouting()
  const {
    files: { gore },
  } = useElectionData()
  const { setComunaColor } = useTerritorios()

  const region = regiones.find((region) => region.codigo === currentRegion)
  const orderedGores = useMemo(
    () =>
      gore
        ? gore
            .filter(
              (c) => c.cod_region.toString() === (getRealRegionCode(currentRegion) ?? '').toString()
            )
            .sort((a, b) => b.porcentaje - a.porcentaje)
        : [],
    [gore, currentRegion]
  )

  const processedData = useMemo(() => {
    if (orderedGores.length === 0) {
      return []
    }
    if (currentRegion === 17) {
      const GSGores = calculateSubRegion(GSComunas, orderedGores)
      return jointCandidatosColor(GSGores, ElectionType.GORE).map((electo, i) => ({
        ...electo,
        pacto: PACTOS[ElectionType.GORE][electo.cod_pacto]?.shortName || electo.cod_pacto,
        order: i + 1,
        imagen: electo.imagen || '/userProfile.png',
      }))
    }

    return jointCandidatosColor(orderedGores, ElectionType.GORE).map((electo, i) => ({
      ...electo,
      order: i + 1,
      pacto: PACTOS[ElectionType.GORE][electo.cod_pacto]?.shortName || electo.cod_pacto,
      imagen: electo.imagen || '/userProfile.png',
    }))
  }, [orderedGores, currentRegion])

  // Process color for each comuna
  useEffect(() => {
    if (gore && currentRegion) {
      const colorDict = getTerritorioColorDictByMajority(
        gore,
        currentRegion as number,
        ElectionType.GORE
      )
      setComunaColor(colorDict)
    }
  }, [gore, currentRegion, setComunaColor])

  return (
    <Electos
      title={region?.nombreCorto || region?.nombre || ''}
      data={processedData}
      cod_region={currentRegion}
    />
  )
}
