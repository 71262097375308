import { SubmenuProps } from '@/components/MagicFrame/SubMenu/SubMenu'
import { create } from 'zustand'

export type MagicFrameState = {
  isSubmenuOpen: boolean
  subMenuOptions?: SubmenuProps
  setSubmenuOpen: (open: boolean) => void
  setSubMenuOptions: (submenuProps: SubmenuProps) => void
  resetSubMenuOptions: () => void
}

const useMagicFrame = create<MagicFrameState>((set) => ({
  isSubmenuOpen: false,
  selectedSubMenuOption: undefined,
  setSubmenuOpen: (open) => set({ isSubmenuOpen: open }),
  setSubMenuOptions: (options) => set({ subMenuOptions: options }),
  resetSubMenuOptions: () => set({ subMenuOptions: undefined }),
}))

export default useMagicFrame
