import Ranking from '@/components/Web/Ranking'
import useElectionData from '@/stores/useElectionData'
import { jointCandidatosColor } from '@/lib/dataProcess'
import { useMemo } from 'react'
import { PACTOS } from '@/constants/pactos'
import { PARTIDOS } from '@/constants/pactos'
import { ElectionType } from '@/types/Elections'
import { Comunas10Mil } from '@/constants/chileEnDatos'

const COMUNAS_ONE_CANDIDATE = [5706, 15102, 10204]

export default function ARanking({ option = 'top' }: { option?: 'top' | 'bottom' }) {
  const {
    files: { alcaldes },
  } = useElectionData()

  const alcaldesElectos = useMemo(() => {
    return alcaldes
      ? alcaldes.filter((candidato) => {
          return (
            !COMUNAS_ONE_CANDIDATE.includes(candidato.cod_comuna) &&
            Comunas10Mil.includes(candidato.cod_comuna.toString())
          )
        })
      : []
  }, [alcaldes])

  const processedData = useMemo(() => {
    if (alcaldesElectos.length === 0) {
      return []
    }
    return jointCandidatosColor(alcaldesElectos, ElectionType.ALCALDES).map((electo) => {
      return {
        ...electo,
        partido: PARTIDOS[electo.cod_partido]?.shortName,
        pacto: PACTOS[ElectionType.ALCALDES][electo.cod_pacto].shortName || electo.cod_pacto,
        imagen: electo.imagen || '/userProfile.png',
        monoLabel: electo.comuna,
      }
    })
  }, [alcaldesElectos])

  return <Ranking data={processedData} option={option} />
}
