import styled from 'styled-components'
import React from 'react'
import { colors } from '@/constants'
import { NameCandidateBadge } from '@/components/Web/CandidateBadge'
import Card from '@/components/Web/Card'
import InfoToolTip from '../InfoToolTip'
import { Skeleton } from '../ui/skeleton'
import InfoPopoverForMobile from '../InfoPopoverForMobile'
export type Data = {
  comuna: string
  pacto: string
  nombre: string
  partido: string
  imagen: string
  color: string
  porcentaje_2021: string
  porcentaje_2024: string
}

type Reelecto = {
  data: Data[]
  selection?: React.ReactNode
}

export default function Reelecto({ data, selection }: Reelecto) {
  const fakeItem: Data = {
    comuna: '',
    pacto: '',
    nombre: '',
    partido: '',
    imagen: 'userProfile.png',
    color: '#3c3c3c',
    porcentaje_2021: '',
    porcentaje_2024: '',
  }
  return (
    <Card title="Reelectos" selector={selection}>
      <div className="max-h-96 overflow-y-auto">
        <GridTable>
          <GridHeaderCell className="text-left sticky top-0 bg-white">COMUNA</GridHeaderCell>
          <GridHeaderCell className="sticky top-0 bg-white z-30" />
          <GridHeaderCell className="items-center flex gap-2 justify-center sticky top-0 bg-white">
            2024
            <InfoToolTip content="OBLIGATORIO" side="top" />
            <InfoPopoverForMobile content="OBLIGATORIO" side="top" />
          </GridHeaderCell>
          <GridHeaderCell className="items-center flex gap-2 justify-center sticky top-0 opacity-100">
            2021 <InfoToolTip content="VOLUNTARIO" side="top" />
            <InfoPopoverForMobile content="OBLIGATORIO" side="top" />
          </GridHeaderCell>
          {data.length === 0 &&
            Array.from({ length: 6 }).map((_, index) => (
              <React.Fragment key={index}>
                <GridCell className="flex flex-col items-start">
                  <Skeleton className="w-[80%] h-3" />
                  <Skeleton className="w-[70%] h-2 mt-1" />
                </GridCell>
                <GridCell className="flex items-center w-full">
                  <NameCandidateBadge electo={fakeItem} isBlank />
                </GridCell>
                <PercentageGridCell className="font-semibold text-[15px]">
                  <Skeleton className="w-[50%] h-3" />
                </PercentageGridCell>
                <PercentageGridCell className="font-base text-[15px]">
                  <Skeleton className="w-[50%] h-3" />
                </PercentageGridCell>
              </React.Fragment>
            ))}

          {data.map((item, index) => (
            <React.Fragment key={index}>
              <GridCell className="flex flex-col items-start border-b border-b-[#eeeef0]">
                <div className="font-medium text-[13px]">{item.comuna}</div>
                <div className="font-light text-[11px] ">{item.pacto}</div>
              </GridCell>
              <GridCell className="flex items-center w-full border-b border-b-[#eeeef0]">
                <NameCandidateBadge electo={item} />
              </GridCell>
              <PercentageGridCell className="font-semibold text-[15px] border-b border-b-[#eeeef0]">
                {item.porcentaje_2024}
              </PercentageGridCell>
              <PercentageGridCell className="font-base text-[15px] border-b border-b-[#eeeef0]">
                {item.porcentaje_2021}
              </PercentageGridCell>
            </React.Fragment>
          ))}
        </GridTable>
      </div>
    </Card>
  )
}

const GridTable = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr 0.6fr 0.6fr;
  /* grid-template-columns: 1.2fr 1fr 0.6fr; */
  grid-auto-rows: min-content;
`

const GridHeaderCell = styled.div`
  font-family: 'JetBrains Mono';
  font-size: 12px;
  color: ${colors.blackDch};
  border-bottom: 1px solid ${colors.gray1Dch};
  padding: 10px 0;
  &:nth-of-type(4n) {
    padding-left: 10px;
    background-color: #fafafa;
  }
`

const GridCell = styled.div`
  display: flex;
  font-size: 17px;
  padding: 3px 0;
  padding-top: 6px;
  color: ${colors.blackDch};
  &:nth-of-type(4n) {
    padding-left: 10px;
    background-color: #fafafa;
  }
`

const PercentageGridCell = styled(GridCell)`
  display: flex;
  justify-content: center;
  align-items: center;
`
