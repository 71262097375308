import ElectosTab from '@/components/MagicWall/ElectosTab'
import { PACTOS_CORE } from '@/constants'
import { compareCORECircunscripcion } from '@/helpers/formatters'
import useElectionData from '@/stores/useElectionData'
import useRouting from '@/stores/useRouting'
import useTerritorios, { comunas } from '@/stores/useTerritorios'
import { useMemo } from 'react'

export default function CRElectosComuna() {
  const { currentCircunscripcion } = useRouting()
  const { setComunaColor } = useTerritorios()
  const {
    files: { core },
  } = useElectionData()

  const data = useMemo(() => {
    if (!core || !currentCircunscripcion) return []

    const electos = core.filter(
      (c) =>
        compareCORECircunscripcion(currentCircunscripcion, c.cod_circunscripcion || '') && c.electo
    )

    const comunasCircunscripcion = comunas
      .filter((comuna) =>
        compareCORECircunscripcion(currentCircunscripcion, comuna.circunscripcionProvincial)
      )
      .map((comuna) => comuna.codigo)

    const comunasColor: Record<number, string> = {}
    comunasCircunscripcion.forEach((comuna) => {
      const orderedByVotos = electos.sort(
        (a, b) => b?.resultados_comuna[comuna]?.votos - a.resultados_comuna[comuna]?.votos
      )
      comunasColor[comuna] = PACTOS_CORE[orderedByVotos[0]?.cod_pacto]?.color || ''
    })
    setComunaColor(comunasColor)

    return electos
      .sort((a, b) => b.porcentaje - a.porcentaje)
      .map((electo) => ({
        ...electo,
        pacto: PACTOS_CORE[electo.cod_pacto]?.name || electo?.cod_pacto,
        color: PACTOS_CORE[electo.cod_pacto]?.color || '',
        imagen: electo?.imagen || '/userProfile.png',
      }))
  }, [core, currentCircunscripcion, setComunaColor])

  return <ElectosTab data={data} pageSize={5} title={currentCircunscripcion as string} />
}
