import { regiones } from '@/stores/useTerritorios'
import Relecto from '@/components/Web/GORE/Reelectos'
import { PACTOS_GORE } from '@/constants'
import { formatFullName } from '@/helpers/formatters'
import useElectionData from '@/stores/useElectionData'
import { useMemo } from 'react'

const CUSTOM_PERCENTAGE_REGIONES = {
  1: 59.2,
  16: 53,
  14: 57,
}

export default function GoreReelectos() {
  const {
    files: { gore },
    comparador: { gore: gore2021 },
  } = useElectionData()

  const data = useMemo(() => {
    if (!gore || !gore2021) return []
    const electos = gore
      ?.filter((candidato) => candidato.reelecto)
      .map((electo) => {
        const pacto = PACTOS_GORE[electo.cod_pacto] || {}
        const electo2021 = gore2021.electos_persona[electo.cod_region]
        const percentage2021 =
          CUSTOM_PERCENTAGE_REGIONES[
            electo.cod_region as keyof typeof CUSTOM_PERCENTAGE_REGIONES
          ] ||
          electo2021?.porcentaje ||
          0
        return {
          region_code: Number(electo.cod_region) || 0,
          region: electo?.region || '',
          region_pacto: pacto.shortName || electo?.cod_pacto || '',
          color: pacto.color || '',
          imagen: electo.imagen || '/userProfile.png',
          nombre: formatFullName(electo.nombre || ''),
          pacto: electo.cod_pacto,
          partido: electo.cod_partido,
          cod_region: electo.cod_region,
          electo_2024: {
            nombre: formatFullName(electo.nombre || ''),
            imagen: electo.imagen || '/userProfile.png',
            color: pacto.color || '',
            partido: electo.cod_partido,
            pacto: electo.pacto,
          },
          porcentaje_2024: (electo?.porcentaje || 0).toFixed(1).replace('.', ',') + '%',
          porcentaje_2021: percentage2021.toFixed(1).replace('.', ',') + '%',
        }
      })
    return electos
  }, [gore, gore2021])

  const regionesData = regiones
    .filter((region) => region.codigo !== 17)
    .sort((a, b) => {
      if (a.orden && b.orden) {
        return a.orden - b.orden
      }
      return 0
    })
    .map((region) => ({
      code: region.codigo,
      name: region.acronimo || region.nombre,
      selectedColor: data.find((item) => item.region_code === region.codigo)?.color || '#B1B1BB',
      realName: region.nombre,
    }))

  return <Relecto data={data} regiones={regionesData} title="Relectos" />
}
