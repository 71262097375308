import { colors, electionParams } from '@/constants'
import styled from 'styled-components'
import Participacion from '@/components/MagicWall/Participacion'
import CNNLogoEleccionBase from '@/assets/logoCNNRed.svg'
import useRouting from '@/stores/useRouting'
import { regiones } from '@/stores/useTerritorios'

type Layout = {
  children: React.ReactNode
}

export default function Layout({ children }: Layout) {
  const { currentElection, currentRegion, currentCircunscripcion } = useRouting()

  const regionData = regiones.find((region) => region.codigo === currentRegion)
  const regionName = regionData?.nombreCorto || regionData?.nombre

  // Get election name
  let electionName = currentElection ? electionParams[currentElection].longName : ''
  // Change gore name to Gobernador if the view is about a region
  if (currentElection === 'gore' && currentRegion) electionName = 'Gobernador'
  if (currentElection === 'core') electionName = 'Cores'

  const title =
    !currentRegion && !currentCircunscripcion
      ? `${electionName}`
      : currentRegion
      ? `${electionName} Región ${regionName}`
      : `${electionName} ${currentCircunscripcion}`

  return (
    <Wrapper>
      <UpContent>
        <TitleWrapper>
          <Title>
            <div className="font-bold">{title}</div>
            <div className="font-normal">2024</div>
          </Title>
          <Participacion />
        </TitleWrapper>
        <CNNLogoEleccion />
      </UpContent>
      {children}
    </Wrapper>
  )
}

const Title = styled.div.attrs({
  className: 'mgw:text-[4rem] mgw:gap-4 text-[3rem] gap-2',
})`
  display: flex;
  color: ${colors.blackDch};
`

const UpContent = styled.div.attrs({
  className: 'mgw:pb-8 pb-4',
})`
  display: grid;
  grid: 1fr / 1fr min-content;
  gap: 20px;
  pointer-events: all;
  align-items: start;
`

const TitleWrapper = styled.div`
  display: grid;
  grid: 1fr min-content / 1fr;
`

const CNNLogoEleccion = styled(CNNLogoEleccionBase).attrs({
  className: 'mgw:w-[230px] mgw:h-[119px] w-[150px] h-full',
})``

const Wrapper = styled.div.attrs({
  className: 'mgw:p-[3rem] p-[2rem]',
})`
  display: grid;
  height: 100%;
  width: 100%;
  grid: min-content 1fr / 1fr;
  overflow: auto;
`
