import styled from 'styled-components'
import { ReactNode } from 'react'
import StatusTag from '@/components/MagicWall/StatusTag'
import { colors } from '@/constants'
import { Separator } from '@/components/ui/separator'
import useRouting from '@/stores/useRouting'

type ICard = {
  title: string
  statusLabel?: string
  children: ReactNode
  separatorColor?: string
  toggle?: ReactNode
}

export default function Card({
  title,
  statusLabel,
  children,
  toggle,
  separatorColor = colors.gray3Dch,
}: ICard) {
  const { currentElection } = useRouting()

  return (
    <Wrapper>
      <Top>
        <div className="flex items-end h-full gap-7">
          <Title>{title}</Title>
          {/* {statusLabel && currentElection !== 'gore' ? <StatusTag text={statusLabel} /> : null} */}
        </div>
        {toggle}
      </Top>
      <CustomSeparator color={separatorColor} />
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid: min-content min-content 1fr / 1fr;
  background-color: ${colors.whiteDch2};
  box-shadow: 4px 4px 0px 4px ${colors.frameBoxShadowCard};
  border: 1px solid #b7b7b7;
  border-radius: 8px;
  width: 100%;
  height: 100%;
`

const Top = styled.div.attrs({
  className: 'mgw:py-9 mgw:px-10 py-8 px-10',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.div.attrs({
  className: 'mgw:text-4xl text-3xl',
})`
  color: ${colors.blackDch};
  font-weight: 600;
`

const CustomSeparator = styled(Separator).attrs<{ color: string }>({
  className: 'mgw:h-[2px] h-[1px]',
})`
  color: ${colors.blackDch};
  background-color: ${({ color }) => color};
`
