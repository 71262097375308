import comunas_topojson from '@/assets/files/comunas_chile.json'
import getInitialMap from '@/components/Map/getInitialMap'
import useRefDimensions from '@/hooks/useRefDimensions'
import * as d3 from 'd3'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Loading from '../Loading'
import { ChileFeature, ChileTopoJSON, ChileTopoProperties } from './types'
import zoomSelection from './zoomSelection'

const typedComunasTopojson = comunas_topojson as unknown as ChileTopoJSON

export type Selected = {
  cut: number
  color: string
  element: React.ReactNode
  focused?: boolean
  opacity?: number
}

export type MapProps = {
  position?: 'center' | 'right-center'
  selectionProperty: keyof ChileTopoProperties
  selected?: Selected[]
  onClick?: (feature?: ChileFeature) => void
  topoJson?: ChileTopoJSON
  scale?: [number, number]
  translate?: [number, number]
  rotate?: [number, number, number]
  selectionColor?: string
  waterColor?: string
  continentColor?: string
}

const clickHandler = (feature?: ChileFeature, onClick?: (feature?: ChileFeature) => void) => {
  if (onClick) onClick(feature)
}
export default function Map({
  topoJson = typedComunasTopojson,
  selected = [],
  onClick,
  position,
  scale,
  translate,
  rotate,
  selectionColor,
  waterColor,
  continentColor,
}: MapProps) {
  const mapWrapperRef = useRef<HTMLDivElement>(null)
  const [mapSvg, setMapSvg] = useState<d3.Selection<SVGSVGElement, undefined, null, undefined>>()
  const dimensions = useRefDimensions({ ref: mapWrapperRef })

  useEffect(() => {
    const parentElement = mapWrapperRef.current
    const mapElement = mapSvg?.node()
    if (!(parentElement && mapElement && mapSvg)) return
    parentElement.appendChild(mapElement)
    zoomSelection({
      mapSvg,
      selected,
      dimensions,
      transitionDuration: 0,
      onClick: (feature) => clickHandler(feature, onClick),
      position,
      scale,
      translate,
      selectionColor,
      continentColor,
    })
    // Only need no transition in first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapSvg, mapWrapperRef])

  useEffect(() => {
    if (!topoJson || !mapWrapperRef.current || mapSvg) return
    const { mapaSvg } = getInitialMap({
      topoJson,
      onClick: (feature) => clickHandler(feature, onClick),
      rotate,
      waterColor,
    })
    if (!mapaSvg) return
    setMapSvg(mapaSvg)
  }, [topoJson, mapWrapperRef, mapSvg, onClick, rotate, waterColor])

  zoomSelection({
    mapSvg,
    selected,
    dimensions,
    onClick: (feature) => clickHandler(feature, onClick),
    position,
    scale,
    translate,
    continentColor,
    selectionColor,
  })

  return (
    <Wrapper ref={mapWrapperRef} waterColor={waterColor}>
      {!topoJson && <Loading />}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ waterColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${({ waterColor }) => waterColor || '#e6e6e6'};
`
