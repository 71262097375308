import { electionParams } from '@/constants'
import useRouting from '@/stores/useRouting'
import useTerritorios from '@/stores/useTerritorios'
import { ElectionType } from '@/types/Elections'
import { RouteParams } from '@/types/RouteParams'
import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

const getElectionByPath = (pathname: string) => {
  const election = Object.entries(electionParams).find(
    ([key]) => pathname.includes(key) && !pathname.includes('datos-comunales')
  )
  return election
}

export default function useUpdateFromRoute() {
  const {
    setCurrentElection,
    setSelectedCircunscripcion,
    setSelectedComuna,
    setSelectedRegion,
    setIsInTerritorio,
    setCurrentPathname,
    setIsMagicChileEnDatos,
    setCurrentSubtema,
    setCurrentTema,
  } = useRouting()
  const { pathname } = useLocation()
  const election = getElectionByPath(pathname)?.[0]
  const { region, comuna, circunscripcion, tema, subtema } = useParams<RouteParams>()
  const { setSelectedComunasById, setSelectedRegionesById, setSelectedCircunscripcionesById } =
    useTerritorios()

  useEffect(() => {
    if (region) setSelectedRegionesById([Number(region)])
    if (circunscripcion) setSelectedCircunscripcionesById([circunscripcion])
    if (comuna) setSelectedComunasById([Number(comuna)])

    setCurrentElection(election as ElectionType)
    setSelectedRegion(Number(region))
    setSelectedComuna(Number(comuna))
    //Cast uri format to string
    setSelectedCircunscripcion(circunscripcion && decodeURI(circunscripcion))
    setIsInTerritorio(pathname.includes('territorio'))
    setCurrentPathname(pathname)
    setIsMagicChileEnDatos(
      pathname.includes('chile-en-datos') || pathname.includes('datos-comunales')
    )
    setCurrentTema(tema)
    setCurrentSubtema(subtema)
  }, [
    region,
    comuna,
    circunscripcion,
    election,
    pathname,
    subtema,
    tema,
    setSelectedRegionesById,
    setSelectedComunasById,
    setSelectedCircunscripcionesById,
    setCurrentElection,
    setSelectedRegion,
    setSelectedComuna,
    setSelectedCircunscripcion,
    setIsInTerritorio,
    setCurrentPathname,
    setIsMagicChileEnDatos,
    setCurrentTema,
    setCurrentSubtema,
  ])
}
