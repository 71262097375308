import ElectosComuna from '@/components/MagicWall/Electos'
import useRouting from '@/stores/useRouting'
import { comunas } from '@/stores/useTerritorios'
import useElectionData from '@/stores/useElectionData'
import { useMemo } from 'react'
import { jointCandidatosColor } from '@/lib/dataProcess'
import useTerritorios from '@/stores/useTerritorios'
import { PACTOS_ALCALDES } from '@/constants/pactos'
import { ElectionType } from '@/types/Elections'

export default function AElectosComuna() {
  const { currentComuna } = useRouting()
  const { files } = useElectionData()
  const { alcaldes } = files
  const { setComunaColor } = useTerritorios()
  const comuna = comunas.find((c) => c.codigo === currentComuna)

  const alcaldeElecto = useMemo(() => {
    return alcaldes?.find(
      (c) => c.cod_comuna?.toString() === (currentComuna ?? '')?.toString() && c.electo
    )
  }, [alcaldes, currentComuna])

  const totalAlcaldeCount = useMemo(() => {
    return alcaldes?.filter((c) => c.cod_comuna?.toString() === (currentComuna ?? '')?.toString())
      .length
  }, [alcaldes, currentComuna])

  const orderedAlcaldes = useMemo(
    () =>
      alcaldes
        ? alcaldes
            .filter((c) => c.cod_comuna?.toString() === (currentComuna || '')?.toString())
            .sort((a, b) => b.porcentaje - a.porcentaje)
        : [],
    [alcaldes, currentComuna]
  )

  const processedData = useMemo(() => {
    if (orderedAlcaldes.length === 0) {
      return []
    }
    setComunaColor({
      [currentComuna as number]: PACTOS_ALCALDES[alcaldeElecto?.cod_pacto as string]?.color || '',
    })
    return jointCandidatosColor(orderedAlcaldes, ElectionType.ALCALDES).map((electo, i) => ({
      ...electo,
      pacto: PACTOS_ALCALDES[electo.cod_pacto]?.shortName || electo.cod_pacto,
      order: i + 1,
      imagen: electo.imagen || '/userProfile.png',
    }))
  }, [alcaldeElecto?.cod_pacto, currentComuna, orderedAlcaldes, setComunaColor])

  return (
    <div className="h-full">
      <ElectosComuna
        title={comuna?.nombre || ''}
        data={processedData}
        totalCount={totalAlcaldeCount as number}
      />
    </div>
  )
}
