import Comparacion, { type Data } from '@/components/Web/Comparacion'
import useElectionData from '@/stores/useElectionData'
import { useMemo } from 'react'
import { getResultsByPacto } from '@/lib/dataProcess'
import { buildComparacionData } from '@/lib/buildDataViews'
import { ElectionType } from '@/types/Elections'
import { getResultsByPacto2021CC } from '@/lib/dataProcess2021'

export default function CComparacion() {
  const {
    files: { concejales },
    comparador: { concejales: concejales2021 },
  } = useElectionData()

  const data: Data[] = useMemo(() => {
    const resultsByPacto2024 = getResultsByPacto(concejales || [])
    const resultsByPacto2021 = concejales2021 ? getResultsByPacto2021CC(concejales2021) : {}

    return buildComparacionData(resultsByPacto2024, resultsByPacto2021, ElectionType.CONCEJALES)
  }, [concejales, concejales2021])

  return <Comparacion data={data} verticalSeparatorBefore="Otros" />
}
