import { ChileFeature } from '@/components/Map/types'
import useMapSelected from '@/hooks/useMapSelected'
import useRouting from '@/stores/useRouting'
import { comunas, circunscripcionesProvinciales } from '@/stores/useTerritorios'
import { ElectionType } from '@/types/Elections'
import { Outlet, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { regiones } from '@/stores/useTerritorios'
import MapBackground from '@/components/Web/MapBackground'
import RegionSelector from '@/components/RegionSelector'
import DecideChileMono from '@/components/DecideChileMono'
import useElectionData from '@/stores/useElectionData'
import { numberWithDots } from '@/helpers/formatters'
import ProvinciaSelector from '@/components/Web/ProvinciaSelector'
import InfoToolTip from '@/components/InfoToolTip'
import InfoPopoverForMobile from '@/components/InfoPopoverForMobile'

function getPathToRegion(currentPath: string, region: string) {
  const path = currentPath.split('/')
  if (path.length === 4) {
    // just change the region
    path[3] = region
    return path.join('/')
  } else if (path.length === 5) {
    if (/^\d+$/.test(path[4])) {
      // change the region and the comuna to default
      path[3] = region
      path[4] =
        regiones
          .find(({ codigo }) => codigo.toString() === region.toString())
          ?.comunaDefecto?.toString() || ''
      return path.join('/')
    } else {
      path[3] = region
      return path.join('/')
    }
  }
  return currentPath
}

export default function LayoutTerritorio() {
  const { currentPathname, currentElection, currentRegion, currentComuna, currentCircunscripcion } =
    useRouting()
  const navigate = useNavigate()
  const { participation } = useElectionData()
  const selected = useMapSelected()
  const territorioAttribute =
    currentElection === ElectionType.CORE ? 'circunscripcionProvincial' : 'region'

  const onClickTerritorio = (feature?: ChileFeature) => {
    const properties = feature?.properties
    const basePath = currentPathname ? currentPathname.split('/').slice(0, 3).join('/') : '/'
    let navigatePath = currentPathname || basePath
    if (properties) {
      const comunaTerritorio = comunas.find((c) => c.codigo === properties.cod_comuna)?.[
        territorioAttribute
      ]
      navigatePath = `${basePath}/${comunaTerritorio}/${properties.cod_comuna}`
    }
    navigate(navigatePath)
  }

  const territorio =
    currentElection === ElectionType.CORE
      ? circunscripcionesProvinciales.find((c) => c.nombre === currentCircunscripcion)
      : regiones.find((r) => r.codigo === currentRegion)

  const regionName = territorio?.nombre || ''
  const comunasInRegion = territorio?.comunas || []

  const handleRegion = (region: string) => {
    const path = getPathToRegion(currentPathname || '', region)
    navigate(path)
  }

  const currentParticipation = participation[currentElection || 'alcaldes']
  const votoInRegion = Object.entries(currentParticipation?.resultados_comuna || {})
    .map(([key, value]) => (comunasInRegion.includes(Number(key)) ? value.votos : 0))
    .reduce((a, b) => a + b, 0)

  const inscritosRegion = Object.entries(currentParticipation?.resultados_comuna || {})
    .map(([key, value]) => (comunasInRegion.includes(Number(key)) ? value.inscritos : 0))
    .reduce((a, b) => a + b, 0)

  const comunasName =
    comunasInRegion.map((comuna) => comunas.find((c) => c.codigo === comuna)?.nombre || '') || []
  return (
    <div>
      <div className="hidden xl:block relative">
        <MapBackground
          selectionProperty="cod_comuna"
          selected={selected}
          onClick={onClickTerritorio}
          scale={[1, 0.75]}
          position="right-center"
          translate={[0, -50]}
          waterColor="white"
          continentColor="white"
        >
          {!currentComuna && (
            <div className="absolute top-3 right-0 text-end">
              <div className="uppercase text-[12px] text-[#6d6e6f] flex gap-1 items-center justify-end">
                {regionName}{' '}
                {currentElection === ElectionType.CORE && (
                  <InfoToolTip
                    content={comunasName.join(', ') + '.' || ''}
                    side="bottom"
                    classNameContent="max-w-[200px] text-right"
                  />
                )}
              </div>
              <div className="text-[11px] text-negroDch font-light">
                Votos {numberWithDots(votoInRegion)} / {numberWithDots(inscritosRegion)}
              </div>
            </div>
          )}

          <DownRow>
            <Content>
              <Outlet />
            </Content>
            <OverpassMap />
          </DownRow>
        </MapBackground>
        <div className="absolute -bottom-3 right-0">
          <DecideChileMono />
        </div>
      </div>
      <div className="block xl:hidden mt-5">
        <div className="relative">
          <div className="absolute top-[-20px] left-0  z-20 flex w-full justify-between items-end  bg-white pb-2 ">
            <div>
              {currentElection === ElectionType.CORE ? (
                <ProvinciaSelector
                  className="w-[150px] border-none shadow-none underline underline-offset-2 focus:ring-0 px-0 h-7"
                  onClick={handleRegion}
                  value={String(currentCircunscripcion)}
                  showIcon={false}
                  comunasName={comunasName}
                />
              ) : (
                <RegionSelector
                  className="w-[150px] border-none shadow-none underline underline-offset-2 focus:ring-0 px-0 h-7"
                  onClick={handleRegion}
                  value={String(currentRegion)}
                  showIcon={false}
                />
              )}
              <p className="text-[11px] text-[#6d6e6f]">
                Votos {numberWithDots(votoInRegion)} / {numberWithDots(inscritosRegion)}
              </p>
            </div>

            <div className="text-[11px] text-[#6d6e6f] z-20">
              {currentElection === ElectionType.CORE && (
                <div className="flex items-center gap-1 justify-end">
                  COMUNAS
                  <InfoPopoverForMobile
                    content={comunasName.join(', ') + '.' || ''}
                    side="bottom"
                    classNameContent="max-w-[200px] text-right"
                  />
                </div>
              )}
              <p>Participación {currentParticipation?.participacion_porcentual}%</p>
            </div>
          </div>

          <MapBackground
            selectionProperty="cod_comuna"
            selected={selected}
            onClick={onClickTerritorio}
            scale={[1, 0.7]}
            position="center"
            translate={[0, -20]}
            waterColor="white"
            continentColor="white"
          />
          <div className="absolute -bottom-3 right-1/2 translate-x-1/2 z-30">
            <DecideChileMono />
          </div>
        </div>

        <Outlet />
      </div>
    </div>
  )
}

const DownRow = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(480px, 0.5fr) 0.5fr;
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: all;
`

const OverpassMap = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
`
