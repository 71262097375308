import { useCarousel } from '@/components/ui/carousel'
import { colors } from '@/constants'
import { useState } from 'react'
import styled from 'styled-components'

export default function Pagination({ total }: { total: number }) {
  const [page, setPage] = useState(1)
  const { canScrollPrev, canScrollNext, api } = useCarousel()
  const onlyOnePage = total === 1
  if (onlyOnePage) return null

  return (
    <Wrapper>
      {Array.from({ length: total }).map((_, index) => (
        <Page
          key={index}
          selected={index + 1 === page}
          onClick={() => {
            if (index + 1 === page) return
            if (index + 1 > page && !canScrollNext) return
            if (index + 1 < page && !canScrollPrev) return
            api?.scrollTo(index)
            setPage(index + 1)
          }}
        >
          {index + 1}
        </Page>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 6px 24px;
  gap: 12px;
  font-size: 20px;
`

const Page = styled.div<{ selected: boolean }>`
  aspect-ratio: 1;
  border-radius: 8px;
  background-color: ${({ selected }) => (selected ? colors.whiteDch : colors.whiteDch + '30')};
  border: 1px solid ${colors.frameFontLight};
  color: ${({ selected }) => (selected ? '#38416C' : colors.whiteDch)};
  font-weight: ${({ selected }) => (selected ? 500 : 400)};
  width: fit-content;
  padding: 2px 10px;
`
