import styled from 'styled-components'
import React from 'react'
import Card from '@/components/Web/Card'
import { colors } from '@/constants'
import { numberToPercent } from '@/helpers/formatters'
import { FullCandidateBadge } from '@/components/Web/CandidateBadge'
import { Progress } from '@/components/ui/progress'
import { LoaderCircle } from 'lucide-react'
import { Skeleton } from '../ui/skeleton'
export type Candidato = {
  nombre: string
  partido: string
  imagen: string
  color: string
  porcentaje: number
  pacto: string
  cod_partido: string
  region?: string
  electo: boolean
  comuna: string
}

interface ElectosTabProps {
  title: string
  data: Candidato[]
  selection: React.ReactNode
}

export default function ElectosTab({ data, selection, title }: ElectosTabProps) {
  const fakeItem: Candidato = {
    nombre: '',
    partido: '',
    imagen: 'userProfile.png',
    color: '#3c3c3c',
    porcentaje: 0,
    pacto: '',
    cod_partido: '',
    region: 'Unknown',
    electo: false,
    comuna: 'Unknown',
  }

  if (data.length === 0) {
    return (
      <Card title={title} selector={selection}>
        <div className="max-h-[330px] overflow-y-auto">
          <GridTable>
            {Array.from({ length: 6 }).map((_, index) => (
              <React.Fragment key={index}>
                <GridCell className="flex items-center gap-3 pl-2">
                  <FullCandidateBadge
                    electo={fakeItem}
                    size="big"
                    winner={fakeItem.electo}
                    isColored={false}
                    isBlank
                  />
                </GridCell>
                <GridCell className="text-left text-[12px] font-medium translate-x-1 xl:translate-x-0">
                  <Skeleton className="w-[80%] h-3" />
                </GridCell>
                <GridCell className="text-right font-medium text-[12px] translate-x-3 xl:translate-x-0">
                  0.0
                </GridCell>
                <GridCell className="text-right flex justify-end translate-x-1 xl:translate-x-0">
                  <Progress
                    value={0}
                    className="h-4 ml-[6px] w-[80%] bg-[#EEEEF0] rounded-tr-[3px] rounded-br-[3px]"
                    indicatorColor={fakeItem.color}
                  />
                </GridCell>
              </React.Fragment>
            ))}
          </GridTable>
        </div>
      </Card>
    )
  }
  return (
    <Card title={title} selector={selection}>
      <div className="max-h-[330px] overflow-y-auto">
        <GridTable>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <GridCell className="flex items-center gap-3 pl-2">
                <FullCandidateBadge
                  electo={item}
                  size="big"
                  winner={item.electo}
                  isColored={false}
                />
              </GridCell>
              <GridCell className="text-left text-[12px] font-medium translate-x-1 xl:translate-x-0">
                {item.comuna}
              </GridCell>
              <GridCell className="text-right font-medium text-[12px] translate-x-3 xl:translate-x-0">
                {numberToPercent(item.porcentaje, 1, true, false)}
              </GridCell>
              <GridCell className="text-right flex justify-end translate-x-1 xl:translate-x-0">
                <Progress
                  value={Number(item.porcentaje.toString().replace(',', '.'))}
                  className="h-4 ml-[6px] w-[80%] bg-[#EEEEF0] rounded-tr-[3px] rounded-br-[3px]"
                  indicatorColor={item.color}
                />
              </GridCell>
            </React.Fragment>
          ))}
        </GridTable>
      </div>
    </Card>
  )
}

const GridTable = styled.div`
  display: grid;
  grid-template-columns: 6fr 2.5fr 1fr 2fr;
  padding-right: 10px;
`

const GridCell = styled.div`
  color: ${colors.blackDch};
  padding-bottom: 10px;
`
