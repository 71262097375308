import { useNavigate } from 'react-router-dom'
import type { Candidato } from '@/components/Web/ElectosTab'
import ElectosTab from '@/components/Web/ElectosTab'
import useTerritorios, { regiones } from '@/stores/useTerritorios'
import useRouting from '@/stores/useRouting'
import useElectionData from '@/stores/useElectionData'
import { useEffect, useMemo, useState } from 'react'
import { getTerritorioColorDictSimple, jointCandidatosColor } from '@/lib/dataProcess'
import { ElectionType } from '@/types/Elections'
import { PACTOS } from '@/constants/pactos'
import RegionSelector from '@/components/RegionSelector'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Search, X } from 'lucide-react'
import { MonoLabel } from '../ParticipationBanner'

export default function AElectosTab() {
  const { currentRegion } = useRouting()
  const [filteredData, setFilteredData] = useState<Candidato[]>([])
  const [isSearch, setIsSearch] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const region = regiones.find((region) => region.codigo === currentRegion)
  const {
    files: { alcaldes },
  } = useElectionData()

  const navigate = useNavigate()

  const orderedAlcaldes = useMemo(
    () =>
      alcaldes
        ? alcaldes
            .filter((c) => c.cod_region.toString() === (currentRegion ?? '').toString())
            .sort((a, b) => b.porcentaje - a.porcentaje)
        : [],
    [alcaldes, currentRegion]
  )

  const processedData = useMemo(() => {
    if (orderedAlcaldes.length === 0) {
      return []
    }
    return jointCandidatosColor(orderedAlcaldes, ElectionType.ALCALDES).map((electo, i) => ({
      ...electo,
      pacto: PACTOS[ElectionType.ALCALDES][electo.cod_pacto]?.name || electo.cod_pacto,
      order: i + 1,
      imagen: electo.imagen || '/userProfile.png',
      region: region?.nombreCorto || region?.nombre || '',
      comuna: electo.comuna,
    }))
  }, [orderedAlcaldes, region?.nombre, region?.nombreCorto])

  const { setComunaColor } = useTerritorios()
  const colorDict = useMemo(
    () => getTerritorioColorDictSimple(alcaldes ?? [], ElectionType.ALCALDES),
    [alcaldes]
  )

  useEffect(() => {
    setComunaColor(colorDict)
  }, [colorDict, setComunaColor])

  const handleRegion = (region: string) => {
    navigate(`/${ElectionType.ALCALDES}/territorio/${region}`)
  }

  useEffect(() => {
    if (searchValue !== '') {
      const filtered = processedData.filter((c) =>
        c.nombre.toLowerCase().includes(searchValue.toLowerCase())
      )
      setFilteredData(filtered)
    } else {
      setFilteredData(processedData)
    }
  }, [searchValue, processedData])

  return (
    <ElectosTab
      selection={
        <div className="w-full">
          <RegionSelector
            className="hidden xl:flex xl:w-[50%]"
            onClick={handleRegion}
            value={String(currentRegion)}
          />
          {isSearch ? (
            <div className="relative w-full h-[27px] mt-1">
              <Search size={12} className="scale-x-[-1] left-2 absolute top-1/2 -translate-y-1/2" />
              <Input
                className="border-none shadow-none my-1 pl-7 font-mono uppercase text-[12px] h-7"
                placeholder="Buscar por nombre"
                autoFocus
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <X
                size={12}
                className="right-2 absolute top-1/2 -translate-y-1/2 cursor-pointer"
                onClick={() => {
                  setIsSearch(false)
                  setSearchValue('')
                }}
              />
            </div>
          ) : (
            <div className="flex justify-between mt-1 items-center w-full h-[27px]">
              <MonoLabel className="text-[11px] font-light text-gray3 uppercase">
                Candidatos {processedData.length} / SE ELIGEN {region?.comunas.length}
              </MonoLabel>

              <Button
                variant={'ghost'}
                className="gap-1"
                size={'ghostSmall'}
                onClick={() => setIsSearch(true)}
              >
                <Search size={12} className="scale-x-[-1] text-gray3" />
                <MonoLabel className="font-light text-gray3 uppercase">Buscar</MonoLabel>
              </Button>
            </div>
          )}
        </div>
      }
      data={filteredData}
      title={'Candidatos por Región'}
    />
  )
}
