import Ganadores, { type Electo } from '@/components/Web/GORE/Ganadores'
import { PACTOS_GORE } from '@/constants'
import { PARTIDOS } from '@/constants/pactos'
import useElectionData from '@/stores/useElectionData'
import { regiones } from '@/stores/useTerritorios'
import { formatFullName } from '@/helpers/formatters'
import { useMemo } from 'react'
import { CandidatoGore } from '@/types/Files'

const GOREOrder: { [key: number]: number } = {
  15: 7,
  1: 8,
  2: 4, //
  3: 9,
  4: 6, //
  5: 2, //
  13: 1, //
  6: 10,
  7: 11,
  16: 12,
  8: 3, //
  9: 5, //
  14: 13,
  10: 14,
  11: 15,
  12: 16,
}

function generateElectoData(electo: CandidatoGore) {
  const pacto = PACTOS_GORE[electo.cod_pacto]
  const region = regiones.find((r) => r.codigo.toString() === electo.cod_region.toString())
  return {
    imagen: electo.imagen || '/userProfile.png',
    nombre: formatFullName(electo.nombre) || '',
    porcentaje: electo.porcentaje || 0,
    pacto: pacto?.name || '',
    sigla_partido: PARTIDOS[electo.cod_partido]?.initials || '',
    region: region?.nombreCorto || region?.nombre || electo.region,
    color: pacto?.color || '',
    order: GOREOrder[Number(region?.codigo)] || 0,
    cod_region: Number(region?.codigo || electo.cod_region),
  }
}

export default function GGanadores() {
  const {
    files: { gore },
  } = useElectionData()

  const dataElectos = useMemo(() => {
    const electos = gore ? gore.filter((candidato) => candidato.electo) : []
    const data = electos.map((electo) => generateElectoData(electo))
    return data.sort((a, b) => a.order - b.order)
  }, [gore])

  const dataSegundaVuelta = useMemo(() => {
    const vanSegunda = gore ? gore.filter((candidato) => candidato.segunda_vuelta) : []
    const candidatosSegunda = vanSegunda.map((candidato) => generateElectoData(candidato))
    const data = candidatosSegunda.reduce(
      (acc: { region: string; electos: Electo[]; order: number }[], electo) => {
        const region = acc.find((r) => r.region === electo.region)
        if (region) {
          region.electos?.push(electo)
        } else {
          acc.push({
            region: electo.region,
            electos: [electo],
            order: GOREOrder[Number(electo.cod_region)],
          })
        }
        return acc
      },
      []
    )
    return data.sort((a, b) => a.order - b.order)
  }, [gore])

  return <Ganadores electos={dataElectos} segunda_vuelta={dataSegundaVuelta} />
}
