import Comparacion, { type Data } from '@/components/MagicWall/Comparacion'
import useElectionData from '@/stores/useElectionData'
import { getResultsByPacto } from '@/lib/dataProcess'
import { getResultsByPacto2021AG } from '@/lib/dataProcess2021'
import { buildComparacionData } from '@/lib/buildDataViews'
import { ElectionType } from '@/types/Elections'
import { useMemo } from 'react'

export default function AComparacion() {
  const {
    files: { alcaldes },
    comparador: { alcaldes: alcaldes2021 },
  } = useElectionData()

  const data: Data[] = useMemo(() => {
    const resultsByPacto2024 = getResultsByPacto(alcaldes || [])
    const resultsByPacto2021 = alcaldes2021 ? getResultsByPacto2021AG(alcaldes2021) : {}

    return buildComparacionData(
      resultsByPacto2024,
      resultsByPacto2021,
      ElectionType.ALCALDES,
      false
    )
  }, [alcaldes, alcaldes2021])

  return <Comparacion data={data} verticalSeparatorBefore="Otros" />
}
