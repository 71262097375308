import { colors } from '@/constants'
import { numberToPercent, numberWithDots } from '@/helpers/formatters'
import styled from 'styled-components'
import useElectionData from '@/stores/useElectionData'
import useRouting from '@/stores/useRouting'

type Participacion = {
  porcentaje: number
  votos: number
  escrutado: number
  hora: string
}

export default function Participacion() {
  const { updated_at, participation } = useElectionData()
  const { currentElection } = useRouting()

  const data = participation[currentElection as keyof typeof participation] || {
    porcentaje_mesas_escrutadas: 0,
    votos: 0,
    votos_blancos: 0,
    votos_nulos: 0,
    participacion_porcentual: 0,
    inscritos: 0,
    resultados_comuna: {},
  }

  return (
    <Wrapper>
      <WrapperText>
        Participación <BoldText>{numberToPercent(data?.participacion_porcentual ?? 0, 1)}</BoldText>
      </WrapperText>
      <WrapperText>
        Votos <BoldText>{numberWithDots(data?.votos ?? 0)}</BoldText>
      </WrapperText>
      <WrapperText>
        Escrutado el{' '}
        <BoldText>{numberToPercent(data?.porcentaje_mesas_escrutadas ?? 0, 1)}</BoldText>a las{' '}
        <BoldText>
          {updated_at
            ? new Date(updated_at).toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
              })
            : '00:00'}{' '}
          hrs
        </BoldText>
      </WrapperText>
    </Wrapper>
  )
}

const Wrapper = styled.div.attrs({
  className:
    'mgw:text-[28px] mgw:mt-[-0.5rem] mgw:gap-[35px] gap-x-[30px] mt-[-0.5rem] text-[20px]',
})`
  display: flex;
  flex-wrap: wrap;
  line-height: 33.88px;
  letter-spacing: 0.03rem;
  color: ${colors.blackDch};
`

const WrapperText = styled.div.attrs({
  className: 'mgw:gap-3 gap-2',
})`
  display: flex;
`

const BoldText = styled.div`
  font-weight: 700;
`
