import { useEffect, useState } from 'react'

export default function useRefDimensions({ ref }: { ref: React.RefObject<HTMLDivElement> }) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const refDimensions = ref.current?.getBoundingClientRect()
    const updateSize = () => {
      if (refDimensions) {
        setDimensions({
          width: refDimensions.width,
          height: refDimensions.height,
        })
      }
    }
    updateSize()
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [ref, ref.current?.offsetHeight, ref.current?.offsetWidth])

  return dimensions
}
