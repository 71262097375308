export enum ElectionType {
  ALCALDES = 'alcaldes',
  CONCEJALES = 'concejales',
  CORE = 'core',
  GORE = 'gore',
}

export type ElectionParams = {
  [key in ElectionType]: {
    name: string
    default: string
    menuIcon?: React.ReactNode
  }
}

export type PartidoType = {
  initials: string
  name: string
  shortName: string
}

export type PactoType = {
  initials: string
  name: string
  shortName?: string
  color: string
  darkColor?: string
  order: number
  partidos: PartidoType[]
  subpactos?: {
    name: string
    initialPartidos: string[]
  }[]
}

export type PactosElectionType = {
  [x: string]: PactoType
}

export type PactosType = {
  [key in ElectionType]: PactosElectionType
}

export type PactosListType = {
  [key in ElectionType]: string[]
}

export type PartidosType = {
  [key: string]: PartidoType
}
