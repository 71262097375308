import Ranking, { type Data } from '@/components/MagicWall/Ranking'
import { PACTOS } from '@/constants'
import { jointCandidatosColor } from '@/lib/dataProcess'
import useElectionData from '@/stores/useElectionData'

import { regiones } from '@/stores/useTerritorios'
import { ElectionType } from '@/types/Elections'
import { useMemo } from 'react'

export default function GRanking() {
  const {
    files: { gore },
  } = useElectionData()

  // For ranking of gore we also add those who are in segunda vuelta
  const electos = useMemo(() => {
    return gore ? gore.filter((candidato) => candidato.electo || candidato.segunda_vuelta) : []
  }, [gore])

  const data: Data[] = useMemo(() => {
    if (electos.length === 0) {
      return []
    }
    return jointCandidatosColor(electos, ElectionType.GORE).map((electo) => ({
      ...electo,
      partido: electo.cod_partido,
      region:
        regiones.find((r) => r.codigo.toString() === electo.cod_region.toString())?.nombreCorto ||
        electo.region,
      pacto: PACTOS[ElectionType.GORE][electo.cod_pacto]?.shortName || electo?.cod_pacto || '',
      imagen: electo.imagen || '/userProfile.png',
      color_texto: PACTOS[ElectionType.GORE][electo.cod_pacto]?.darkColor || '',
    }))
  }, [electos])

  return <Ranking data={data} />
}
