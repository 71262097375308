import MPactos, { PactoResult } from '@/components/MagicWall/Pactos'
import { getResultsByPacto } from '@/lib/dataProcess'
import { buildPactosViewData } from '@/lib/buildDataViews'
import styled from 'styled-components'
import useElectionData from '@/stores/useElectionData'
import { useMemo } from 'react'
import { ElectionType } from '@/types/Elections'
import { colors, TOTAL_ELECTOS } from '@/constants'

export default function Pactos() {
  const {
    files: { concejales },
  } = useElectionData()

  const data: PactoResult[] = useMemo(() => {
    const resultByPactos = getResultsByPacto(concejales || [])
    return buildPactosViewData(resultByPactos, ElectionType.CONCEJALES)
  }, [concejales])

  return (
    <MPactos
      className="mgw:py-14 mgw:px-6 py-12 px-5"
      election="Concejales"
      data={data}
      verticalSeparatorBefore="Otros"
      numberOfPartidosbyColumn={7}
      minWidthPactos="110px"
      minWidthPartidos="100px"
    >
      <Graph className="w-full flex gap-0 h-[130px]">
        {data.map((pacto) => (
          <BackgroundPacto
            key={`${pacto.abreviatura}-background`}
            color={pacto.color}
            percentage={(pacto.electos / TOTAL_ELECTOS[ElectionType.CONCEJALES]) * 100}
          />
        ))}
      </Graph>
    </MPactos>
  )
}

const BackgroundPacto = styled.div<{ color: string; percentage: number }>`
  background-color: ${({ color }) => color};
  width: ${({ percentage }) => percentage}%;
`

const Graph = styled.div`
  background-color: ${colors.gray2Dch};
`
