import styled from 'styled-components'
import SubMenu from './SubMenu/SubMenu'
import { colors } from '@/constants'
import ChevronLeft from '@/assets/chevronLeft.svg'
import useMagicFrame from '@/stores/useMagicFrame'

export default function Expand({ open, height }: { open?: boolean; height?: string }) {
  const { setSubmenuOpen } = useMagicFrame()
  return (
    <Wrapper open={open}>
      {open ? (
        <SubMenu />
      ) : (
        <ExpandButton height={height} onClick={() => setSubmenuOpen(true)} direction="left" />
      )}
    </Wrapper>
  )
}

export function ExpandButton({
  height,
  className,
  direction = 'right',
  onClick,
}: {
  height?: string
  className?: string
  direction?: 'left' | 'right'
  onClick?: () => void
}) {
  return (
    <ExpandButtonWrapper
      onClick={onClick}
      height={height}
      className={className}
      direction={direction}
    >
      <ChevronLeft />
    </ExpandButtonWrapper>
  )
}

const ExpandButtonWrapper = styled.button<{ height?: string; direction: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: ${colors.frame};
  padding: 4px;
  > svg {
    transform: rotate(${({ direction }) => (direction === 'left' ? '0' : '180deg')});
    width: 18px;
  }
`

const Wrapper = styled.div<{ height?: string; open?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(-100%, ${({ open }) => (open ? 'calc(50vh - 50%)' : '0px')});
  top: ${({ open }) => open && '0'};
`
