import { PACTOS_CORE } from '@/constants/pactos'
import useElectionData from '@/stores/useElectionData'
import useRouting from '@/stores/useRouting'
import useTerritorios, { comunas } from '@/stores/useTerritorios'
import { ElectionType } from '@/types/Elections'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import ElectosVersus, { type Data } from '@/components/Web/ElectosVersus'
import ProvinciaSelector from '@/components/Web/ProvinciaSelector'
import { Search, X } from 'lucide-react'
import { MonoLabel } from '../ParticipationBanner'
import ElectosTab from '@/components/Web/ElectosTab'
import { compareCORECircunscripcion } from '@/helpers/formatters'

export default function CoreCandidatoProvincia() {
  const { currentCircunscripcion } = useRouting()
  const { setComunaColor } = useTerritorios()
  const {
    files: { core },
    static: { escanos_core },
  } = useElectionData()
  const [isSearch, setIsSearch] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredData, setFilteredData] = useState<Data[]>([])

  const isSearching = useMemo(() => searchValue !== '', [searchValue])
  const navigate = useNavigate()

  const processedData = useMemo(() => {
    if (!core || !currentCircunscripcion) return []

    const electos = core.filter(
      (c) =>
        compareCORECircunscripcion(currentCircunscripcion, c.cod_circunscripcion || '') && c.electo
    )

    const comunasCircunscripcion = comunas
      .filter((comuna) =>
        compareCORECircunscripcion(currentCircunscripcion, comuna.circunscripcionProvincial)
      )
      .map((comuna) => comuna.codigo)

    const comunasColor: Record<number, string> = {}
    comunasCircunscripcion.forEach((comuna) => {
      const orderedByVotos = electos.sort(
        (a, b) => b?.resultados_comuna[comuna]?.votos - a.resultados_comuna[comuna]?.votos
      )
      comunasColor[comuna] = PACTOS_CORE[orderedByVotos[0]?.cod_pacto]?.color || ''
    })
    setComunaColor(comunasColor)

    return electos
      .sort((a, b) => b.porcentaje - a.porcentaje)
      .map((electo, index) => ({
        ...electo,
        pacto: PACTOS_CORE[electo.cod_pacto]?.name || electo?.cod_pacto,
        color: PACTOS_CORE[electo.cod_pacto]?.color || '',
        imagen: electo?.imagen || '/userProfile.png',
        order: index,
        comuna: '',
        region: '',
      }))
  }, [core, currentCircunscripcion, setComunaColor])

  useEffect(() => {
    if (searchValue !== '') {
      const filtered = processedData.filter((c) =>
        c.nombre.toLowerCase().includes(searchValue.toLowerCase())
      )
      setFilteredData(filtered)
    } else {
      setFilteredData(processedData)
    }
  }, [searchValue, processedData])

  const handleRegion = (region: string) => {
    navigate(`/${ElectionType.CORE}/territorio/${region}`)
  }

  return (
    <div className="h-full">
      <ElectosTab
        title={`Electos por Provincia`}
        data={filteredData}
        selection={
          <div className="w-full xl:mt-[-5px] ">
            <div className="flex gap-3 w-full">
              <ProvinciaSelector
                className="w-[50%] xl:flex hidden"
                onClick={handleRegion}
                value={String(currentCircunscripcion)}
              />
            </div>
            {isSearch ? (
              <div className="relative w-full">
                <Search
                  size={12}
                  className="scale-x-[-1] left-2 absolute top-1/2 -translate-y-1/2"
                />
                <Input
                  className="border-none shadow-none mt-1 pl-7 font-mono uppercase text-[12px] h-7"
                  placeholder="Buscar por nombre"
                  autoFocus
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <X
                  size={13}
                  className="right-2 absolute top-1/2 -translate-y-1/2 cursor-pointer hover:bg-gray-100 rounded-[2px]"
                  onClick={() => {
                    setIsSearch(false)
                    setSearchValue('')
                  }}
                />
              </div>
            ) : (
              <div className="flex justify-between mt-2 items-center">
                <MonoLabel className="text-[11px] font-light text-gray3 uppercase">
                  SE ELIGEN {processedData.length}
                </MonoLabel>

                <Button
                  variant={'ghost'}
                  className="gap-1"
                  size={'ghostSmall'}
                  onClick={() => setIsSearch(true)}
                >
                  <Search size={12} className="scale-x-[-1] text-gray3" />
                  <MonoLabel className="font-light text-gray3 uppercase">Buscar</MonoLabel>
                </Button>
              </div>
            )}
          </div>
        }
      />
    </div>
  )
}
