import ElectosPacto from '@/components/MagicWall/ElectosPacto'
import useElectionData from '@/stores/useElectionData'
import useRouting from '@/stores/useRouting'
import { getRegionResultsByPactoConcejales } from '@/lib/dataProcess'
import useTerritorios, { regiones } from '@/stores/useTerritorios'
import { useMemo } from 'react'
import { ElectionType } from '@/types/Elections'
import { buildRegionResultsByPacto } from '@/lib/buildDataViews'
import { PACTOS_CONCEJALES } from '@/constants'

const COMUNAS_DIFFER_ESCANOS = {
  13125: 8, // Quilicura
  13302: 6, // Lampa
  4104: 5, // La Higuera
  // 12202: 12, // La antartica
}

export default function CJElectosRegion() {
  const { currentRegion } = useRouting()
  const { setComunaColor } = useTerritorios()
  const {
    files: { concejales },
    comparador: { concejales: concejales2021 },
    static: { escanos_concejales },
  } = useElectionData()

  const data = useMemo(() => {
    if (!concejales2021 || !concejales || !currentRegion || !escanos_concejales) return []
    const region = regiones.find((r) => r.codigo === Number(currentRegion))
    const { countElectosByPacto, countElectosByPacto2021 } = getRegionResultsByPactoConcejales(
      concejales,
      concejales2021,
      region?.comunas || []
    )

    let totalElectos2021 = 0
    const totalElectos2024 = region?.comunas.reduce((acc, comuna) => {
      if (Object.keys(COMUNAS_DIFFER_ESCANOS).includes(comuna.toString())) {
        totalElectos2021 += COMUNAS_DIFFER_ESCANOS[comuna as keyof typeof COMUNAS_DIFFER_ESCANOS]
      } else {
        totalElectos2021 += escanos_concejales[comuna]
      }
      return acc + escanos_concejales[comuna]
    }, 0)

    const data = buildRegionResultsByPacto(
      countElectosByPacto,
      countElectosByPacto2021,
      totalElectos2024 || 0,
      ElectionType.CONCEJALES,
      totalElectos2021
    )

    const comunasColor: Record<number, string> = {}
    region?.comunas.forEach((comuna) => {
      const candidatosComuna = concejales.filter(
        (candidato) => Number(candidato.cod_comuna) === comuna && candidato.electo
      )
      const orderedByVotos = candidatosComuna.sort((a, b) => b.votos - a.votos)
      comunasColor[comuna] = PACTOS_CONCEJALES[orderedByVotos[0]?.cod_pacto]?.color || ''
    })

    setComunaColor(comunasColor)

    return data.sort((a, b) => b.electos_2024 - a.electos_2024)
  }, [concejales, concejales2021, currentRegion, escanos_concejales, setComunaColor])

  return <ElectosPacto data={data} pageSize={8} />
}
