import { colors } from '@/constants'
import useMagicFrame from '@/stores/useMagicFrame'
import useRouting from '@/stores/useRouting'
import styled from 'styled-components'
import Expand from './Expand'

export const BUTTON_HEIGHT = '75px'

export type MagicButtonProps = {
  children: React.ReactNode
  selected?: boolean
  onClick?: () => void
}

export default function MagicButton({ children, selected, onClick }: MagicButtonProps) {
  const { isSubmenuOpen } = useMagicFrame()
  const { isInTerritorio, isMagicChileEnDatos } = useRouting()
  const canOpenSubmenu = selected && (isInTerritorio || isMagicChileEnDatos)

  return (
    <MagicButtonWrapper>
      {canOpenSubmenu && <Expand open={isSubmenuOpen} height={BUTTON_HEIGHT} />}
      <Button onClick={onClick} selected={selected}>
        {children}
      </Button>
    </MagicButtonWrapper>
  )
}

const MagicButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const Button = styled.button<{ selected?: boolean; height?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  font-weight: 500;
  font-size: 28px;
  padding: 18px;
  color: ${({ selected }) => (selected ? colors.frameFontDark : colors.frameFontLight)};
  background-color: ${({ selected }) => (selected ? colors.background : colors.frame)};
  height: ${({ height }) => height || BUTTON_HEIGHT};
`
