import Ranking, { type Data } from '@/components/MagicWall/Ranking'
import useElectionData from '@/stores/useElectionData'
import { jointCandidatosColor } from '@/lib/dataProcess'
import { useMemo } from 'react'
import { PACTOS } from '@/constants/pactos'
import { ElectionType } from '@/types/Elections'

const COMUNAS_ONE_CANDIDATE = [5706, 15102, 10204]

export default function ARanking() {
  const {
    files: { alcaldes },
  } = useElectionData()

  const electos = useMemo(() => {
    return alcaldes
      ? alcaldes.filter(
          (candidato) => candidato.electo && !COMUNAS_ONE_CANDIDATE.includes(candidato.cod_comuna)
        )
      : []
  }, [alcaldes])

  const data: Data[] = useMemo(() => {
    if (electos.length === 0) {
      return []
    }
    return jointCandidatosColor(electos, ElectionType.ALCALDES).map((electo) => {
      return {
        ...electo,
        partido: electo.cod_partido,
        pacto: PACTOS[ElectionType.ALCALDES][electo.cod_pacto]?.shortName || electo?.cod_pacto,
        imagen: electo.imagen || '/userProfile.png',
        color_texto: PACTOS[ElectionType.ALCALDES][electo.cod_pacto]?.darkColor || '',
      }
    })
  }, [electos])

  return <Ranking data={data} />
}
