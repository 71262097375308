import { useNavigate } from 'react-router-dom'

import ElectosVersus from '@/components/Web/ElectosVersus'
import useRouting from '@/stores/useRouting'
import { comunas, regiones } from '@/stores/useTerritorios'
import useElectionData from '@/stores/useElectionData'
import { useEffect, useMemo, useState } from 'react'
import { getPartidoDisplayName, jointCandidatosColor } from '@/lib/dataProcess'
import useTerritorios from '@/stores/useTerritorios'
import { PACTO_COLORS } from '@/constants/pactos'
import RegionSelector from '@/components/RegionSelector'
import ComunaSelector from '@/components/ComunaSelector'
import { ElectionType } from '@/types/Elections'
import { MonoLabel } from '../ParticipationBanner'
import { Search, X } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Data } from '@/components/Web/ElectosVersus'
import { PACTOS_ALCALDES } from '@/constants/pactos'

export default function AElectosVersus() {
  const { currentComuna, currentRegion } = useRouting()
  const { files } = useElectionData()
  const { alcaldes } = files
  const { comunasColorDict, setComunaColor } = useTerritorios()
  const [isSearch, setIsSearch] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredData, setFilteredData] = useState<Data[]>([])
  const navigate = useNavigate()

  const alcaldeElecto = useMemo(() => {
    return alcaldes?.find(
      (c) => c.cod_comuna.toString() === (currentComuna ?? '').toString() && c.electo
    )
  }, [alcaldes, currentComuna])

  const orderedAlcaldes = useMemo(
    () =>
      alcaldes
        ? alcaldes
            .filter((c) => c.cod_comuna.toString() === (currentComuna ?? '').toString())
            .sort((a, b) => b.porcentaje - a.porcentaje)
        : [],
    [alcaldes, currentComuna]
  )

  const processedData = useMemo(() => {
    if (orderedAlcaldes.length === 0) {
      return []
    }
    return jointCandidatosColor(orderedAlcaldes, ElectionType.ALCALDES).map((c, i) => ({
      ...c,
      order: i + 1,
      pacto: PACTOS_ALCALDES[c.cod_pacto as string].name,
      imagen: c.imagen || '/userProfile.png',
      partido: getPartidoDisplayName(c.partido),
    }))
  }, [orderedAlcaldes])

  useEffect(() => {
    if (!comunasColorDict[currentComuna as number]) {
      setComunaColor({
        ...comunasColorDict,
        [currentComuna as number]: PACTO_COLORS[alcaldeElecto?.cod_pacto as string] || '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderedAlcaldes, alcaldeElecto, currentComuna])

  useEffect(() => {
    if (searchValue !== '') {
      const filtered = processedData.filter((c) =>
        c.nombre.toLowerCase().includes(searchValue.toLowerCase())
      )
      setFilteredData(filtered)
    } else {
      setFilteredData(processedData)
    }
  }, [searchValue, processedData])

  const isSearching = useMemo(() => searchValue !== '', [searchValue])

  const handleRegion = (region: string) => {
    const comunaRegion = regiones.find(
      ({ codigo }) => codigo.toString() === region.toString()
    )?.comunaDefecto
    navigate(`/${ElectionType.ALCALDES}/territorio/${region}/${comunaRegion}`)
  }

  const handleComuna = (comuna: string) => {
    navigate(`/${ElectionType.ALCALDES}/territorio/${currentRegion}/${comuna}`)
  }

  return (
    <div className="h-full">
      <ElectosVersus
        title={`Electos por Comuna`}
        data={filteredData}
        isSearching={isSearching}
        selection={
          <div className="w-full xl:mt-[-5px] ">
            <div className="flex gap-3 w-full">
              <RegionSelector
                className="w-[100%] xl:flex hidden"
                onClick={handleRegion}
                value={String(currentRegion)}
              />
              <ComunaSelector
                className="w-[100%] hidden xl:flex"
                region={currentRegion}
                onClick={handleComuna}
                value={String(currentComuna)}
              />
              <ComunaSelector
                className="flex xl:hidden w-[100%] border-none shadow-none underline underline-offset-2 focus:ring-0 px-0 h-7 -mb-2"
                region={currentRegion}
                onClick={handleComuna}
                value={String(currentComuna)}
                showIcon={false}
              />
            </div>
            {isSearch ? (
              <div className="relative w-full">
                <Search
                  size={12}
                  className="scale-x-[-1] left-2 absolute top-1/2 -translate-y-1/2"
                />
                <Input
                  className="border-none shadow-none mt-1 pl-7 font-mono uppercase text-[12px] h-7"
                  placeholder="Buscar por nombre"
                  autoFocus
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <X
                  size={12}
                  className="right-2 absolute top-1/2 -translate-y-1/2"
                  onClick={() => {
                    setIsSearch(false)
                    setSearchValue('')
                  }}
                />
              </div>
            ) : (
              <div className="flex justify-between mt-2 items-center">
                <MonoLabel className="text-[11px] font-light text-gray3 uppercase">
                  Candidatos {processedData.length} / SE ELIGEN 1
                </MonoLabel>

                <Button
                  variant={'ghost'}
                  className="gap-1"
                  size={'ghostSmall'}
                  onClick={() => setIsSearch(true)}
                >
                  <Search size={12} className="scale-x-[-1] text-gray3" />
                  <MonoLabel className="font-light text-gray3 uppercase">Buscar</MonoLabel>
                </Button>
              </div>
            )}
          </div>
        }
      />
    </div>
  )
}
