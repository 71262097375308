import { Router } from '@/app/Router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'topojson' // To get the namespaces
import '@/lib/fonts'

const queryClient = new QueryClient()

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  )
}

export default App
