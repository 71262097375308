import { colors } from '@/constants'
import { slide as BurgerMenu } from 'react-burger-menu'
import styled from 'styled-components'
import HamburgerSVG from '@/assets/hamburger.svg'

const years = ['2017', '2013', '2009', '2005', '2001', '1999', '1997', '1993', '1989']

export const Hamburger = ({ className }: { className?: string }) => {
  return (
    <Wrapper className={className}>
      <BurgerMenu
        customBurgerIcon={<BurgerIcon text={'2024'} />}
        id="hamburger"
        isOpen={false}
        // left
      >
        <MenuWrapper>
          <Menu>
            <ListTitle>Elecciones</ListTitle>
            <Events>
              <Table>
                <a href="https://2023.decidechile.cl" rel="noreferrer" target="_blank">
                  <span>2023</span>
                </a>
                <a href="https://2022.decidechile.cl" rel="noreferrer" target="_blank">
                  <span>2022</span>
                </a>
                <a href="https://2021.decidechile.cl" rel="noreferrer" target="_blank">
                  <span>2021</span>
                </a>
                <a href="https://2020.decidechile.cl" rel="noreferrer" target="_blank">
                  <span>2020</span>
                </a>
                {years.map((eventKey) => (
                  <a
                    href={`https://historico.decidechile.cl/#/ev/${eventKey}`}
                    key={eventKey}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <span>{eventKey}</span>
                  </a>
                ))}
              </Table>
            </Events>
            <Footer href="https://www.unholster.com/" target="_blank">
              <img alt="unholster" height="64px" src="unholster-white.svg" width="172px" />
            </Footer>
          </Menu>
        </MenuWrapper>
      </BurgerMenu>
    </Wrapper>
  )
}

const MenuWrapper = styled.div`
  height: 100%;
  top: 0;
  left: 0;
`

const Menu = styled.div`
  display: grid;
  grid: min-content auto min-content / 1fr;
  font-size: 10px;
  padding: 22px;
  gap: 10px;
  height: 100%;
  background-color: #2150e8;
  color: white;
`

const BurgerIcon = ({ text }: { text: string }) => (
  <BurgerIconStyled>
    <HamburgerSVG className="h-[20px] fill-[#3C3C3C]" />
    <span>{text}</span>
  </BurgerIconStyled>
)

const BurgerIconStyled = styled.div`
  display: grid;
  grid: 1fr / 1fr 1fr;
  gap: 30px;
  align-items: center;
  font-size: 23px;
  font-weight: bold;
  color: ${colors.blackDch};
`

const ListTitle = styled.div`
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
`

const Footer = styled.a`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`

const Events = styled.div`
  padding-left: 24px;
  a,
  span {
    color: #dddddd;
    padding: 12px;

    &:hover {
      color: #ffffff;
    }
  }
`

const Table = styled.div`
  display: grid;
  font-size: 12px;
  grid: 1fr / 1fr 1fr;
  align-items: start;
  gap: 5px;
`

const Wrapper = styled.div`
  font-family: 'JetBrains Mono';
  .bm-burger-button {
    position: relative;
  }
  .bm-cross {
    background-color: #ffffff;
  }
  .bm-menu-wrap {
    top: 0;
    left: 0;
  }

  .bm-overlay {
    left: 0;
    top: 0;
  }
`

export default Hamburger
