import React from 'react'
import { Button } from '../ui/button'
import { useNavigate } from 'react-router-dom'

export default function ErrorPage() {
  const navigate = useNavigate()

  return (
    <div className="flex-col justify-center items-center w-full flex gap-3 mt-28 xl:min-w-[867px]">
      <img src="unholster.png" alt="unholster logo" />
      <h2 className="text-negroDch text-[24px]">Página sin resultados</h2>
      <p className="text-negroDch text-[16px] font-light text-center">
        No pudimos acceder a la información que buscas en este momento.
      </p>
      <Button variant={'outline'} onClick={() => navigate(-1)}>
        Volver atrás
      </Button>
    </div>
  )
}
