import styled from 'styled-components'
import { ReactNode } from 'react'
import { colors } from '@/constants'
import Card from '@/components/MagicWall/Card'

type ICard = {
  title: string
  statusLabel?: string
  children: ReactNode
  currentPage: number
  setCurrentPage: (id: number) => void
  pageSize: number
  totalPages: number
  toggle?: ReactNode
  separatorColor?: string
}

export default function PaginatedCard({
  title,
  statusLabel,
  children,
  currentPage,
  setCurrentPage,
  totalPages,
  toggle,
  separatorColor = colors.gray3Dch,
}: ICard) {
  const handlePageChange = (pageIndex: number) => {
    setCurrentPage(pageIndex)
  }

  return (
    <Wrapper>
      <Card title={title} statusLabel={statusLabel} toggle={toggle} separatorColor={separatorColor}>
        {children}
      </Card>
      <PaginationWrapper>
        {Array.from({ length: totalPages }, (_, i) => (
          <PageTab
            className={totalPages === 1 ? 'invisible' : ''}
            key={i}
            $active={i === currentPage}
            onClick={() => handlePageChange(i)}
          />
        ))}
      </PaginationWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  /* min-width: 689px;
  max-width: 100%; */
  width: 100%;
`

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  right: -35px;
  top: 10px;
`

const PageTab = styled.div<{ $active: boolean }>`
  width: ${({ $active }) => ($active ? '27px' : '20px')};
  height: 73px;
  cursor: pointer;

  border-radius: 0 10px 10px 0;
  box-shadow: 1px 0px 0px 1px rgb(150, 150, 150);
  margin: 7px 0;

  background-color: ${({ $active }) => ($active ? colors.whiteDch2 : colors.grayTab)};
  transition: background-color 0.2s ease, width 0.1s;
`
