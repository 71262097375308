import styled from 'styled-components'
import { Fragment, ReactNode, useState } from 'react'
import { colors } from '@/constants'
import ToggleSwitch from './ToggleSwitch'
import Card from './Card'
import PactoInformation from './PactoInformation'
import { voteToMillionString } from '@/helpers/formatters'

type PartidoResult = {
  nombre_partido: string
  cod_partido: string
  electos: number
}

export type PactoResult = {
  nombre: string
  abreviatura: string
  color: string
  electos: number
  porcentaje: number
  votos: number
  orden: number
  electos_partido: PartidoResult[]
}

type Pactos = {
  election: string
  data: PactoResult[]
  verticalSeparatorBefore?: string
  children: ReactNode
  minWidthPactos: string
  minWidthPartidos: string
  numberOfPartidosbyColumn?: number
  className?: string
}

export function generateCircles(data: PactoResult[], totalCircles: number) {
  const circles: { color: string; key: string }[] = []
  data.forEach(({ color, electos, abreviatura }) => {
    for (let i = 0; i < electos; i++) {
      circles.push({ color, key: `${abreviatura}-${i.toString()}` })
    }
  })
  if (circles.length < totalCircles) {
    for (let i = circles.length; i < totalCircles; i++) {
      circles.push({ color: colors.gray2Dch, key: `empty-${i.toString()}` })
    }
  }
  return circles
}

export default function Pactos({
  children,
  election,
  data,
  verticalSeparatorBefore = '',
  minWidthPartidos,
  minWidthPactos,
  numberOfPartidosbyColumn = 6,
  className = '',
}: Pactos) {
  const [isPartidosView, setIsPartidosView] = useState(false)
  const handleChangeView = () => {
    setIsPartidosView((prev) => !prev)
  }
  const orderedData = data.sort((a, b) => a.orden - b.orden)
  const numberOfPactos = orderedData.length

  const sectionLabel = isPartidosView ? 'Partido' : 'Pacto'
  const title = `${election} Electos por ${sectionLabel}`

  return (
    <Card
      title={title}
      separatorColor="#CCCCCC"
      statusLabel={'PRELIMINAR'}
      toggle={
        <ToggleSwitch
          checkedLabel="PACTOS"
          uncheckedLabel="PARTIDOS"
          checked={isPartidosView}
          handleToggle={handleChangeView}
        />
      }
    >
      <Wrapper className={className}>
        {isPartidosView ? (
          <GridPactos cols={numberOfPactos} minWidthChild={minWidthPartidos}>
            {orderedData.map((pacto) => (
              <Fragment key={`partidos-${pacto.abreviatura}`}>
                {pacto.nombre === verticalSeparatorBefore && <VSeparatorPactos />}
                <PactoInformation color={pacto.color} electos={pacto.electos} name={pacto.nombre}>
                  <PartidosResult
                    cols={Math.ceil(pacto.electos_partido.length / numberOfPartidosbyColumn)}
                    rows={numberOfPartidosbyColumn}
                  >
                    {pacto.electos_partido.map((partido) => (
                      <Fragment key={partido.cod_partido}>
                        <div className="mgw:text-[25px] pr-1 text-[18px] font-semibold text-right">
                          {partido.electos}
                        </div>
                        <div className="mgw:text-[18px] pr-4 text-[14px] font-medium">
                          {partido.cod_partido}
                        </div>
                      </Fragment>
                    ))}
                  </PartidosResult>
                </PactoInformation>
              </Fragment>
            ))}
          </GridPactos>
        ) : (
          <div className="h-fit grid grid-row-[min-content-min-content] items-center mgw:gap-[40px] gap-[30px]">
            {children}
            <GridPactos cols={numberOfPactos} minWidthChild={minWidthPactos}>
              {orderedData.map((pacto) => (
                <Fragment key={`pactos-${pacto.abreviatura}`}>
                  {pacto.nombre === verticalSeparatorBefore && <VSeparatorPactos />}
                  <PactoInformation color={pacto.color} electos={pacto.electos} name={pacto.nombre}>
                    <PactoVotes>{voteToMillionString(pacto.votos)} votos</PactoVotes>
                  </PactoInformation>
                </Fragment>
              ))}
            </GridPactos>
          </div>
        )}
      </Wrapper>
    </Card>
  )
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`

const GridPactos = styled.div.attrs<{ cols: number; minWidthChild: string }>({
  className: 'mgw:gap-[15px] gap-[8px]',
})`
  display: grid;
  grid-template-columns:
    repeat(${({ cols }) => cols - 2}, minmax(${({ minWidthChild }) => minWidthChild}, 1fr))
    min-content repeat(2, minmax(${({ minWidthChild }) => minWidthChild}, 1fr));
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  width: 100%;
  height: fit-content;
`

const PartidosResult = styled.div.attrs<{ cols: number; rows: number }>({
  className: 'mgw:py-[12px] py-[8px]',
})`
  display: grid;
  grid-template-rows: repeat(${({ rows }) => rows}, 1fr);
  grid-template-columns: repeat(${({ cols }) => cols * 2}, min-content);
  height: 100%;
  width: 100%;
  color: ${colors.blackDch};
  column-gap: 2px;
  row-gap: 2px;
  align-items: center;
`

const VSeparatorPactos = styled.div`
  height: 100%;
  border-right: 2.5px solid ${colors.gray2Dch};
`

const PactoVotes = styled.div.attrs({
  className: 'mgw:text-[22px] text-[18px] mgw:py-[10px] py-[8px] min-h-[66px]',
})`
  color: ${colors.gray3};
  font-weight: 500;
  line-height: 23px;
`
