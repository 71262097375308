import styled from 'styled-components'
import { colors } from '@/constants/colors'
import { ReactNode } from 'react'

interface IfrButtonProps {
  selected?: boolean
  children: ReactNode
  onClick: () => void
}

export default function IfrButton({ selected = false, children, onClick }: IfrButtonProps) {
  return (
    <StyledBtn selected={selected} onClick={onClick}>
      {children}
    </StyledBtn>
  )
}

const StyledBtn = styled.button.attrs<{ selected: boolean }>({
  className:
    'h-[16px] sifr:h-[22px] ifr:h-[30px] rounded-[4px] ifr:rounded-[5px] text-[10px] sifr:text-[13px] ifr:text-[17px]',
})`
  background-color: ${(props) => (props.selected ? colors['blackDch'] : 'white')};
  color: ${(props) => (props.selected ? 'white' : colors['blackDch'])};
  width: 100%;
  border: 1px solid ${colors['blackDch']};
  font-weight: 700;
`
