import { ChileFeature } from '@/components/Map/types'
import MapBackground from '@/components/MapBackground'
import useMapSelected from '@/hooks/useMapSelected'
import useMagicFrame from '@/stores/useMagicFrame'
import useRouting from '@/stores/useRouting'
import { comunas } from '@/stores/useTerritorios'
import { ElectionType } from '@/types/Elections'
import { Outlet, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Layout from './Layout'

export default function LayoutTerritorio() {
  const { setSubmenuOpen } = useMagicFrame()
  const { currentPathname = '', currentElection } = useRouting()
  const navigate = useNavigate()
  const selected = useMapSelected()
  const territorioAttribute =
    currentElection === ElectionType.CORE ? 'circunscripcionProvincial' : 'region'

  const onClickTerritorio = (feature?: ChileFeature) => {
    const properties = feature?.properties
    const basePath = currentPathname.split('/').slice(0, 3).join('/')
    let navigatePath = currentPathname

    setSubmenuOpen(false)
    if (properties) {
      const comunaTerritorio = comunas.find((c) => c.codigo === properties.cod_comuna)?.[
        territorioAttribute
      ]
      navigatePath = `${basePath}/territorio/${comunaTerritorio}/${properties.cod_comuna}`
    }
    navigate(navigatePath)
  }

  return (
    <MapBackground
      selectionProperty="cod_comuna"
      selected={selected}
      onClick={onClickTerritorio}
      scale={[1, 0.8]}
      position="right-center"
      translate={[0, -50]}
    >
      <Layout>
        <DownRow>
          <Content>
            <Outlet />
          </Content>
          <OverpassMap />
        </DownRow>
      </Layout>
    </MapBackground>
  )
}

const DownRow = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(700px, 0.52fr) 0.48fr;
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: all;
`

const OverpassMap = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
`
