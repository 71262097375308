import useRouting from '@/stores/useRouting'
import useTerritorios, {
  circunscripcionesProvinciales,
  comunas,
  regiones,
} from '@/stores/useTerritorios'
import { ElectionType } from '@/types/Elections'
import MapOverlay from '@/components/MagicWall/MapOverlay'

export function useSelectedAdapter({
  comunas,
  focused = [],
}: {
  comunas: number[]
  focused?: number[]
}) {
  // TODO Replace with a more robust way to get the current path
  const { comunasColorDict } = useTerritorios()
  const selected = comunas.map((cut: number) => {
    return {
      cut,
      color: comunasColorDict[cut] || '#B1B1BB',
      element: <MapOverlay />,
      opacity: 1,
      focused: focused?.includes(cut),
    }
  })
  return selected
}

export default function useMapSelected() {
  const { selectedComunas: focusedComunas } = useTerritorios()
  const { currentElection } = useRouting()
  const territorioAttribute =
    currentElection === ElectionType.CORE ? 'circunscripcionProvincial' : 'region'
  const selectedComunasTerritorio = new Set(
    focusedComunas.map((comuna: number) => {
      const territorio = comunas.find((c) => c.codigo === comuna)?.[territorioAttribute]
      return territorio
    })
  )

  const territorioData =
    currentElection === ElectionType.CORE ? circunscripcionesProvinciales : regiones
  const comunasTerritorio = territorioData
    .filter((region) => selectedComunasTerritorio.has(region.codigo))
    .flatMap((region) => region.comunas)

  const selected = useSelectedAdapter({ comunas: comunasTerritorio, focused: focusedComunas })
  return selected
}
