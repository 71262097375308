import PByUnholsterBase from '@/assets/pByUnholsterWhite.svg'
import { colors } from '@/constants'
import styled from 'styled-components'
import Menu from './Menu'
import useMagicFrame from '@/stores/useMagicFrame'
import { Outlet } from 'react-router-dom'

export default function MagicFrame() {
  const { setSubmenuOpen } = useMagicFrame()

  return (
    <Wrapper>
      <LeftWrapper onClick={() => setSubmenuOpen(false)}>
        <Content>
          <Outlet />
        </Content>
      </LeftWrapper>
      <Right>
        <Menu />
      </Right>
      <BottomFrame className="col-span-2">
        <PByUnholster />
      </BottomFrame>
    </Wrapper>
  )
}

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #e6e6e6;
  overflow: auto;
`

const LeftWrapper = styled.div`
  display: grid;
  grid: 1fr min-content / 1fr min-content;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
`

const BottomFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: ${colors.frame};
`

const Right = styled.div`
  display: grid;
  grid: 1fr min-content / 1fr;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 260px;
  background-color: ${colors.frame};
  padding-bottom: 100px;
  box-shadow: 0px 6.23px 19.31px 0px #00000040;
`

const Wrapper = styled.div`
  display: grid;
  grid: 1fr / 1fr min-content;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const PByUnholster = styled(PByUnholsterBase)`
  width: fit-content;
  height: 100%;
  padding: 30px;
`
