import styled from 'styled-components'
import React, { useState } from 'react'
import ToggleSwitch from '@/components/Web/ToggleSwitch'
import Card from '@/components/Web/Card'
import { colors } from '@/constants'
import TriangleArrowSVG from '@/assets/triangleArrow.svg'
import CircleSvg from '@/assets/circle.svg'
import { numberWithDots } from '@/helpers/formatters'

export type Data = {
  pacto: string
  color: string
  electos_2021: number
  electos_2024: number
  porcentaje_2021: string
  porcentaje_2024: string
  comparacion: 'up' | 'down' | 'equal'
}

type ElectosPacto = {
  data: Data[]
  title: string
  selection: React.ReactNode
}

export default function PactoElectos({ data, selection, title }: ElectosPacto) {
  const [isChecked, setIsChecked] = useState(false)

  const handleChange = () => {
    setIsChecked((prev) => !prev)
  }

  return (
    <Card
      title={title}
      selector={selection}
      selector2={
        <ToggleSwitch
          checkedLabel="N°"
          uncheckedLabel="%"
          checked={isChecked}
          handleToggle={handleChange}
        />
      }
    >
      <GridTable className="h-auto xl:max-h-[350px] overflow-y-auto mt-8">
        <GridHeaderCell className="text-left sticky top-0 bg-white py-1 z-1">PACTO</GridHeaderCell>
        <GridHeaderCell className="text-right sticky top-0 bg-white py-1">2024</GridHeaderCell>
        <GridHeaderCell className="text-center sticky top-0 bg-white py-1" />
        <GridHeaderCell className="text-right sticky top-0 bg-white py-1">2021</GridHeaderCell>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <GridCell
              className={`flex items-center gap-3 text-[13px] xl:text-[14px] ${
                index % 2 === 0 ? 'bg-[#fafafa]' : ''
              }`}
            >
              <CirclePacto color={item.color} />
              {item.pacto}
            </GridCell>
            <GridCell
              className={`text-right font-bold text-[13px] xl:text-[14px] ${
                index % 2 === 0 ? 'bg-[#fafafa]' : ''
              }`}
            >
              {isChecked ? item.porcentaje_2024 : numberWithDots(item.electos_2024)}
            </GridCell>
            <GridCell
              className={`flex justify-center items-center ${
                index % 2 === 0 ? 'bg-[#fafafa]' : ''
              }`}
            >
              {item.comparacion !== 'equal' && (
                <TriangleArrow direction={item.comparacion} className="w-[10px] xl:w-3 " />
              )}
            </GridCell>
            <GridCell
              className={`text-right text-[13px] xl:text-[14px] ${
                index % 2 === 0 ? 'bg-[#fafafa]' : ''
              }`}
            >
              {isChecked ? item.porcentaje_2021 : numberWithDots(item.electos_2021)}
            </GridCell>
          </React.Fragment>
        ))}
      </GridTable>
    </Card>
  )
}

const GridTable = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr 2fr 1fr;
  grid-auto-rows: min-content;
  overflow-y: auto;
`

const GridHeaderCell = styled.div`
  font-family: 'JetBrains Mono';
  color: ${colors.blackDch};
  border-bottom: 1px solid ${colors.gray1Dch};
  font-size: 12px;
  &:nth-of-type(4n) {
    padding-right: 20px;
  }
`

const GridCell = styled.div`
  padding: 3px 0;
  color: ${colors.blackDch};
  &:nth-of-type(4n) {
    padding-right: 20px;
  }
`

const TriangleArrow = styled(TriangleArrowSVG)<{ direction: 'up' | 'down' }>`
  transform: ${({ direction }) => (direction === 'up' ? 'rotate(0deg)' : 'rotate(180deg)')};
  fill: ${({ direction }) => (direction === 'up' ? colors.gray3Dch : colors.grayVersusDch)};
`

const CirclePacto = styled(CircleSvg)<{ color: string }>`
  fill: ${({ color }) => color};
  width: 12px;
`
