import CircleMesh from '@/components/CircleMesh/CircleMesh'
import DecideChileMono from '@/components/DecideChileMono'
import { Button } from '@/components/ui/button'
import MPactos, { generateCircles } from '@/components/Web/MPactos'
import { PACTOS, TOTAL_ELECTOS } from '@/constants'
import { buildPactosViewData } from '@/lib/buildDataViews'
import { getResultsByPacto, jointCandidatosColor } from '@/lib/dataProcess'
import useElectionData from '@/stores/useElectionData'
import { ElectionType } from '@/types/Elections'
import { Circle, Eye, EyeOff, X } from 'lucide-react'
import { useMemo, useState } from 'react'
import { SmCandidateBadge } from '@/components/Web/CandidateBadge'

export default function CorePactos() {
  const {
    files: { core },
  } = useElectionData()

  const [showPartidos, setShowPartidos] = useState(false)
  const [showPactosDetail, setShowPactosDetail] = useState(false)
  const [selectedPacto, setSelectedPacto] = useState('')

  const electos = useMemo(() => {
    return core ? core.filter((c) => c.electo) : []
  }, [core])

  const resultByPactos = getResultsByPacto(electos)
  const data = buildPactosViewData(resultByPactos, ElectionType.CORE, true)

  const circles = useMemo(() => {
    const orderedData = data.sort((a, b) => a.orden - b.orden)
    return generateCircles(
      orderedData,
      TOTAL_ELECTOS[ElectionType.CORE],
      selectedPacto,
      showPactosDetail
    )
  }, [data, selectedPacto, showPactosDetail])

  const candidatosPacto = useMemo(
    () =>
      core
        ? jointCandidatosColor(
            core.filter((c) => c.cod_pacto === selectedPacto && c.electo),
            ElectionType.CORE
          ).map((c) => ({
            ...c,
            imagen: c.imagen || '/userProfile.png',
          }))
        : [],
    [selectedPacto, core]
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCircleClick = (e: any) => {
    if (e.data.cod_pacto !== '' && e.data.cod_pacto) {
      setSelectedPacto(e.data.cod_pacto)
      setShowPactosDetail(true)
    }
  }
  return (
    <MPactos
      title="Electos por Pacto"
      data={data}
      verticalSeparatorBefore="Otros"
      numberOfPartidosbyColumn={9}
      minWidthPactos="54px"
      showPartidosMobile={showPartidos}
    >
      <div className="flex gap-3 items-center mb-2">
        <Circle fill="#BDBDBd" color="#bdbdbd" className="w-3 xl:w-[10px]" />
        <span className="text-[12px]">A ELEGIR</span>
      </div>
      <div className="relative">
        <div className="hidden md:block">
          <CircleMesh
            onCircleClick={handleCircleClick}
            circles={circles}
            nrows={6}
            gap="5px"
            className="mb-2"
          />
        </div>
        <div className="block md:hidden">
          <CircleMesh
            onCircleClick={handleCircleClick}
            circles={circles}
            nrows={12}
            gap="5px"
            className="mb-4"
          />
        </div>
        {showPactosDetail && (
          <div className="w-full xl:w-[250px] h-[320px] border border-[#e0e0e0] shadow-[0.73px_0px_2.91px_0px_#00000040] xl:mt-6 z-[100] bg-white absolute xl:-top-8 xl:-right-[270px]">
            <div className="h-[50px] px-3 border-b border-[#e0e0e0] flex items-center justify-between">
              <div>
                <h4 className="text-[12px] font-medium">
                  {PACTOS[ElectionType.CORE][selectedPacto].name}
                </h4>
                <p className="font-mono text-gris3Dch uppercase text-[11px]">
                  {candidatosPacto.length} electos
                </p>
              </div>
              <Button size={'iconSm'} variant={'ghost'} onClick={() => setShowPactosDetail(false)}>
                <X size={15} />
              </Button>
            </div>
            <div className="w-full overflow-y-auto h-[270px]">
              {
                <div className="flex flex-col gap-2 px-2 py-2">
                  {candidatosPacto.map((c) => (
                    <SmCandidateBadge key={c.id_persona} electo={c} isRelected={c.reelecto} />
                  ))}
                </div>
              }
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-between xl:justify-end items-center -mt-1 mb-1">
        <DecideChileMono />
        <Button
          onClick={() => setShowPartidos(!showPartidos)}
          className="xl:hidden gap-1 uppercase"
          variant={'outline'}
          size={'ghostSmall'}
        >
          {showPartidos ? <EyeOff size={13} /> : <Eye size={13} />}
          partidos
        </Button>
      </div>
    </MPactos>
  )
}
