import React from 'react'
import { comunas } from '@/stores/useTerritorios'
import useRouting from '@/stores/useRouting'
import styled from 'styled-components'
import { Button } from '../ui/button'
import BarChartIcon from '@/assets/barChart.svg'
import useTerritorios from '@/stores/useTerritorios'
import { darken } from 'polished'
import { useNavigate } from 'react-router-dom'
import { colors } from '@/constants'
import { cn } from '@/lib/utils'
import useElectionData from '@/stores/useElectionData'
export type ComunaProgressData = {
  escrutadas: number
  votos: number
  inscrito: number
}

export default function MapOverlay() {
  const { currentComuna, currentPathname, currentElection } = useRouting()
  const comuna = comunas.find((c) => c.codigo === currentComuna)
  const { comunasColorDict } = useTerritorios()
  const isMagic = currentPathname?.includes('magic')
  const navigate = useNavigate()
  const { participation } = useElectionData()
  const comunaParticipation = participation
    ? participation[currentElection as keyof typeof participation]?.resultados_comuna[
        currentComuna as number
      ]
    : null
  return (
    <div className="flex xl:flex-col items-center gap-2 justify-start xl:w-auto">
      {currentElection !== 'core' && (
        <>
          <ShadowedText
            className={cn('font-semibold text-[40px] uppercase text-center hidden xl:block', {
              'text-[20px]': !isMagic,
            })}
          >
            {comuna?.nombre}
          </ShadowedText>

          <div className="hidden xl:block">
            <ShadowedText
              className={cn('font-bold text-[20px] uppercase text-center', {
                'text-[13px]': !isMagic,
              })}
            >
              {comunaParticipation?.participacion_porcentual || 0}% participación
            </ShadowedText>
            <ShadowedText
              className={cn('font-bold text-[20px] uppercase text-center', {
                'text-[13px] text-nowrap': !isMagic,
              })}
            >
              {comunaParticipation?.votos?.toLocaleString().replace(',', '.') || 0} votos /{' '}
              {comunaParticipation?.inscritos?.toLocaleString().replace(',', '.') || 0} inscritos
            </ShadowedText>

            <ShadowedText
              className={cn('font-bold text-[20px] uppercase text-center', {
                'text-[13px] text-nowrap': !isMagic,
              })}
            >
              {comunaParticipation?.votos_blancos?.toLocaleString().replace(',', '.') || 0} blancos
              / {comunaParticipation?.votos_nulos?.toLocaleString().replace(',', '.') || 0} nulos
            </ShadowedText>
          </div>
          <DataButton
            size={isMagic ? 'iconBig' : 'flexIcon'}
            className={isMagic ? 'p-3' : 'px-[5px] py-[2px]'}
            color={comunasColorDict[currentComuna as number] || 'black'}
            onClick={() =>
              navigate(
                isMagic
                  ? `/magic/chile-en-datos/poblacion/genero_edad/${currentComuna}`
                  : `/datos-comunales/poblacion/genero_edad/${currentComuna}`
              )
            }
          >
            <BarChartIcon
              className={cn('w-6 h-6', {
                'w-4 h-4 ml-1': !isMagic,
              })}
            />
            {!isMagic && <p className="block text-[11px] ml-3 uppercase mr-1">Datos comunales</p>}
          </DataButton>
        </>
      )}
    </div>
  )
}

const ShadowedText = styled.div`
  text-shadow: 0 0 4.5px #000000;
  color: ${colors.whiteDch};
`

const DataButton = styled(Button)`
  background-color: ${(props) => darken(0.1, props.color as string) || 'transparent'};
  box-shadow: 3px 3px 0px 0px #000000a6;
`
