import styled from 'styled-components'
import ComunasChileMap, { MapProps } from '../Map/ComunasChileMap'

type MapBackgroundProps = {
  children?: React.ReactNode
} & MapProps

export default function MapBackground({ children, ...props }: MapBackgroundProps) {
  const { selected } = props
  const focusedElements = selected?.filter(({ focused }) => focused)
  const selectedElement = focusedElements?.length === 1 ? focusedElements[0].element : null

  return (
    <Wrapper className="max-w-vw h-[220px] xl:w-[870px] xl:h-[440px]">
      <Content>{children}</Content>
      <OverMap>{selectedElement}</OverMap>
      <Map {...props} selectionColor="white" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Map = styled(ComunasChileMap)`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
`

const OverMap = styled.div`
  position: absolute;
  top: calc(100% / 2);
  left: calc(100% / 4 * 3);
  transform: translate(-50%, -50%);
  height: min-content;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
