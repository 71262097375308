import styled from 'styled-components'
import React from 'react'
import CheckIconSVG from '@/assets/checkIcon.svg'
import { cn } from '@/lib/utils'
import ReelectoBadge from '@/components/ReelectoBadge'
import SegundaVIcon from '@/assets/2V.svg'
import { getCandidateDisplayName } from '@/lib/utils'
import { Skeleton } from '../ui/skeleton'

type ICandidateBadge = {
  imagen: string
  color: string
  upperCandidateLabel: React.ReactNode
  lowerCandidateLabel: React.ReactNode
  small?: boolean
  winner?: boolean
}

type ICandidateBigBadge = {
  className?: string
  electo: Electo
  winner?: boolean
  reelecto?: boolean
  monoLabel: string
  labeled?: boolean
  isRanking?: boolean
  full?: boolean
  isBlank?: boolean
  showSkeleton?: boolean
  segundaVuelta?: boolean
}

type ICandidateBadgeElect = ICandidateBadge & {
  winner?: boolean
}

type Electo = {
  nombre: string
  partido: string
  pacto?: string
  cod_pacto?: string
  cod_partido?: string
  imagen: string
  color: string
  darkColor?: string
}

export default function CandidateBadge({
  imagen,
  color,
  upperCandidateLabel,
  lowerCandidateLabel,
  small = false,
  winner,
}: ICandidateBadge) {
  return (
    <div className="flex gap-2.5">
      <div className="relative">
        <CandidateImage src={imagen} $borderColor={color} $small={small} />
        {winner && <CheckIconSmall />}
      </div>

      <div className="flex flex-col justify-center">
        {upperCandidateLabel}
        {lowerCandidateLabel}
      </div>
    </div>
  )
}

export function CandidateBadgeWithCheck({
  imagen,
  color,
  upperCandidateLabel,
  lowerCandidateLabel,
  small = false,
  winner = false,
}: ICandidateBadgeElect) {
  return (
    <div className="flex gap-2.5">
      <div className="relative">
        <CandidateImage src={imagen} $borderColor={color} $small={small} />
        {winner && <CheckIconSmall $width="40%" $bottom="-25%" />}
      </div>
      <div className="flex flex-col justify-center">
        {upperCandidateLabel}
        {lowerCandidateLabel}
      </div>
    </div>
  )
}

export function ElectCandidateBadge({
  electo,
  small = false,
}: {
  electo: Electo
  small?: boolean
}) {
  const { nombre, partido, imagen, color } = electo

  return (
    <CandidateBadge
      imagen={imagen}
      color={color}
      small={small}
      upperCandidateLabel={
        <CandidateLabel
          className={cn('font-semibold', {
            'text-[20px] mgw:text-[23px]': small === false,
            'text-[17px] mgw:text-[20px]': small === true,
          })}
          $small={small}
        >
          {partido}
        </CandidateLabel>
      }
      lowerCandidateLabel={
        <CandidateLabel
          className={cn('font-normal', {
            'text-[19px] mgw:text-[23px]': small === false,
            'text-[15px] mgw:text-[19px]': small === true,
          })}
          $small={small}
        >
          {nombre}
        </CandidateLabel>
      }
    />
  )
}

export function NameCandidateBadge({
  electo,
  small = false,
  isBlank = false,
}: {
  electo: Electo
  small?: boolean
  isBlank?: boolean
}) {
  const { nombre, imagen, color } = electo

  const nombreSplitted = nombre.trim().split(' ')
  const firstName = nombreSplitted[0] || ''
  const lastName = nombreSplitted.slice(1).join(' ') || ''

  return (
    <CandidateBadge
      imagen={imagen}
      color={color}
      small={small}
      upperCandidateLabel={
        isBlank ? (
          <Skeleton className="w-[150px] h-5" />
        ) : (
          <CandidateLabel className="font-normal" $small={small}>
            {firstName}
          </CandidateLabel>
        )
      }
      lowerCandidateLabel={
        isBlank ? (
          <Skeleton className="w-[150px] h-5 mt-2" />
        ) : (
          <CandidateLabel className="font-semibold" $small={small}>
            {lastName}
          </CandidateLabel>
        )
      }
    />
  )
}

export function FullCandidateBadge({
  electo,
  small = false,
  size = 'normal',
  winner = false,
  isBlank = false,
}: {
  electo: Electo
  small?: boolean
  size?: 'normal' | 'big'
  winner?: boolean
  isBlank?: boolean
}) {
  const { nombre, cod_partido, imagen, pacto, color } = electo

  return (
    <CandidateBadge
      imagen={imagen}
      color={color}
      winner={winner}
      small={small}
      upperCandidateLabel={
        isBlank ? (
          <Skeleton className="w-[80%] h-5" />
        ) : (
          <CandidateLabel
            className={cn('font-semibold', {
              'text-[22px] mgw:text-[28px]': size === 'big',
            })}
            $small={small}
          >
            {getCandidateDisplayName(nombre)}
          </CandidateLabel>
        )
      }
      lowerCandidateLabel={
        isBlank ? (
          <Skeleton className="w-[300px] h-5 mt-4" />
        ) : (
          <CandidateLabel
            className={cn('font-normal', {
              'text-[18px] mgw:text-[20px]': size === 'big',
            })}
            $small={small}
          >{`${pacto} - ${cod_partido}`}</CandidateLabel>
        )
      }
    />
  )
}

export function CandidateBigBadge({
  className,
  electo,
  winner,
  reelecto,
  monoLabel,
  labeled = false,
  full = false,
  isRanking = false,
  isBlank = false,
  showSkeleton = false,
  segundaVuelta = false,
}: ICandidateBigBadge) {
  const { nombre, imagen, color, pacto, darkColor } = electo

  const splitName = nombre.trim().split(' ')
  const firstLastName = splitName
    .slice(1, splitName.length - 1)
    .join(' ')
    .toLowerCase()
  const secondLastNameFirstCharacter = splitName[splitName.length - 1].charAt(0)

  return (
    <div className={`flex flex-col items-center gap-2 ${className}`}>
      <div className="relative w-full h-full flex justify-center">
        <BigCandidateImage
          src={imagen}
          $borderColor={color}
          $full={full}
          className={cn({
            'h-[170px]': !isRanking,
            'h-full w-full': isBlank,
          })}
        />
        {reelecto && (
          <ReelectoBadge
            id={`reeleccion-tag-${color}`}
            color={color}
            className={cn({
              'absolute top-[10px] left-[-10px] scale-[0.86]': isRanking,
              'absolute top-[27px] left-[40px] scale-[1.1]': !isRanking,
            })}
          />
        )}
        {winner && (
          <CheckIconSVG
            className={cn({
              'absolute bottom-0 right-0 w-[30%]': isRanking,
              'absolute bottom-0 right-[30px] ': !isRanking,
            })}
          />
        )}
        {segundaVuelta && (
          <SegundaVIcon
            className={cn({
              'absolute bottom-0 right-0 w-[42px]': isRanking,
              'absolute bottom-0 right-[30px] w-[50px]': !isRanking,
            })}
          />
        )}
      </div>
      {isBlank ? (
        showSkeleton ? (
          <>
            <Skeleton className="w-[60%] h-6 " />
            <Skeleton className="w-[65%] h-7" />
            <Skeleton className="w-[45%] h-6" />
            <Skeleton className="w-[75%] h-9" />
          </>
        ) : (
          <div className="h-4"></div>
        )
      ) : (
        <>
          {' '}
          <div>
            <div
              className={cn('lowercase first-letter:uppercase text-center', {
                'text-[25px]': !isRanking,
                'text-[20px]': isRanking,
              })}
            >
              {splitName[0]}
            </div>
            <div
              className={cn('capitalize text-nowrap', {
                'text-[22px] mt-[-7px] mb-[-1px] font-medium': isRanking,
                'text-[34px] mt-[-15px] ': !isRanking,
              })}
            >
              {firstLastName.slice(0, 10)}
              {firstLastName.length > 10 && '.'}{' '}
              <span className="capitalize">{secondLastNameFirstCharacter}.</span>
            </div>
          </div>
          <MonoLabel
            className={cn({
              'text-[16px] mt-[-4px] mb-[5px] h-[40px]': isRanking,
              'text-[20px] my-[5px] min-h-[42px] max-w-full text-wrap': !isRanking,
            })}
          >
            {monoLabel}
          </MonoLabel>
          {labeled && (
            <Tag $color={color} $darkColor={darkColor || '#3c3c3c'}>
              {pacto}
            </Tag>
          )}
        </>
      )}
    </div>
  )
}

type ICandidateImage = { $small?: boolean; $borderColor: string }
const CandidateImage = styled.img.attrs<ICandidateImage>(({ $small }) => ({
  className: $small
    ? 'mgw:w-[60px] mgw:h-[60px] w-[47px] h-[47px] mgw:border-[4px] border-[3px]'
    : 'mgw:w-[70px] mgw:h-[70px] w-[60px] h-[60px] mgw:border-[4px] border-[3px]',
}))<ICandidateImage>`
  border-radius: 50%;
  object-fit: cover;
  border-color: ${({ $borderColor }) => $borderColor};
`

type ICandidateLabel = { $small?: boolean }
const CandidateLabel = styled.div<ICandidateLabel>(({ $small }) => ({
  className: $small ? 'mgw:text-[0.9em] text-[0.9em]' : 'mgw:text-[1em] text-[1em]',
}))

const BigCandidateImage = styled.img<{ $borderColor: string; $full: boolean }>`
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  width: ${({ $full }) => ($full ? '100%' : '75%')};
  object-fit: cover;
  border-color: ${({ $borderColor }) => $borderColor};
  border-width: 0.3vw;
`

const CheckIconSmall = styled(CheckIconSVG)<{ $width?: string; $bottom?: string }>`
  position: absolute;
  width: ${({ $width }) => $width || '30%'};
  right: 0px;
  bottom: ${({ $bottom }) => $bottom || '-14%'};
`

const MonoLabel = styled.div`
  font-family: 'JetBrains Mono';
  text-transform: uppercase;
  text-align: center;
  color: #525252;
  line-height: 20px;
`

const Tag = styled.div.attrs<{ $color: string; $darkColor: string }>({
  className: 'mgw:text-[20px] text-[17px] tracking-wide mgw:w-[230px] w-[200px]',
})`
  height: fit-content;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  background-color: ${({ $color }) => `${$color}50`};
  color: ${({ $darkColor }) => $darkColor};
`
