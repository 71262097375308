import { useState } from 'react'
import styled from 'styled-components'
import ToggleSwitch from '../ToggleSwitch'
import Card from '../Card'
import { colors } from '@/constants'
import { Progress } from '@/components/ui/progress'
import { CandidateBadgeWithCheck } from '../CandidateBadge'
import { getCandidateDisplayName } from '@/lib/utils'
import DecideChileMono from '../../DecideChileMono'

export type Electo = {
  region: string
  imagen: string
  nombre: string
  porcentaje: number
  sigla_partido: string
  pacto: string
  color: string
  cod_region: number
  order: number
}

export type Data = {
  electos: Electo[]
  segunda_vuelta: {
    electos: Electo[]
    region: string
  }[]
}

export default function Ganadores({ electos, segunda_vuelta }: Data) {
  const [isElectos, setIsElectos] = useState(false)
  const title = isElectos ? 'Electos' : 'Segunda Vuelta'
  const data = isElectos ? electos : segunda_vuelta

  const handleChangeView = () => {
    setIsElectos((prev) => !prev)
  }

  return (
    <Card
      title={title}
      separatorColor="#CCCCCC"
      toggle={
        <>
          <div className="hidden xl:block">
            <ToggleSwitch
              checkedLabel="2º VUELTA"
              uncheckedLabel="ELECTOS"
              checked={isElectos}
              handleToggle={handleChangeView}
            />
          </div>
          <div className="block xl:hidden">
            <ToggleSwitch
              checkedLabel="2V"
              uncheckedLabel="1V"
              checked={isElectos}
              handleToggle={handleChangeView}
            />
          </div>
        </>
      }
      showInfoToolTip
    >
      <Wrapper className="m-auto xl:max-h-[400px] xl:overflow-y-auto relative">
        <div className="absolute top-[2px] w-full flex justify-end pr-1">
          <DecideChileMono />
        </div>
        {isElectos ? (
          <>
            {data.length === 0 && <img src="goreElectos.png" alt="Blank Electos" />}
            <Grid rows={Math.ceil(data.length / 3)} className="flex flex-col xl:grid gap-x-4">
              {data.map((item, index) => {
                if ('electos' in item) return null
                return (
                  <div className="flex flex-col gap-[10px]" key={`2nd-${item.region}-${index}`}>
                    <RegionText>{item.region}</RegionText>
                    <CandidateInformation candidate={item} winner />
                  </div>
                )
              })}
            </Grid>
          </>
        ) : (
          <>
            {data.length === 0 && <img src="goreGanadores.png" alt="Blank segunda vuelta" />}
            <Grid className="flex flex-col xl:grid gap-x-5">
              {data.map((item, index) => {
                if (!('electos' in item)) return null
                const sortedElectos = item.electos.sort((a, b) => b.porcentaje - a.porcentaje)
                return (
                  <CellGrid2ndRound key={`2nd-${item.region}-${index}`}>
                    <RegionText>{item.region}</RegionText>
                    <div className="flex flex-col gap-3">
                      {sortedElectos.map((electo, index) => (
                        <CandidateInformation
                          key={`2ndround-${electo.region}-${index}`}
                          candidate={electo}
                        />
                      ))}
                    </div>
                  </CellGrid2ndRound>
                )
              })}
            </Grid>
          </>
        )}
      </Wrapper>
    </Card>
  )
}

function CandidateInformation({
  candidate,
  winner = false,
}: {
  candidate: Electo
  winner?: boolean
}) {
  return (
    <div className="flex justify-between items-start ">
      <CandidateBadgeWithCheck
        winner={winner}
        imagen={candidate.imagen}
        color={candidate.color}
        upperCandidateLabel={
          <UpperContent>{getCandidateDisplayName(candidate.nombre, true)}</UpperContent>
        }
        lowerCandidateLabel={
          <LowerContent>
            {candidate.pacto} - {candidate.sigla_partido}
          </LowerContent>
        }
      />
      <div className="flex items-center">
        <div className="font-semibold text-[12px]">
          {candidate.porcentaje.toFixed(1).replace('.', ',')}%
        </div>
        <Progress
          value={Number(candidate.porcentaje)}
          className="w-[50px] xl:w-[30px] h-[11px] ml-3 bg-[#EEEEF0] rounded-tr-[3px] rounded-br-[3px]"
          indicatorColor={candidate.color}
        />
      </div>
    </div>
  )
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  color: ${colors.blackDch};
`

const Grid = styled.div.attrs<{ rows?: number }>({
  className: ' px-2 gap-y-4',
})`
  grid-template-columns: repeat(3, minmax(268px, 1fr));
  grid-template-rows: repeat(${({ rows }) => rows}, minmax(0, 1fr));
  height: fit-content;
  width: 100%;
`

const CellGrid2ndRound = styled.div.attrs({
  className: 'gap-[10px] w-full',
})`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const UpperContent = styled.div.attrs({
  className: 'text-[12px]',
})`
  width: 100%;
  font-weight: 500;
`

const LowerContent = styled.div.attrs({
  className: 'text-[9px]',
})`
  width: 100%;
  font-weight: 300;
  color: #4f4f4f;
`

const RegionText = styled.div.attrs({
  className: 'text-[14px]',
})`
  width: 100%;
  font-weight: 600;
  border-bottom: 0.6px solid #e0e0e0;
`
