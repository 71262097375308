import styled from 'styled-components'
import React, { useState } from 'react'
import ToggleSwitch from '@/components/MagicWall/ToggleSwitch'
import PaginatedCard from '@/components/MagicWall/PaginatedCard'
import { colors } from '@/constants'
import TriangleArrowSVG from '@/assets/triangleArrow.svg'
import CircleSvg from '@/assets/circle.svg'
import { numberWithDots } from '@/helpers/formatters'

export type Data = {
  pacto: string
  color: string
  electos_2021: number
  electos_2024: number
  porcentaje_2021: string
  porcentaje_2024: string
  comparacion: 'up' | 'down' | 'equal'
}

type ElectosPacto = {
  data: Data[]
  pageSize?: number
}

export default function ElectosPacto({ data, pageSize = 5 }: ElectosPacto) {
  const [isChecked, setIsChecked] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)

  const handleChange = () => {
    setIsChecked((prev) => !prev)
  }

  const totalPages = Math.ceil(data.length / pageSize)

  const paginatedData = data.slice(currentPage * pageSize, (currentPage + 1) * pageSize)

  return (
    <PaginatedCard
      title="Electos por Pacto"
      statusLabel="PRELIMINAR"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      pageSize={pageSize}
      totalPages={totalPages}
      toggle={
        <ToggleSwitch
          checkedLabel="N°"
          uncheckedLabel="%"
          checked={isChecked}
          handleToggle={handleChange}
        />
      }
    >
      <GridTable>
        <GridHeaderCell className="text-left">PACTO</GridHeaderCell>
        <GridHeaderCell className="text-right">2024</GridHeaderCell>
        <GridHeaderCell className="text-center" />
        <GridHeaderCell className="text-right">2021</GridHeaderCell>
        {paginatedData.slice(0, pageSize).map((item, index) => (
          <React.Fragment key={index}>
            <GridCell className="flex items-center gap-3">
              <CirclePacto color={item.color} />
              {item.pacto}
            </GridCell>
            <GridCell className="text-right font-bold">
              {isChecked ? item.porcentaje_2024 : numberWithDots(item.electos_2024)}
            </GridCell>
            <GridCell className="flex justify-center items-center">
              {item.comparacion !== 'equal' && <TriangleArrow direction={item.comparacion} />}
            </GridCell>
            <GridCell className="text-right">
              {isChecked ? item.porcentaje_2021 : numberWithDots(item.electos_2021)}
            </GridCell>
          </React.Fragment>
        ))}
      </GridTable>
    </PaginatedCard>
  )
}

const GridTable = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr 1fr 1fr;
  grid-auto-rows: min-content;
`

const GridHeaderCell = styled.div.attrs({
  className: 'mgw:text-[1.5em] text-[1.25em]',
})`
  font-family: 'JetBrains Mono';
  font-weight: 600;
  line-height: 31.78px;

  color: ${colors.blackDch};
  border-bottom: 2px solid ${colors.gray3Dch};

  padding: 15px 0px 15px 10px;
  &:nth-of-type(4n + 1) {
    padding-left: 40px;
  }
  &:nth-of-type(4n) {
    padding-right: 50px;
  }
`

const GridCell = styled.div.attrs({
  className: 'mgw:text-[2em] text-[1.7em] mgw:py-[15px] py-[10px]',
})`
  line-height: 36.48px;

  color: ${colors.blackDch};

  border-bottom: 2px solid ${colors.gray3Dch};

  &:nth-of-type(4n + 1) {
    padding-left: 40px;
  }
  &:nth-of-type(4n) {
    padding-right: 50px;
  }
`

const TriangleArrow = styled(TriangleArrowSVG)<{ direction: 'up' | 'down' }>`
  transform: ${({ direction }) => (direction === 'up' ? 'rotate(0deg)' : 'rotate(180deg)')};
  fill: ${({ direction }) => (direction === 'up' ? colors.gray3Dch : colors.grayVersusDch)};
`

const CirclePacto = styled(CircleSvg)<{ color: string }>`
  fill: ${({ color }) => color};
`
