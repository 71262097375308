import styled from 'styled-components'
import { colors } from '@/constants'

type IToggleSwitch = {
  checked: boolean
  handleToggle: (event: React.ChangeEvent<HTMLInputElement>) => void
  uncheckedLabel: string
  checkedLabel: string
}

export default function ToggleSwitch({
  checked,
  handleToggle,
  uncheckedLabel,
  checkedLabel,
}: IToggleSwitch) {
  const isBigSwitch = checkedLabel.length > 3 || uncheckedLabel.length > 3
  return (
    <SwitchContainer className="scale-75 xl:scale-100">
      <Input checked={checked} onChange={handleToggle} />
      <Slider $isBig={isBigSwitch} $isChecked={checked}>
        <SlideText $align="left" $checked={checked} $isBig={isBigSwitch}>
          {checkedLabel}
        </SlideText>
        <SlideText $align="right" $checked={!checked} $isBig={isBigSwitch} className="pl-2">
          {uncheckedLabel}
        </SlideText>
      </Slider>
    </SwitchContainer>
  )
}

const SwitchContainer = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`

const Slider = styled.span<{ $isBig: boolean; $isChecked: boolean }>`
  position: relative;
  display: inline-block;
  background-color: white;
  border: 1px solid #eeeef0;
  border-radius: 2px;
  width: ${({ $isBig }) => ($isBig ? '145px' : '84px')};
  height: 35px;

  &::before {
    content: '';
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background-color: white;
    transition: background-color 0.4s;
  }

  &::after {
    content: '';
    position: absolute;
    cursor: pointer;
    width: ${({ $isChecked, $isBig }) => ($isChecked ? ($isBig ? '47%' : '44%') : '50%')};
    height: calc(100% - 8px);
    margin: 4px;
    margin-left: ${({ $isChecked }) => ($isChecked ? '15px' : '4px')};
    border-radius: 0px;
    background-color: #f5f5f5;
    transition: transform 0.4s;
  }
`

const SlideText = styled.span<{ $checked: boolean; $align: string; $isBig: boolean }>`
  position: absolute;
  width: calc(50% + 8px);
  top: 50%;
  z-index: 1;
  text-align: center;
  font-size: ${({ $isBig }) => ($isBig ? '11px' : '13px')};

  font-weight: ${({ $checked }) => ($checked ? 400 : 700)};
  letter-spacing: 0.03em;
  user-select: none;

  transition: color 0.2s;
  transform: translateY(-50%);

  color: ${({ $checked }) => (!$checked ? colors.blackDch : '#A8A8A8')};
  ${({ $align }) => $align}: 0;
`

const Input = styled.input.attrs({ type: 'checkbox' })`
  display: none;

  &:checked + ${Slider}::after {
    transform: translateX(calc(100% - 8px));
  }
`
