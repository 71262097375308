import styled from 'styled-components'
import { colors } from '@/constants'
import { ReactNode } from 'react'
import { numberToShortVotes } from '@/helpers/formatters'

type PactoInfoProps = {
  color: string
  electos: number
  name?: string
  children?: ReactNode
  votos: number
  isGobernadorView?: boolean
}

export default function PactoInfo({
  color,
  electos,
  name,
  votos,
  isGobernadorView = true,
}: PactoInfoProps) {
  const WrapperComponent = isGobernadorView ? WrapperGobernadorPacto : WrapperAlcaldePacto
  return (
    <WrapperComponent>
      <ElectosPacto color={color}>{electos}</ElectosPacto>
      <PactoName>{name}</PactoName>
      <HSeparatorPactos />
      <VoteCount>{numberToShortVotes(votos)}</VoteCount>
    </WrapperComponent>
  )
}

const WrapperGobernadorPacto = styled.div.attrs({
  className: 'w-[58px] ifr:w-[76px]',
})`
  display: flex;
  flex-direction: column;
`
const WrapperAlcaldePacto = styled.div.attrs({
  className: 'w-[54px] sifr:w-[70px] ifr:w-[86px]',
})`
  display: flex;
  flex-direction: column;
`

const ElectosPacto = styled.div.attrs<{ color: string }>({
  className: 'text-[16px] sifr:text-[22px] ifr:text-[30px]',
})`
  font-weight: 700;
  color: ${({ color }) => color};
`

const PactoName = styled.div.attrs({
  className: `text-[10px] sifr:text-[12px] ifr:text-[14px]
            leading-[14px] ifr:leading-[16px]
            tracking-[0.48px] ifr:tracking-[0.56px]
            min-h-[30px] sifr:min-h-[40px] ifr:min-h-[44px]
            pb-[6px] sifr:pb-[12px]
  `,
})`
  height: 100%;
  font-weight: 500;
  color: ${colors.blackDch};
`

const HSeparatorPactos = styled.div.attrs({})`
  height: 100%;
  border-bottom: 1px solid ${colors.gray5Dch};
`

const VoteCount = styled.div.attrs({
  className: 'text-[9px] ifr:text-[12px]',
})`
  font-weight: 500;
  color: ${colors.gray3};
`
