import { Carousel, CarouselContent, CarouselItem } from '@/components/ui/carousel'
import { colors, electionParams } from '@/constants'
import useMagicFrame from '@/stores/useMagicFrame'
import useRouting from '@/stores/useRouting'
import _ from 'lodash'
import { useEffect } from 'react'
import { To, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ExpandButton as ExpandButtonBase } from '../Expand'
import { BUTTON_HEIGHT } from '../MagicButton'
import {
  setCircunscripcionProvincialSubmenu,
  setComunasChileEnDatosSubmenu,
  setComunasSubmenu,
  setRegionesSubmenu,
} from './helpers'
import Pagination from './Pagination'
import { ElectionType } from '@/types/Elections'

const MAX_ITEMS = 17

export type SubmenuOption = {
  name: string
  key: string
  onClick?: () => void
  selected?: boolean
  navigateTo?: To
  closeMenu?: boolean
  ordenMenu?: number
}

export type SubmenuProps = {
  onClick?: (region: string) => void
  options?: SubmenuOption[]
  title?: React.ReactNode
  navigation?: React.ReactNode
}
export default function SubMenu() {
  const { setSubmenuOpen, subMenuOptions } = useMagicFrame()
  const navigate = useNavigate()
  const {
    currentElection,
    currentRegion,
    currentComuna,
    currentCircunscripcion,
    isInTerritorio,
    isMagicChileEnDatos,
    currentPathname,
  } = useRouting()

  useEffect(() => {
    if (isMagicChileEnDatos) return
    // Aquí se setean los territorios a partir de la ruta seleccionada
    function setEleccionSubmenu() {
      const needsSetSubmenu = currentElection && isInTerritorio
      if (!needsSetSubmenu) return
      const { territorios } = electionParams[currentElection]
      if (currentComuna || currentRegion) {
        setComunasSubmenu({
          from: territorios,
          onReturn: () =>
            currentElection === ElectionType.CORE
              ? setCircunscripcionProvincialSubmenu()
              : setRegionesSubmenu(),
        })
        return
      }
      const renderRegiones = territorios === 'region-comuna'
      if (renderRegiones) {
        setRegionesSubmenu()
        return
      }
      setCircunscripcionProvincialSubmenu()
    }

    setEleccionSubmenu()
  }, [
    currentElection,
    currentComuna,
    currentRegion,
    currentCircunscripcion,
    isInTerritorio,
    isMagicChileEnDatos,
    currentPathname,
  ])

  useEffect(() => {
    if (!isMagicChileEnDatos) return
    setComunasChileEnDatosSubmenu()
  }, [])

  if (!subMenuOptions) return null
  const { onClick: onClickSubmenu, options, title, navigation } = subMenuOptions
  const paginatedOptions = _.chunk<SubmenuOption>(options, MAX_ITEMS)

  return (
    <Wrapper>
      <ExpandButton height={BUTTON_HEIGHT} onClick={() => setSubmenuOpen(false)} />
      <Content>
        {navigation && <Navigation>{navigation}</Navigation>}
        {title && <Title>{title}</Title>}
        <OptionsSection>
          <Carousel className="w-full max-w-xs flex flex-col gap-2" onChange={console.log}>
            <CarouselContent>
              {paginatedOptions.map((options: SubmenuOption[], index) => (
                <CarouselItem key={index}>
                  <Options>
                    {options.map(({ name, key: id, selected, navigateTo, onClick, closeMenu }) => (
                      <Option
                        key={id}
                        onClick={() => {
                          if (onClick) onClick()
                          if (onClickSubmenu) onClickSubmenu(id)
                          if (navigateTo) navigate(navigateTo)
                          if (closeMenu) setSubmenuOpen(false)
                        }}
                        selected={selected}
                      >
                        {name}
                      </Option>
                    ))}
                  </Options>
                </CarouselItem>
              ))}
            </CarouselContent>
            <Pagination total={paginatedOptions.length} />
          </Carousel>
        </OptionsSection>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${colors.subMenuBackground};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 36px;
  gap: 18px;
`

const Navigation = styled.div`
  font-size: 22px;
  color: white;
  width: 100%;
`

const Title = styled.div`
  font-size: 25px;
  color: white;
  font-weight: bold;
  width: 100%;
  padding-left: 24px;
`

const Option = styled.button<{ selected?: boolean }>`
  font-size: 22px;
  color: ${({ selected }) => (selected ? 'white' : colors.frameFontLight)};
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  text-decoration: ${({ selected }) => (selected ? 'underline' : 'none')};
  text-underline-position: under;
  padding-left: 24px;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ExpandButton = styled(ExpandButtonBase)`
  position: absolute;
  left: 0;
  transform: translateX(-100%);
  background-color: ${colors.frame};
`
