import ElectosPacto from '@/components/MagicWall/ElectosPacto'
import useRouting from '@/stores/useRouting'
import useTerritorios, { comunas } from '@/stores/useTerritorios'
import useElectionData from '@/stores/useElectionData'
import { useMemo } from 'react'
import { getRegionResultsByPactoCORE } from '@/lib/dataProcess'
import { buildRegionResultsByPacto } from '@/lib/buildDataViews'
import { ElectionType } from '@/types/Elections'
import { compareCORECircunscripcion } from '@/helpers/formatters'

export default function CRElectosRegion() {
  const { currentCircunscripcion } = useRouting()
  const { setComunaColor } = useTerritorios()
  const {
    files: { core },
    comparador: { core: core2021 },
    static: { escanos_core },
  } = useElectionData()

  const data = useMemo(() => {
    if (!core2021 || !core || !currentCircunscripcion || !escanos_core) return []

    const comunasCircunscripcion = comunas
      .filter((comuna) =>
        compareCORECircunscripcion(currentCircunscripcion, comuna.circunscripcionProvincial || '')
      )
      .map((comuna) => comuna.codigo)

    const { countElectosByPacto, countElectosByPacto2021, comunasColor } =
      getRegionResultsByPactoCORE(
        core || [],
        core2021,
        currentCircunscripcion || '',
        comunasCircunscripcion
      )

    const totalElectos =
      escanos_core[currentCircunscripcion.toUpperCase()] ||
      escanos_core[currentCircunscripcion] ||
      escanos_core[currentCircunscripcion === 'Aysén' ? 'AISÉN' : ''] ||
      escanos_core[currentCircunscripcion === 'Antártica' ? 'ANTÁRTICA CHILENA' : ''] ||
      1

    const data = buildRegionResultsByPacto(
      countElectosByPacto,
      countElectosByPacto2021,
      totalElectos || 0,
      ElectionType.CORE
    )

    setComunaColor(comunasColor)

    return data.sort((a, b) => b.electos_2024 - a.electos_2024)
  }, [core, core2021, currentCircunscripcion, escanos_core, setComunaColor])

  return <ElectosPacto data={data} pageSize={8} />
}
