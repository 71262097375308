import { colors } from '@/constants'
import useRouting from '@/stores/useRouting'
import { ComunaData, comunas, RegionData, regiones } from '@/stores/useTerritorios'
import styled from 'styled-components'

const regionesCorto = [13, 6, 11, 12]

export default function ComunaHeader() {
  const { currentComuna } = useRouting()
  const comuna = comunas.find((comuna) => comuna.codigo === currentComuna) as ComunaData
  const region = regiones.find((region) => region.codigo === comuna?.region) as RegionData

  const nameRegion = regionesCorto.includes(region?.codigo) ? region?.nombreCorto : region?.nombre
  return (
    <Content className="flex items-center gap-3 uppercase font-medium text-[22px]">
      <p>{nameRegion}</p>
      <p className="mb-1">{'>'}</p>
      <p>{comuna?.nombre}</p>
    </Content>
  )
}

const Content = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 2px 10px;
  background-color: #4a5fab;
  font-family: 'JetBrains Mono';
  color: ${colors.whiteDch};
`
