import { colors, electionParams } from '@/constants'
import styled from 'styled-components'
import useRouting from '@/stores/useRouting'
import { Outlet } from 'react-router-dom'
import Layout from '@/app/Web/Layout'
import Sidebar from '@/components/Web/SideBar'
import ParticipationBanner from './ParticipationBanner'
import { MonoLabel } from './ParticipationBanner'
import { MobileNavigation } from '@/components/Web/SideBar'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorPage from '@/components/Web/ErrorPage'
import { Button } from '@/components/ui/button'
import { Share2 } from 'lucide-react'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import ReactGA from 'react-ga4'
ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID)

const shareInSocialMedia = (url: string) => {
  const width = 800
  const height = 600
  const left = window.screen.width / 2 - width / 2
  const top = window.screen.height / 2 - height / 2
  window.open(url, '_blank', `width=${width},height=${height},top=${top},left=${left}`)
  ReactGA.event('live2024_boton_capturar')
}

export default function ContentLayout() {
  const { currentElection, currentTema } = useRouting()
  const lastUpdateTime = new Date().toLocaleTimeString('es-CL', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })
  const today = new Date()
    .toLocaleDateString('es-CL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .replace(/-/g, '.')

  // Get election name
  let electionName = currentElection
    ? electionParams[currentElection].longName
    : currentTema
    ? 'Datos comunales'
    : 'Error'
  // Change gore name to Gobernador if the view is about a region
  if (currentElection === 'gore') electionName = 'Gobernadores Regionales'

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Layout>
        <div className="xl:border-b xl:border-[#e0e0e0] xl:pb-4">
          <div className="w-full xl:w-[1100px] flex justify-center flex-col xl:flex-row xl:justify-between px-5 items-center">
            {electionName !== 'Datos comunales' && electionName !== 'Error' && (
              <>
                {/* <MonoLabel className="block xl:hidden font-medium text-[11px] px-1 bg-[#FCBE31] h-fit mb-[6px] rounded-[2px]">
                  {currentElection === 'gore' ? '' : 'PRELIMINAR'}
                </MonoLabel> */}
                <MonoLabel className="block xl:hidden text-[10px] font-light">
                  ACTUALIZADO {lastUpdateTime} | {today}
                </MonoLabel>
              </>
            )}

            <Title className="-mb-2 xl:mb-0">
              <div className="font-bold text-[24px] xl:text-[36px]">{electionName}</div>
              {electionName !== 'Datos comunales' && electionName !== 'Error' && (
                <>
                  <div className="font-normal hidden xl:block text-[36px]">2024</div>
                  {/* <MonoLabel className="hidden xl:block font-medium text-[12px] px-1 bg-[#FCBE31] h-fit ml-2 rounded-[2px]">
                    {currentElection === 'gore' ? '' : 'PRELIMINAR'}
                  </MonoLabel> */}
                </>
              )}
            </Title>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={'outline'}
                  className="absolute right-7 bottom-7 rounded-full xl:static xl:rounded-md z-[100]"
                  size={'iconFlex'}
                >
                  <Share2 className="w-5 h-5 xl:mr-2" size={20} />
                  <p className="hidden xl:block">Compartir</p>
                </Button>
              </PopoverTrigger>
              <PopoverContent
                className="p-2 flex flex-col xl:flex-row gap-2 w-fit xl:bg-transparent xl:border-none xl:shadow-none pb-0"
                side="top"
              >
                <Button
                  variant={'ghost'}
                  size={'icon'}
                  className="rounded"
                  onClick={() =>
                    shareInSocialMedia(
                      `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`
                    )
                  }
                >
                  <img src="facebook.svg" alt="" />
                </Button>
                <Button
                  variant={'ghost'}
                  size={'icon'}
                  className="rounded"
                  onClick={() =>
                    shareInSocialMedia(`https://api.whatsapp.com/send?text=${window.location.href}`)
                  }
                >
                  <img src="whatsapp.svg" alt="" />
                </Button>
                <Button
                  variant={'ghost'}
                  size={'icon'}
                  className="rounded"
                  onClick={() =>
                    shareInSocialMedia(`https://x.com/intent/post?url=${window.location.href}`)
                  }
                >
                  <img src="X.svg" alt="" />
                </Button>
                <Button
                  variant={'ghost'}
                  size={'icon'}
                  className="rounded"
                  onClick={() =>
                    shareInSocialMedia(
                      `https://www.linkedin.com/feed/?linkOrigin=LI_BADGE&shareActive=true&shareUrl=${window.location.href}`
                    )
                  }
                >
                  <img src="linkedin.svg" alt="" />
                </Button>
                <Button
                  variant={'ghost'}
                  size={'icon'}
                  className="rounded"
                  onClick={() =>
                    shareInSocialMedia(`https://telegram.me/share/url?url=${window.location.href}`)
                  }
                >
                  <img src="telegram.svg" alt="" />
                </Button>
              </PopoverContent>
            </Popover>
          </div>
        </div>
        <div className="w-full xl:w-[1100px] flex justify-center xl:justify-between xl:p-5 xl:grid xl:grid-cols-[0.4fr_1fr] xl:h-[490px]">
          <Sidebar />
          <div className="xl:pl-4 pt-2 w-full flex flex-col xl:justify-between justify-start flex-grow">
            {electionName !== 'Datos comunales' && electionName !== 'Error' && (
              <ParticipationBanner />
            )}

            <MobileNavigation />
            <div className="mt-2 xl:mt-0">
              <Outlet />
            </div>
          </div>
        </div>
      </Layout>
    </ErrorBoundary>
  )
}

const Title = styled.div`
  display: flex;
  gap: 8px;
  color: ${colors.blackDch};
  align-items: center;
`
