import { colors } from '@/constants'
import { ArrowLeft } from 'lucide-react'
import styled from 'styled-components'

export function ReturnButton({
  onClick,
  children = 'Ir a regiones',
}: {
  onClick?: () => void
  children?: React.ReactNode
}) {
  return (
    <Wrapper onClick={onClick}>
      <ArrowLeft size={37} />
      <Text>{children}</Text>
    </Wrapper>
  )
}

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 6px;
`

const Text = styled.span`
  font-size: 22px;
  color: ${colors.frameFontLight};
`
