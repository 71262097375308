import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getCandidateDisplayName(nombre: string, shorted = false) {
  const parts1 = nombre
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())

  const parts = parts1.map((word) => (word.includes('-') ? word.split('-')[0] : word))

  return shorted
    ? parts[0] + ' ' + parts.slice(-2, -1) + ' ' + parts[parts.length - 1].charAt(0) + '.'
    : parts.slice(0, 1).join(' ') + ' ' + parts.slice(-2).join(' ')
}

export function getCandidateDisplayNameSeprated(nombre: string) {
  const parts = nombre
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())

  return {
    firstName: parts[0],
    lastName: parts
      .slice(1, parts.length - 1)
      .join(' ')
      .toLowerCase(),
    secondLastName: parts[parts.length - 1],
  }
}
