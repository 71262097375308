import DecideChileMono from '@/components/DecideChileMono'
import { Button } from '@/components/ui/button'
import { SmCandidateBadge } from '@/components/Web/CandidateBadge'
import MPactos, { PactoResult } from '@/components/Web/MPactos'
import { colors, PACTOS, TOTAL_ELECTOS } from '@/constants'
import { buildPactosViewData } from '@/lib/buildDataViews'
import { getResultsByPacto, jointCandidatosColor } from '@/lib/dataProcess'
import useElectionData from '@/stores/useElectionData'
import { ElectionType } from '@/types/Elections'
import { Eye, EyeOff, X } from 'lucide-react'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

export default function CPactos() {
  const {
    files: { concejales },
  } = useElectionData()

  const data: PactoResult[] = useMemo(() => {
    const resultByPactos = getResultsByPacto(concejales || [])
    return buildPactosViewData(resultByPactos, ElectionType.CONCEJALES)
  }, [concejales])

  const [showPartidos, setShowPartidos] = useState(false)
  const [showPactosDetail, setShowPactosDetail] = useState(false)

  const [selectedPacto, setSelectedPacto] = useState<string | null>(null)

  const candidatosPacto = useMemo(
    () =>
      concejales
        ? jointCandidatosColor(
            concejales.filter((c) => c.cod_pacto === selectedPacto && c.electo),
            ElectionType.CONCEJALES
          ).map((c) => ({
            ...c,
            imagen: c.imagen || '/userProfile.png',
          }))
        : [],
    [concejales, selectedPacto]
  )

  const handlebgClick = (cod_pacto: string) => {
    setSelectedPacto(cod_pacto)
    setShowPactosDetail(true)
  }

  return (
    <MPactos
      election="Concejales"
      data={data}
      verticalSeparatorBefore="Otros"
      numberOfPartidosbyColumn={8}
      minWidthPactos="67px"
      showPartidosMobile={showPartidos}
    >
      <div className="relative">
        <Graph className="w-full xl:w-[868px] flex gap-0 h-[130px] mb-2 xl:mt-0">
          {data.map((pacto) => {
            return (
              <BackgroundPacto
                key={`${pacto.abreviatura}-background`}
                $color={
                  pacto.cod_pacto === selectedPacto
                    ? pacto.color
                    : showPactosDetail
                    ? `${pacto.color}50`
                    : pacto.color
                }
                $percentage={(pacto.electos / TOTAL_ELECTOS[ElectionType.CONCEJALES]) * 100}
                onClick={() => handlebgClick(pacto.cod_pacto)}
                className="cursor-pointer"
              />
            )
          })}
        </Graph>
        {showPactosDetail && (
          <div className="w-full xl:w-[250px] h-[320px] border border-[#e0e0e0] shadow-[0.73px_0px_2.91px_0px_#00000040] xl:mt-6 z-[100] bg-white absolute xl:-top-8 xl:-right-[270px]">
            <div className="h-[50px] px-3 border-b border-[#e0e0e0] flex items-center justify-between">
              <div>
                <h4 className="text-[12px] font-medium">
                  {PACTOS[ElectionType.CONCEJALES][selectedPacto as string].name}
                </h4>
                <p className="font-mono text-gris3Dch uppercase text-[11px]">
                  {candidatosPacto.length} electos
                </p>
              </div>
              <Button
                size={'iconSm'}
                variant={'ghost'}
                onClick={() => {
                  setShowPactosDetail(false)
                  setSelectedPacto(null)
                }}
              >
                <X size={15} />
              </Button>
            </div>
            <div className="w-full overflow-y-auto h-[270px]">
              {
                <div className="flex flex-col gap-2 px-2 py-2">
                  {candidatosPacto.map((c) => (
                    <SmCandidateBadge key={c.id_persona} electo={c} isRelected={c.reelecto} />
                  ))}
                </div>
              }
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-between xl:justify-end items-center">
        <DecideChileMono />
        <Button
          onClick={() => setShowPartidos(!showPartidos)}
          className="xl:hidden gap-1 uppercase"
          variant={'outline'}
          size={'ghostSmall'}
        >
          {showPartidos ? <EyeOff size={13} /> : <Eye size={13} />}
          partidos
        </Button>
      </div>
    </MPactos>
  )
}

const BackgroundPacto = styled.div<{ $color: string; $percentage: number }>`
  background-color: ${({ $color }) => $color};
  width: ${({ $percentage }) => `${$percentage}%`};
`

const Graph = styled.div`
  background-color: ${colors.gray2Dch};
`
