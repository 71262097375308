import styled from 'styled-components'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { formatFullName, hexToRGBA, numberToPercent } from '@/helpers/formatters'
import { PACTOS_ALCALDES } from '@/constants/pactos'
import CheckIcon from '@/assets/checkIcon.svg'
import { Alcaldes2021, AlcaldesFile } from '@/types/Files'
import {
  codigosComunasDestacadas,
  getAlcaldesDestacados,
  getComunasDestacadas,
  getPactosDestacados2021ByComuna,
} from '@/helpers/iframe'
import { colors } from '@/constants/colors'

type Candidate = {
  name: string
  pacto: string
  percentage: number
}

export default function AlcaldesComunas({
  alcaldesFile,
  comparadorAlcaldes,
}: {
  alcaldesFile: AlcaldesFile | undefined
  comparadorAlcaldes: Alcaldes2021 | undefined
}) {
  const alcaldesDestacados = getAlcaldesDestacados(alcaldesFile)
  const pactosDestacados2021 = getPactosDestacados2021ByComuna(comparadorAlcaldes?.electos_pacto)
  const comunasDestacadas = getComunasDestacadas()
  return (
    <StyledTable>
      <TableHeader>
        <TableRow>
          <StyledTableHead className="w-[70px] sifr:w-[90px] ifr:w-[120px]">
            COMUNAS
          </StyledTableHead>
          <StyledTableHead>ELECTO/A</StyledTableHead>
          <StyledTableHead>%</StyledTableHead>
          <StyledTableHead>PACTO 2021</StyledTableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {Object.entries(comunasDestacadas)
          .sort(([codComunaA], [codComunaB]) => {
            return (
              codigosComunasDestacadas.indexOf(Number(codComunaA)) -
              codigosComunasDestacadas.indexOf(Number(codComunaB))
            )
          })
          .map(([codComuna, comuna]) => {
            const alcalde = alcaldesDestacados.find(
              (alcalde) => alcalde.cod_comuna === Number(codComuna)
            )

            return (
              <StyledTableRow key={`comuna-${codComuna}`}>
                <StyledTableCell className="align-top">
                  {comunasDestacadas[codComuna].nombre}
                </StyledTableCell>
                <StyledTableCell>
                  {alcalde && (
                    <Candidate>
                      <div className="relative">
                        <StyledAvatar
                          color={PACTOS_ALCALDES[alcalde.cod_pacto].color}
                          className="h-[26px] sifr:h-[30px] ifr:h-[40px] w-[26px] sifr:w-[30px] ifr:w-[40px]"
                        >
                          <AvatarImage
                            src={alcalde.imagen || '/userProfile.png'}
                            alt={alcalde.nombre}
                          />
                          <AvatarFallback>{alcalde.nombre.charAt(0)}</AvatarFallback>
                        </StyledAvatar>
                        <CheckIcon
                          className="absolute w-[8px] h-[8px] sifr:w-[10px] sifr:h-[10px] ifr:w-[12px] ifr:h-[12px] 
                          top-[18px] left-[18px] sifr:top-[20px] sifr:left-[20px] ifr:top-[28px] ifr:left-[28px] "
                        />
                      </div>
                      <CandidateInfo className="flex flex-col gap-[2px] ifr:gap-[4px]">
                        <div className="text-balance leading-[1.07]">
                          {formatFullName(alcalde.nombre)}
                        </div>
                        <CandidateDetail>
                          <StyledBadge
                            color={PACTOS_ALCALDES[alcalde.cod_pacto].color}
                            darkColor={PACTOS_ALCALDES[alcalde.cod_pacto].darkColor}
                          >
                            {alcalde.cod_pacto}
                          </StyledBadge>
                        </CandidateDetail>
                      </CandidateInfo>
                    </Candidate>
                  )}
                </StyledTableCell>
                <StyledTableCell className="font-medium text-[12px] sifr:text-[16px] ifr:text-[20px]">
                  {alcalde ? numberToPercent(alcalde.porcentaje, 1) : ''}
                </StyledTableCell>
                <StyledTableCell className="text-[10px] ifr:text-[13px]">
                  <div className="flex">
                    {pactosDestacados2021 && (
                      <StyledBadge
                        color={PACTOS_ALCALDES[pactosDestacados2021[codComuna].sigla_pacto].color}
                        darkColor={
                          PACTOS_ALCALDES[pactosDestacados2021[codComuna].sigla_pacto].darkColor
                        }
                      >
                        {pactosDestacados2021[codComuna].sigla_pacto}
                      </StyledBadge>
                    )}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            )
          })}
      </TableBody>
    </StyledTable>
  )
}

const StyledTable = styled(Table).attrs({
  className: `text-[10px] sifr:text-[12px] ifr:text-[16px]`,
})`
  border-collapse: separate;
  border-spacing: 0;
  color: #3c3c3c;
`

const StyledTableHead = styled(TableHead).attrs({
  className: `text-[8px] sifr:text-[10px] ifr:text-[13px]
    h-[24px] ifr:h-[30px]
  `,
})`
  font-weight: 600;
  font-family: 'Jetbrains Mono', monospace;
  color: #3c3c3c;
  position: sticky;
  top: 0px;
  z-index: 100;
  border-bottom: 1px solid ${colors.gray3Dch};
  background-color: white;
  &:first-child {
    background-color: ${colors.gray6Dch};
  }
`

const StyledTableCell = styled(TableCell)`
  border-bottom: 1px solid ${colors.gray3Dch};
  border-left: none;
  border-right: none;

  &:first-child {
    font-weight: 700;
    background-color: ${colors.gray6Dch};
  }
`

const StyledTableRow = styled(TableRow)`
  &:last-child ${StyledTableCell} {
    border-bottom: none;
  }
`

const Candidate = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const CandidateInfo = styled.div.attrs({
  className: `text-[10px] sifr:text-[12px] ifr:text-[16px]`,
})``

const CandidateDetail = styled.div.attrs({
  className: `text-[9px] sifr:text-[10px] ifr:text-[13px]`,
})`
  color: #3c3c3c;
  display: flex;
  gap: 6px;
  align-items: center;
`

const StyledBadge = styled.div.attrs({
  className: 'text-[8px] ifr:text-[11px]',
})<{ color: string; darkColor: string | undefined }>`
  background-color: ${(props) => hexToRGBA(props.color, 0.1)};
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.darkColor};
  padding: 0px 4px;
  font-weight: 400;
  border-radius: 4px;
  height: min-content;
  line-height: 1.6;
`

const StyledAvatar = styled(Avatar).attrs({
  className: 'h-10 w-10 border-[1px] sifr:border-2',
})<{ color: string }>`
  border-color: ${(props) => props.color};
`
