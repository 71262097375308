import styled from 'styled-components'
import { colors } from '@/constants'

type IToggleSwitch = {
  checked: boolean
  handleToggle: (event: React.ChangeEvent<HTMLInputElement>) => void
  uncheckedLabel: string
  checkedLabel: string
}

export default function ToggleSwitch({
  checked,
  handleToggle,
  uncheckedLabel,
  checkedLabel,
}: IToggleSwitch) {
  return (
    <SwitchContainer>
      <Input checked={checked} onChange={handleToggle} />
      <Slider size={Math.max(checkedLabel.length, uncheckedLabel.length)}>
        <SlideText align="left" checked={checked}>
          {checkedLabel}
        </SlideText>
        <SlideText align="right" checked={!checked}>
          {uncheckedLabel}
        </SlideText>
      </Slider>
    </SwitchContainer>
  )
}

const SwitchContainer = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`

const Slider = styled.span.attrs<{ size: number }>({
  className: 'mgw:min-w-[6em] min-w-[5.5em] mgw:h-[2em] h-[1.7em]',
})`
  display: flex;
  position: relative;
  display: inline-block;
  width: ${({ size }) => 2 * size}em;
  background-color: ${colors.gray3Dch};
  border-radius: 6px;

  &::before {
    content: '';
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: ${colors.gray3Dch};
    transition: background-color 0.4s;
  }

  &::after {
    content: '';
    position: absolute;
    cursor: pointer;
    width: 50%;
    height: calc(100% - 8px);
    margin: 4px;
    border-radius: 3px;
    background-color: ${colors.whiteDch};
    transition: transform 0.4s;
  }
`

const SlideText = styled.span.attrs<{ checked: boolean; align: string }>({
  className: 'mgw:text-[1.25em] text-[1em]',
})`
  position: absolute;
  width: calc(50% + 8px);
  top: 50%;
  z-index: 1;
  text-align: center;

  font-weight: 600;
  letter-spacing: 0.03em;
  user-select: none;

  transition: color 0.2s;
  transform: translateY(-50%);

  color: ${({ checked }) => (checked ? colors.whiteDch : colors.gray3Dch)};
  ${({ align }) => align}: 0;
`

const Input = styled.input.attrs({ type: 'checkbox' })`
  display: none;

  &:checked + ${Slider}::after {
    transform: translateX(calc(100% - 8px));
  }
`
