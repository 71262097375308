import { Alcaldes2021, Concejales2021, Core2021, Gore2021 } from '@/types/Files'

// Only for GORE y Alcaldes
export const getResultsByPacto2021AG = (file2021: Alcaldes2021 | Gore2021) => {
  const territorios = Object.keys(file2021.electos_pacto)
  const results = territorios.reduce((acc, cod_comuna) => {
    const { sigla_pacto, electos } = file2021.electos_pacto[cod_comuna][0]
    if (!sigla_pacto || !electos) return acc
    if (!acc[sigla_pacto]) acc[sigla_pacto] = 0
    acc[sigla_pacto] += electos
    return acc
  }, {} as Record<string, number>)

  return results
}

// Only for CORE y Concejales
export const getResultsByPacto2021CC = (file2021: Concejales2021 | Core2021) => {
  const territorios = Object.keys(file2021.electos_pacto)
  const results = territorios.reduce((acc, cod_comuna) => {
    file2021.electos_pacto[cod_comuna]?.forEach(({ sigla_pacto, electos }) => {
      if (!acc[sigla_pacto]) acc[sigla_pacto] = 0
      acc[sigla_pacto] += electos
    })
    return acc
  }, {} as Record<string, number>)

  return results
}
