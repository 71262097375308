import styled from 'styled-components'
import { useState } from 'react'
import Card from '@/components/Web/Card'
import ToggleSwitch from '@/components/Web/ToggleSwitch'
import ContentPercentageBar, { SupPercentageLabel } from './PercentageBar'
import { RankingCandidateBigBadge } from '@/components/Web/CandidateBadge'
import { numberWithDots } from '@/helpers/formatters'
import { colors } from '@/constants'
import { cn } from '@/lib/utils'
import { MobileRankingBar } from './PercentageBar'
import DecideChileMono from '../DecideChileMono'
import useRouting from '@/stores/useRouting'

export type Data = {
  pacto: string
  color: string
  darkColor: string
  imagen: string
  nombre: string
  partido: string
  votos: number
  porcentaje: number
  comuna: string
  monoLabel: string
  reelecto: boolean
  segunda_vuelta?: boolean
  region: string
  electo?: boolean
}

type Ranking = {
  data: Data[]
  option: 'top' | 'bottom'
}

const Element = ({
  isChecked,
  inner = false,
  item,
}: {
  isChecked: boolean
  inner?: boolean
  item: Data
}) => {
  const color = inner ? colors.whiteDch : item.color
  return isChecked ? (
    <LabelTag
      className={cn('text-[18px]', {
        'leading-[32px]': inner,
      })}
      $color={color}
    >
      <SupPercentageLabel percentage={item.porcentaje} />
    </LabelTag>
  ) : (
    <LabelTag
      className={cn('text-[15px]', {
        'leading-[32px]': inner,
      })}
      $color={color}
    >
      {numberWithDots(item.votos)}
    </LabelTag>
  )
}

function RankingItem({
  item,
  isChecked,
  maxVotos,
  maxPorcentage,
  monoLabel,
}: {
  item: Data
  isChecked: boolean
  maxVotos: number
  maxPorcentage: number
  monoLabel: string
}) {
  const porcentagetoShow = isChecked
    ? (item.porcentaje * 100) / maxPorcentage
    : (item.votos * 100) / maxVotos

  return (
    <div className="flex flex-col h-full items-center w-[79px]">
      <ContentPercentageBar
        className="text-[6px] w-full"
        maxHeight={56}
        minHeight={8}
        outerContent={
          <RankingCandidateBigBadge
            className="mb-1"
            electo={item}
            reelecto={item.reelecto}
            monoLabel={monoLabel}
            segundaVuelta={item.segunda_vuelta}
            winner={item.electo}
            full
          />
        }
        innerContent={<Element isChecked={isChecked} inner item={item} />}
        percentage={porcentagetoShow}
        color={item.color}
      />
      <BottomTag $color={item.color} $darkColor={item.darkColor}>
        {item.pacto}
      </BottomTag>
    </div>
  )
}

function BlankRankingItem({ item, isChecked }: { item: Data; isChecked: boolean }) {
  const porcentagetoShow = 10
  return (
    <div className="flex flex-col h-full items-center w-[79px]">
      <ContentPercentageBar
        className="text-[6px] w-full"
        maxHeight={56}
        minHeight={4}
        outerContent={
          <RankingCandidateBigBadge
            className="mb-1"
            electo={item}
            reelecto={item.reelecto}
            monoLabel={''}
            segundaVuelta={item.segunda_vuelta}
            full
            isBlank
          />
        }
        innerContent={<Element isChecked={isChecked} inner item={item} />}
        percentage={porcentagetoShow}
        color={item.color}
      />
      <BottomTag $color={item.color} $darkColor={item.darkColor}>
        {item.pacto}
      </BottomTag>
    </div>
  )
}

export default function Ranking({ data, option }: Ranking) {
  const [isChecked, setIsChecked] = useState(false)
  const { currentElection } = useRouting()

  const handleChange = () => {
    setIsChecked((prev) => !prev)
  }

  const multiplier = option === 'top' ? 1 : -1

  const sortData = [...data]
    .sort((a, b) => {
      if (!isChecked) {
        return (b.votos - a.votos) * multiplier
      }
      return (b.porcentaje - a.porcentaje) * multiplier
    })
    .slice(0, 10)

  const maxVotos =
    sortData.length > 0
      ? option === 'top'
        ? sortData[0].votos
        : sortData[sortData.length - 1].votos * 2
      : 1

  const maxPorcentage =
    sortData.length > 0
      ? option === 'top'
        ? sortData[0].porcentaje
        : sortData[sortData.length - 1].porcentaje * 2
      : 100

  const fakeItem = {
    pacto: '',
    color: '#4f4f4f',
    darkColor: '#999',
    imagen: '/userProfile.png',
    nombre: '',
    partido: '',
    votos: 0,
    porcentaje: 0,
    comuna: '',
    monoLabel: '',
    reelecto: false,
    region: '',
  }

  const title =
    option === 'top'
      ? !isChecked
        ? '10 candidatos con mayor número de votos'
        : '10 candidatos con mayor porcentaje de votos'
      : !isChecked
      ? '10 candidatos con menor número de votos'
      : '10 candidatos con menor porcentaje de votos'

  return (
    <Card
      title={title}
      toggle={
        <ToggleSwitch
          checkedLabel="N°"
          uncheckedLabel="%"
          checked={isChecked}
          handleToggle={handleChange}
        />
      }
    >
      <div className="h-auto xl:h-[360px] xl:mt-5">
        <DecideChileMono className="w-full start block xl:hidden mt-[-10px] mb-1" />
        <RankingGrid $number={data.length} className="hidden xl:flex">
          {sortData.length > 0 ? (
            <>
              {sortData.map((item, index) => (
                <RankingItem
                  key={index}
                  item={item}
                  isChecked={isChecked}
                  maxVotos={maxVotos}
                  maxPorcentage={maxPorcentage}
                  monoLabel={item.monoLabel}
                />
              ))}
              {sortData.length < 10 &&
                Array.from({ length: 10 - sortData.length }).map((_, index) => (
                  <BlankRankingItem isChecked={isChecked} key={index} item={fakeItem} />
                ))}
            </>
          ) : (
            Array.from({ length: 10 }).map((_, index) => (
              <BlankRankingItem isChecked={isChecked} key={index} item={fakeItem} />
            ))
          )}
        </RankingGrid>
        <div className="flex flex-col gap-2 xl:hidden">
          {sortData.length > 0 ? (
            sortData.map((item, index) => (
              <MobileRankingBar
                key={index}
                data={item}
                isChecked={isChecked}
                maxVotos={maxVotos}
                maxPorcentage={maxPorcentage}
              />
            ))
          ) : (
            <div>no hay data</div>
          )}
        </div>
        <div className="w-full flex justify-end items-center">
          <DecideChileMono />
        </div>
      </div>
    </Card>
  )
}

const RankingGrid = styled.div<{ $number: number }>`
  height: 100%;
  justify-content: space-between;
`

const BottomTag = styled.div.attrs<{ $color: string; $darkColor: string }>({
  className: 'text-[10px] h-[30px] w-full',
})`
  background-color: ${({ $color }) => `${$color}50`};
  color: ${({ $darkColor }) => $darkColor};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 12px;
  text-align: center;
  font-weight: 500;
`

const LabelTag = styled.div<{ $color: string }>`
  color: ${({ $color }) => $color};
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 5px;
`
