import { Territorios } from '@/constants'
import useMagicFrame from '@/stores/useMagicFrame'
import useRouting from '@/stores/useRouting'
import {
  circunscripcionesProvinciales,
  CircunscripcionProvincialData,
  ComunaData,
  comunas,
  RegionData,
  regiones,
  customOrderMetropolitana,
  GSComunas,
} from '@/stores/useTerritorios'
import { matchPath } from 'react-router-dom'
import { ONLY_REGION_OPTIONS } from '../Accordion'
import { ReturnButton } from './ReturnButton'
import { SubmenuOption } from './SubMenu'
import { ElectionType } from '@/types/Elections'

const getOptions = (
  options: ComunaData[] | RegionData[] | CircunscripcionProvincialData[] = regiones,
  selected?: string | number,
  getNavigateTo?: (region: string) => string | void,
  onClick?: (region: string) => (() => void) | void,
  closeMenu = true,
  sortBy = 'name'
) => {
  return options
    .map(({ nombre, codigo, nombreCorto, orden, ...rest }) => {
      const ordenMenu = 'ordenMenu' in rest ? rest.ordenMenu : 0
      const navigateTo = getNavigateTo ? getNavigateTo(codigo as string) : undefined
      return {
        key: String(codigo),
        name: nombreCorto || nombre,
        selected: selected === codigo,
        navigateTo,
        onClick: onClick ? () => onClick(String(codigo)) : undefined,
        closeMenu,
        ordenMenu: ordenMenu || orden,
      } as SubmenuOption
    })
    .sort((a, b) =>
      sortBy === 'name' ? a.name.localeCompare(b.name) : (a.ordenMenu || 0) - (b.ordenMenu || 0)
    )
}

export function setRegionesSubmenu() {
  const { currentRegion, currentPathname, currentComuna } = useRouting.getState()
  const { setSubMenuOptions } = useMagicFrame.getState()
  const splittedByRegion = currentPathname?.split(`${currentRegion}`)
  if (!splittedByRegion) return
  const preRegion = splittedByRegion[0]
  const postRegion = currentComuna ? '' : splittedByRegion[1]

  function getNavigateTo(territorio: string) {
    return `${preRegion}${territorio}${postRegion}`
  }
  function getOnClick(territorio: string) {
    const navigateTo = getNavigateTo(territorio)
    const onlyMacroTerritory = ONLY_REGION_OPTIONS.some(({ toNavigate }) => {
      return toNavigate && matchPath(toNavigate, navigateTo)
    })
    if (onlyMacroTerritory) return
    return () =>
      setComunasSubmenu({ from: 'region-comuna', onReturn: () => setRegionesSubmenu(), territorio })
  }

  const regionesProps = getOptions(
    regiones,
    currentRegion,
    getNavigateTo,
    getOnClick,
    true,
    'ordenMenu'
  )

  setSubMenuOptions({
    title: 'Regiones',
    options: regionesProps,
  })
}

export function setCircunscripcionProvincialSubmenu() {
  const { currentCircunscripcion, currentPathname } = useRouting.getState()
  const { setSubMenuOptions } = useMagicFrame.getState()
  const splittedByCircunscripcion = decodeURI(currentPathname || '').split(
    `${currentCircunscripcion}`
  )
  if (!splittedByCircunscripcion) return
  const preCircunscripcion = splittedByCircunscripcion[0]
  const postCircunscripcion = splittedByCircunscripcion[1]
  function getNavigateTo(circunscripcion: string) {
    return `${preCircunscripcion}${circunscripcion}${postCircunscripcion}`
  }
  const circunscripcionProvincialProps = getOptions(
    circunscripcionesProvinciales,
    currentCircunscripcion,
    getNavigateTo
  )
  setSubMenuOptions({
    title: 'Provincias',
    options: circunscripcionProvincialProps,
  })
}

export function setComunasSubmenu({
  from = 'region-comuna',
  onReturn,
  territorio,
}: {
  from?: Territorios
  onReturn?: () => void
  territorio?: string | number
}) {
  const { currentComuna, currentRegion, currentCircunscripcion, currentPathname, currentElection } =
    useRouting.getState()
  const { setSubMenuOptions } = useMagicFrame.getState()
  const currentTerritorioId = currentRegion || (currentCircunscripcion as string | number)
  const newTerritorioId = territorio || currentTerritorioId
  if (!currentTerritorioId) return
  const splittedByTerritorio = decodeURI(currentPathname || '').split(`/${currentTerritorioId}`)
  const isTerritorio = splittedByTerritorio && splittedByTerritorio.length >= 2
  const dataTerritorios = from === 'region-comuna' ? regiones : circunscripcionesProvinciales
  const dataTerritorio = dataTerritorios.find(
    (territorio) => String(territorio.codigo) === String(newTerritorioId)
  )
  function getNavigateTo(comuna: string) {
    const pathBase = isTerritorio ? splittedByTerritorio[0] : ''
    const postTerritorio = isTerritorio ? splittedByTerritorio[1] : ''
    const territorio = dataTerritorio?.codigo
    const newPath = `${pathBase}/${isTerritorio ? territorio : postTerritorio}/${comuna}`
    return newPath
  }
  const territorioComunasData = comunas.filter((comuna) =>
    dataTerritorio?.comunas.includes(comuna.codigo)
  )

  let comunasProps = getOptions(territorioComunasData, currentComuna, getNavigateTo)

  // Custom order to RM and Gran Santiago //
  if (newTerritorioId === 17 || newTerritorioId === 13) {
    const comunasWithoutOrder = comunasProps.filter(
      (comuna) => !Object.keys(customOrderMetropolitana).includes(comuna.key)
    )
    const comunasWithOrder = comunasProps
      .filter((comuna) => Object.keys(customOrderMetropolitana).includes(comuna.key))
      .map((comuna) => {
        return {
          ...comuna,
          ordenMenu: customOrderMetropolitana[parseInt(comuna.key)],
        }
      })
      .sort((a, b) => a.ordenMenu - b.ordenMenu)
    comunasProps = [...comunasWithOrder, ...comunasWithoutOrder]
  }

  setSubMenuOptions({
    title: 'Comunas',
    navigation: (
      <ReturnButton onClick={onReturn}>
        {currentElection === ElectionType.CORE ? 'Ir a provincias' : 'Ir a regiones'}
      </ReturnButton>
    ),
    options: comunasProps,
  })
}

export function setRegionesChileEnDatosSubmenu() {
  const { setSubMenuOptions } = useMagicFrame.getState()
  const { currentComuna } = useRouting.getState()
  const regionCurrentComuna = comunas.find((comuna) => comuna.codigo === currentComuna)?.region
  const regionesProps = getOptions(
    regiones,
    regionCurrentComuna,
    undefined,
    (region) => {
      return setComunasChileEnDatosSubmenu(region)
    },
    false,
    'ordenMenu'
  )
  setSubMenuOptions({
    title: 'Regiones',
    options: regionesProps,
  })
}

export const setComunasChileEnDatosSubmenu = (region?: string) => {
  const { setSubMenuOptions } = useMagicFrame.getState()
  const { currentComuna, currentPathname } = useRouting.getState()
  if (!currentComuna || !currentPathname) return
  const regionCurrentComuna =
    region || comunas.find((comuna) => comuna.codigo === currentComuna)?.region

  let comunasRegion = comunas.filter((comuna) => comuna.region === Number(regionCurrentComuna))

  // The GS 'comunas' do not include the region code 17 because GS is a subset of the RM region.
  // Therefore, when the region code is 17, we need to filter the 'comunas' using the GSComunas array.
  if (region === '17') {
    comunasRegion = comunas.filter((comuna) => GSComunas.includes(comuna.codigo))
  }

  const getNavigateTo = (comuna: string) => {
    return currentPathname.replace(`${currentComuna}`, comuna)
  }

  let comunasProps = getOptions(comunasRegion, currentComuna, getNavigateTo, undefined)

  // Custom order to RM and Gran Santiago //
  if (regionCurrentComuna === 13 || region === '13' || region === '17') {
    const comunasWithoutOrder = comunasProps.filter(
      (comuna) => !Object.keys(customOrderMetropolitana).includes(comuna.key)
    )
    const comunasWithOrder = comunasProps
      .filter((comuna) => Object.keys(customOrderMetropolitana).includes(comuna.key))
      .map((comuna) => {
        return {
          ...comuna,
          ordenMenu: customOrderMetropolitana[parseInt(comuna.key)],
        }
      })
      .sort((a, b) => a.ordenMenu - b.ordenMenu)
    comunasProps = [...comunasWithOrder, ...comunasWithoutOrder]
  }
  setSubMenuOptions({
    title: 'Comunas',
    options: comunasProps,
    navigation: <ReturnButton onClick={() => setRegionesChileEnDatosSubmenu()} />,
  })
}
