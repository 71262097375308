type IReelectoSVG = {
  id: string
  color: string
  className?: string
}

export default function ReelectoSVG({ id, color, className = '' }: IReelectoSVG) {
  return (
    <svg
      className={className}
      width="137"
      height="137"
      viewBox="0 0 137 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.748 0C8.62028 15.1278 0.0780493 35.6019 0.000532175 56.9182C-0.0769842 78.2346 8.31657 98.6471 23.3347 113.665C38.3529 128.683 58.7654 137.077 80.0817 136.999C101.398 136.922 121.872 128.38 137 113.252L118.013 94.2653C107.958 104.321 94.3487 109.999 80.1797 110.05C66.0108 110.102 52.4426 104.523 42.46 94.54C32.4775 84.5574 26.8983 70.9892 26.9498 56.8202C27.0013 42.6513 32.6793 29.0421 42.7348 18.9867L23.748 0Z"
        fill={`url(#${id})`}
      />
      <g filter="url(#filter0_d_14607_87794)">
        <path
          d="M8 69.86L18.65 67L19.59 70.52C19.82 71.39 19.88 72.12 19.78 72.71C19.67 73.31 19.42 73.79 19.02 74.17C18.62 74.54 18.11 74.82 17.48 74.98C16.5 75.24 15.67 75.17 14.99 74.76C14.31 74.35 13.8 73.64 13.46 72.62C13.39 72.39 13.32 72.15 13.25 71.91C13.18 71.67 13.13 71.46 13.08 71.28L12.91 70.65L8.53 71.83L8 69.86ZM9.55 75.65L13.45 71.74L14.23 73.69L10.16 77.93L9.55 75.65ZM14.68 70.17L15.04 71.52C15.13 71.86 15.25 72.15 15.4 72.41C15.55 72.67 15.75 72.86 16 72.98C16.25 73.1 16.55 73.11 16.92 73.01C17.3 72.91 17.56 72.75 17.71 72.52C17.86 72.29 17.93 72.03 17.92 71.74C17.91 71.45 17.86 71.12 17.77 70.79L17.41 69.45L14.69 70.18L14.68 70.17Z"
          fill="#FAFAFA"
        />
        <path
          d="M11.6 81.73L21.67 77.25L24.66 83.96L22.98 84.71L20.82 79.87L18.42 80.94L20.43 85.46L18.77 86.2L16.76 81.68L14.1 82.86L16.26 87.7L14.58 88.45L11.6 81.74V81.73Z"
          fill="#FAFAFA"
        />
        <path
          d="M16.76 92.41L26.03 86.44L30 92.61L28.46 93.6L25.59 89.14L23.38 90.56L26.06 94.72L24.53 95.7L21.85 91.54L19.4 93.12L22.27 97.58L20.73 98.57L16.76 92.4V92.41Z"
          fill="#FAFAFA"
        />
        <path
          d="M23.46 102.11L31.77 94.87L33.11 96.41L26.18 102.45L29.27 105.99L27.89 107.2L23.46 102.12V102.11Z"
          fill="#FAFAFA"
        />
        <path
          d="M30.69 109.93L37.87 101.56L43.44 106.34L42.25 107.73L38.23 104.28L36.52 106.27L40.28 109.49L39.1 110.87L35.34 107.65L33.45 109.86L37.47 113.31L36.28 114.7L30.71 109.92L30.69 109.93Z"
          fill="#FAFAFA"
        />
        <path
          d="M43.98 120.16C43.09 119.62 42.42 118.94 41.99 118.11C41.56 117.28 41.37 116.39 41.44 115.42C41.51 114.45 41.84 113.47 42.43 112.49C43.02 111.51 43.73 110.76 44.55 110.25C45.37 109.74 46.25 109.48 47.18 109.48C48.11 109.48 49.03 109.74 49.92 110.28C50.92 110.88 51.64 111.65 52.06 112.59C52.49 113.53 52.59 114.53 52.38 115.6L50.36 115.22C50.49 114.55 50.44 113.93 50.19 113.37C49.95 112.8 49.52 112.34 48.9 111.97C48.34 111.63 47.77 111.47 47.19 111.48C46.61 111.49 46.07 111.67 45.56 112.02C45.05 112.37 44.59 112.89 44.18 113.56C43.77 114.24 43.53 114.89 43.46 115.51C43.39 116.13 43.48 116.7 43.75 117.21C44.01 117.73 44.42 118.15 44.98 118.49C45.59 118.86 46.21 119.02 46.82 118.97C47.43 118.92 48.01 118.68 48.54 118.25L49.84 119.84C49 120.53 48.06 120.9 47.03 120.97C46 121.04 44.98 120.77 43.98 120.17V120.16Z"
          fill="#FAFAFA"
        />
        <path
          d="M54.51 125.14L57.87 116.58L54.69 115.33L55.36 113.62L63.64 116.87L62.97 118.58L59.8 117.34L56.44 125.9L54.51 125.14Z"
          fill="#FAFAFA"
        />
        <path
          d="M67.81 129.46C66.79 129.25 65.94 128.82 65.26 128.19C64.58 127.56 64.11 126.77 63.85 125.84C63.59 124.9 63.58 123.87 63.82 122.75C64.06 121.61 64.48 120.67 65.09 119.91C65.7 119.16 66.45 118.62 67.32 118.32C68.2 118.01 69.15 117.97 70.17 118.18C71.2 118.4 72.06 118.82 72.74 119.45C73.42 120.08 73.89 120.87 74.15 121.8C74.41 122.74 74.42 123.77 74.18 124.91C73.95 126.04 73.52 126.97 72.9 127.73C72.28 128.48 71.54 129.01 70.66 129.32C69.78 129.63 68.83 129.67 67.81 129.46ZM68.2 127.54C68.86 127.68 69.46 127.64 69.99 127.44C70.52 127.24 70.98 126.88 71.35 126.38C71.72 125.88 71.99 125.25 72.15 124.49C72.31 123.73 72.32 123.04 72.18 122.43C72.04 121.82 71.76 121.31 71.36 120.91C70.95 120.51 70.42 120.24 69.76 120.1C69.13 119.97 68.54 120 68 120.2C67.46 120.4 67 120.75 66.63 121.26C66.26 121.76 65.99 122.4 65.83 123.18C65.67 123.94 65.66 124.63 65.79 125.24C65.93 125.85 66.2 126.36 66.62 126.76C67.04 127.16 67.56 127.43 68.19 127.56L68.2 127.54Z"
          fill="#FAFAFA"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_14607_87794"
          x="8"
          y="67"
          width="66.3516"
          height="63.5918"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14607_87794" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_14607_87794"
            result="shape"
          />
        </filter>
        <linearGradient id={id} x1="-0.1" y1="1.2" x2="1" y2="0">
          <stop offset="50%" stop-color={color} stop-opacity="1" />
          <stop offset="60%" stop-color={color} stop-opacity="0" />
          <stop offset="100%" stop-color={color} stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
