import Ranking from '@/components/Web/Ranking'
import useElectionData from '@/stores/useElectionData'
import { jointCandidatosColor } from '@/lib/dataProcess'
import { useMemo } from 'react'
import { PACTOS } from '@/constants/pactos'
import { PARTIDOS } from '@/constants/pactos'
import { ElectionType } from '@/types/Elections'
import { regiones } from '@/stores/useTerritorios'

export default function GRanking({ option = 'top' }: { option?: 'top' | 'bottom' }) {
  const {
    files: { gore },
  } = useElectionData()

  const electos = useMemo(() => {
    return gore || []
  }, [gore])

  const processedData = useMemo(() => {
    if (electos.length === 0) {
      return []
    }
    return jointCandidatosColor(electos, ElectionType.GORE).map((electo) => {
      return {
        ...electo,
        partido: PARTIDOS[electo.cod_partido]?.shortName,
        pacto: PACTOS[ElectionType.GORE][electo.cod_pacto]?.shortName || electo.cod_pacto,
        imagen: electo.imagen || '/userProfile.png',
        monoLabel:
          regiones.find((region) => region.codigo === Number(electo.cod_region))?.nombreCorto ||
          regiones.find((region) => region.codigo === Number(electo.cod_region))?.nombre ||
          '',
      }
    })
  }, [electos])

  return <Ranking data={processedData} option={option} />
}
