import ContentLayout from '@/app/Web/ContentLayout'
import { electionParams } from '@/constants'
import { ElectionType } from '@/types/Elections'
import { redirect, RouteObject } from 'react-router-dom'
import {
  ACambioPacto,
  AElectosTab,
  AElectosVersus,
  AMantienePacto,
  APactoElectos,
  APactos,
  ARanking,
  AReelecto,
} from './Alcaldes'
import {
  GoreGanadores,
  GoreComparacion,
  GoreRanking,
  GorePactos,
  GoreCambioPacto,
  GoreMantienePacto,
  GoreReelectos,
} from '@/app/Web/GORE'
import LayoutTerritorio from './LayoutTerritorio'
import AComparacion from './Alcaldes/Comparacion'
import CPactos from './Concejales/CElectosPactos'
import CComparacion from './Concejales/CComparacion'
import CElectoComuna from './Concejales/CElectosComuna'
import CPactoRegion from './Concejales/CPactoRegion'
import CRanking from './Concejales/CRanking'
import CoreComparacion from './CORE/CoreComparacion'
import CorePactos from './CORE/CorePactos'
import CorePactosProvincia from './CORE/CorePactosProvincia'
import CoreRanking from './CORE/CoreRanking'
import GoreElectosRegion from './GORE/GoreElectosRegion'
import GoreVotacionComuna from './GORE/GoreVotacionComuna'
import DatosComunales from './DatosComunales'
import ErrorPage from '@/components/Web/ErrorPage'
import CoreCandidatoProvincia from './CORE/CoreCandidatoProvincia'
export default [
  {
    path: '/',
    element: <ContentLayout />,
    children: [
      {
        loader: () => redirect(`/${ElectionType.GORE}`),
        index: true,
      },
      {
        path: 'datos-comunales',
        loader: () => redirect(`/datos-comunales/poblacion/genero_edad/13101`),
      },
      {
        path: 'datos-comunales',
        children: [
          {
            path: ':tema/:subtema/:comuna',
            element: <DatosComunales />,
          },
        ],
      },
      {
        path: `${ElectionType.ALCALDES}`,
        children: [
          {
            index: true,
            element: <APactos />,
          },
          {
            path: 'comparacion',
            element: <AComparacion />,
          },
          {
            path: 'ranking',
            children: [
              {
                index: true,
                element: <ARanking />,
              },
              {
                path: 'top',
                element: <ARanking option="top" />,
              },
              {
                path: 'bottom',
                element: <ARanking option="bottom" />,
              },
            ],
          },
        ],
      },
      {
        path: `${ElectionType.ALCALDES}/territorio`,
        loader: () =>
          redirect(
            `/${ElectionType.ALCALDES}/territorio/${
              electionParams[ElectionType.ALCALDES].defaultTerritorio
            }/pactos`
          ),
      },
      {
        path: `${ElectionType.ALCALDES}/territorio`,
        element: <LayoutTerritorio />,
        children: [
          {
            path: ':region',
            element: <AElectosTab />,
          },
          {
            path: ':region/pactos',
            element: <APactoElectos />,
          },
          {
            path: ':region/cambio-pacto',
            element: <ACambioPacto />,
          },
          {
            path: ':region/mantiene-pacto',
            element: <AMantienePacto />,
          },
          {
            path: ':region/reelectos',
            element: <AReelecto />,
          },
          {
            path: ':region/:comuna',
            element: <AElectosVersus />,
          },
        ],
      },
      {
        path: `${ElectionType.ALCALDES}`,
        loader: () =>
          redirect(
            `/${ElectionType.ALCALDES}/${electionParams[ElectionType.ALCALDES].defaultTerritorio}`
          ),
      },
      {
        path: `${ElectionType.GORE}`,
        children: [
          {
            index: true,
            element: <GorePactos />,
          },
          {
            path: 'comparacion',
            element: <GoreComparacion />,
          },
          {
            path: 'ganadores',
            element: <GoreGanadores />,
          },
          {
            path: 'ranking',
            children: [
              {
                index: true,
                element: <GoreRanking />,
              },
              {
                path: 'top',
                element: <GoreRanking option="top" />,
              },
              {
                path: 'bottom',
                element: <GoreRanking option="bottom" />,
              },
            ],
          },
          {
            path: 'cambio-pacto',
            element: <GoreCambioPacto />,
          },
          {
            path: 'mantiene-pacto',
            element: <GoreMantienePacto />,
          },
          {
            path: 'reelectos',
            element: <GoreReelectos />,
          },
        ],
      },
      {
        path: `${ElectionType.GORE}/territorio`,
        loader: () =>
          redirect(
            `/${ElectionType.GORE}/territorio/${
              electionParams[ElectionType.GORE].defaultTerritorio
            }`
          ),
      },
      {
        path: `${ElectionType.GORE}/territorio`,
        element: <LayoutTerritorio />,
        children: [
          {
            path: ':region',
            element: <GoreElectosRegion />,
          },
          {
            path: ':region/:comuna',
            element: <GoreVotacionComuna />,
          },
        ],
      },
      {
        path: `${ElectionType.CONCEJALES}`,
        children: [
          {
            index: true,
            element: <CPactos />,
          },
          {
            path: 'comparacion',
            element: <CComparacion />,
          },
          {
            path: 'ranking',
            children: [
              {
                index: true,
                element: <CRanking />,
              },
              {
                path: 'top',
                element: <CRanking option="top" />,
              },
              {
                path: 'bottom',
                element: <CRanking option="bottom" />,
              },
            ],
          },
        ],
      },
      {
        path: `${ElectionType.CONCEJALES}/territorio`,
        loader: () =>
          redirect(`${electionParams[ElectionType.CONCEJALES].defaultTerritorio}/pactos`),
      },
      {
        path: `${ElectionType.CONCEJALES}/territorio`,
        element: <LayoutTerritorio />,
        children: [
          {
            path: ':region/:comuna',
            element: <CElectoComuna />,
          },
          {
            path: ':region/pactos',
            element: <CPactoRegion />,
          },
        ],
      },
      {
        path: `${ElectionType.CORE}`,
        children: [
          {
            index: true,
            element: <CorePactos />,
          },
          {
            path: 'comparacion',
            element: <CoreComparacion />,
          },
          {
            path: 'ranking',
            children: [
              {
                index: true,
                element: <CoreRanking />,
              },
              {
                path: 'top',
                element: <CoreRanking option="top" />,
              },
              {
                path: 'bottom',
                element: <CoreRanking option="bottom" />,
              },
            ],
          },
        ],
      },
      {
        path: `${ElectionType.CORE}/territorio`,
        loader: () => redirect(`${electionParams[ElectionType.CORE].defaultTerritorio}`),
      },
      {
        path: `${ElectionType.CORE}/territorio`,
        element: <LayoutTerritorio />,
        children: [
          {
            path: ':circunscripcion',
            element: <CoreCandidatoProvincia />,
          },
          {
            path: ':circunscripcion/pactos',
            element: <CorePactosProvincia />,
          },
          {
            path: ':circunscripcion/:comuna',
            loader: ({ params }) => {
              const { circunscripcion } = params
              return redirect(`/${ElectionType.CORE}/territorio/${circunscripcion}/`)
            },
          },
        ],
      },
      {
        path: '*',
        element: <ErrorPage />,
      },
    ],
  },
] as RouteObject[]
