import { useEffect, useState } from 'react'
import styled from 'styled-components'
import ToggleSwitch from '../ToggleSwitch'
import PaginatedCard from '../PaginatedCard'
import { CandidateBadgeWithCheck } from '../CandidateBadget'
import { colors } from '@/constants'
import { Progress } from '@/components/ui/progress'

export type Electo = {
  region: string
  imagen: string
  nombre: string
  porcentaje: number
  sigla_partido: string
  pacto: string
  color: string
  cod_region: number
  order: number
}

export type Data = {
  electos: Electo[]
  segunda_vuelta: {
    electos: Electo[]
    region: string
    order: number
  }[]
}

export default function Ganadores({ electos, segunda_vuelta }: Data) {
  const [isElectos, setIsElectos] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)

  const pageSize = isElectos ? 6 : 4
  const title = isElectos ? 'Electos' : 'Segunda Vuelta'

  const data = isElectos ? electos : segunda_vuelta
  const paginatedData = data.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
  const totalPages = isElectos
    ? Math.ceil(electos.length / pageSize)
    : Math.ceil(segunda_vuelta.length / pageSize)

  const handleChangeView = () => {
    setIsElectos((prev) => !prev)
  }

  useEffect(() => {
    setCurrentPage(0)
  }, [isElectos])

  return (
    <PaginatedCard
      title={title}
      separatorColor="#CCCCCC"
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      totalPages={totalPages}
      toggle={
        <ToggleSwitch
          checkedLabel="2DA VUELTA"
          uncheckedLabel="ELECTOS"
          checked={isElectos}
          handleToggle={handleChangeView}
        />
      }
    >
      <Wrapper>
        {isElectos ? (
          <Grid rows={3}>
            {paginatedData.map((item, index) => {
              if ('electos' in item) return null
              return (
                <div className="flex flex-col mgw:gap-5 gap-3" key={`2nd-${item.region}-${index}`}>
                  <RegionText>{item.region}</RegionText>
                  <CandidateInformation candidate={item} winner />
                </div>
              )
            })}
          </Grid>
        ) : (
          <Grid rows={2}>
            {paginatedData.map((item, index) => {
              if (!('electos' in item)) return null
              const sortedElectos = item.electos.sort((a, b) => b.porcentaje - a.porcentaje)
              return (
                <CellGrid2ndRound key={`2nd-${item.region}-${index}`}>
                  <RegionText>{item.region}</RegionText>
                  <div className="flex flex-col mgw:gap-6 gap-4">
                    {sortedElectos.map((electo, index) => (
                      <CandidateInformation
                        key={`2ndround-${electo.region}-${index}`}
                        candidate={electo}
                      />
                    ))}
                  </div>
                </CellGrid2ndRound>
              )
            })}
          </Grid>
        )}
      </Wrapper>
    </PaginatedCard>
  )
}

function CandidateInformation({
  candidate,
  winner = false,
}: {
  candidate: Electo
  winner?: boolean
}) {
  return (
    <div className="flex justify-between items-start">
      <CandidateBadgeWithCheck
        winner={winner}
        imagen={candidate.imagen}
        color={candidate.color}
        upperCandidateLabel={<UpperContent>{candidate.nombre}</UpperContent>}
        lowerCandidateLabel={
          <LowerContent>
            {candidate.pacto} - {candidate.sigla_partido}
          </LowerContent>
        }
      />
      <div className="flex items-center">
        <div className="font-semibold text-[32px]">
          {candidate.porcentaje.toFixed(1).toString().replace('.', ',')}
        </div>
        <sup className="font-semibold text-[22px] mt-[16px]">%</sup>
        <Progress
          value={Number(candidate.porcentaje)}
          className="w-20 h-[28px] ml-3 bg-[#BDBDBD] rounded-tr-[3px] rounded-br-[3px]"
          indicatorColor={candidate.color}
        />
      </div>
    </div>
  )
}

const Wrapper = styled.div.attrs({
  className: 'mgw:py-5 mgw:px-10 py-3 px-10',
})`
  height: 100%;
  width: 100%;
  color: ${colors.blackDch};
`

const Grid = styled.div.attrs<{ rows: number }>({
  className: 'mgw:gap-x-[80px] mgw:pr-[40px] gap-x-[40px] gap-y-[20px] pr-[10px]',
})`
  display: grid;
  grid-template-columns: repeat(2, minmax(550px, 1fr));
  grid-template-rows: repeat(${({ rows }) => rows}, minmax(0, 1fr));
  height: 100%;
  width: 100%;
`

const CellGrid2ndRound = styled.div.attrs({
  className: 'mgw:gap-5 gap-3',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const UpperContent = styled.div.attrs({
  className: 'mgw:text-[26px] mgw:leading-[41px] text-[22px] leading-[35px]',
})`
  width: 100%;
  font-weight: 600;
`

const LowerContent = styled.div.attrs({
  className: 'mgw:text-[24px] mgw:leading-[31px] text-[20px] leading-[26px]',
})`
  width: 100%;
  font-weight: 400;
`

const RegionText = styled.div.attrs({
  className: 'mgw:text-[28px] mgw:leading-[36px] text-[24px] leading-[31px]',
})`
  width: 100%;
  font-weight: 600;
  border-bottom: 2px solid #dcdcdc;
`
