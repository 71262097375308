import ElectosTab from '@/components/MagicWall/ElectosTab'
import useTerritorios, { comunas } from '@/stores/useTerritorios'
import useRouting from '@/stores/useRouting'
import useElectionData from '@/stores/useElectionData'
import { useEffect, useMemo } from 'react'
import { jointCandidatosColor } from '@/lib/dataProcess'
import { ElectionType } from '@/types/Elections'
import { PACTOS } from '@/constants'

export default function CJElectosComuna() {
  const { currentComuna } = useRouting()
  const comuna = comunas.find((comuna) => comuna.codigo === currentComuna)
  const {
    files: { concejales },
  } = useElectionData()
  const { comunasColorDict, setComunaColor } = useTerritorios()

  const orderedConcejales = useMemo(
    () =>
      concejales
        ? concejales
            .filter((c) => c.cod_comuna.toString() === (currentComuna ?? '').toString() && c.electo)
            .sort((a, b) => b.porcentaje - a.porcentaje)
        : [],
    [concejales, currentComuna]
  )

  const processedData = useMemo(() => {
    if (orderedConcejales.length === 0) {
      return []
    }
    return jointCandidatosColor(orderedConcejales, ElectionType.CONCEJALES).map((electo, i) => ({
      ...electo,
      pacto: PACTOS[ElectionType.CONCEJALES][electo.cod_pacto]?.name || electo?.cod_pacto,
      order: i + 1,
      imagen: electo?.imagen || '/userProfile.png',
    }))
  }, [orderedConcejales])

  useEffect(() => {
    if (!comunasColorDict[currentComuna as number] && processedData.length > 0) {
      setComunaColor({
        ...comunasColorDict,
        [currentComuna as number]: processedData.length > 1 ? processedData[0]?.color : '',
      })
    }
  }, [comunasColorDict, setComunaColor, processedData, currentComuna])

  return <ElectosTab data={processedData} pageSize={5} title={comuna?.nombre || ''} />
}
