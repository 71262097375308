import useRouting from '@/stores/useRouting'
import { useNavigate } from 'react-router-dom'

import MantienePacto from '@/components/Web/MantienePacto'
import useTerritorios, { regiones } from '@/stores/useTerritorios'

import RegionSelector from '@/components/RegionSelector'
import { ElectionType } from '@/types/Elections'
import { buildCambioMantienePactoAlcalde } from '@/lib/buildDataViews'
import useElectionData from '@/stores/useElectionData'
import { useMemo } from 'react'

export default function AMantienePacto() {
  const navigate = useNavigate()
  const { currentRegion } = useRouting()

  const {
    files: { alcaldes },
    comparador: { alcaldes: alcaldes2021 },
  } = useElectionData()

  const { setComunaColor } = useTerritorios()

  const data = useMemo(() => {
    const region = regiones.find((r) => r.codigo === currentRegion)
    if (!alcaldes || !alcaldes2021 || !region) {
      return []
    }
    const { data, comunasColor } = buildCambioMantienePactoAlcalde(
      region,
      alcaldes,
      alcaldes2021,
      'mantiene',
      15
    )

    setComunaColor(comunasColor)
    return data.sort((a, b) => a.comuna.localeCompare(b.comuna))
  }, [alcaldes, alcaldes2021, currentRegion, setComunaColor])

  const handleRegion = (region: string) => {
    navigate(`/${ElectionType.ALCALDES}/territorio/${region}/mantiene-pacto`)
  }
  return (
    <MantienePacto
      selection={
        <RegionSelector
          className="w-[50%] hidden xl:flex"
          onClick={handleRegion}
          value={String(currentRegion)}
        />
      }
      data={data}
    />
  )
}
