import CircleMesh from '@/components/Iframe/CircleMesh/CircleMesh'
import PactoInfo from '@/components/Iframe/PactoInfo'
import styled from 'styled-components'
import { colors } from '@/constants/colors'
import { Fragment } from 'react/jsx-runtime'
import { Candidato } from '@/types/Files'
import { useMemo } from 'react'
import { getResultsByPacto } from '@/lib/dataProcess'
import { ElectionType } from '@/types/Elections'
import { buildPactosViewData } from '@/lib/buildDataViews'

type PartidoResult = {
  nombre_partido: string
  cod_partido: string
  electos: number
}

type PactoResult = {
  nombre: string
  abreviatura: string
  color: string
  electos: number
  porcentaje: number
  votos: number
  orden: number
  electos_partido: PartidoResult[]
}

function generateCircles(data: PactoResult[], totalCircles: number) {
  const circles: { color: string; key: string }[] = []
  data.forEach(({ color, electos, abreviatura }) => {
    for (let i = 0; i < electos; i++) {
      circles.push({ color, key: `${abreviatura}-${i.toString()}` })
    }
  })
  if (circles.length < totalCircles) {
    for (let i = circles.length; i < totalCircles; i++) {
      circles.push({ color: colors.gray2Dch, key: `empty-${i.toString()}` })
    }
  }
  return circles
}

export default function AlcaldesPactos({ summaryAlcaldes }: { summaryAlcaldes: Candidato[] }) {
  const data = useMemo(() => {
    const resultByPactos = getResultsByPacto(summaryAlcaldes || [])
    return buildPactosViewData(resultByPactos, ElectionType.ALCALDES, false)
  }, [summaryAlcaldes])

  const circles = useMemo(() => {
    const orderedData = data.sort((a, b) => a.orden - b.orden)
    return generateCircles(orderedData, 345)
  }, [data])

  return (
    <div className="flex flex-col flex-grow items-center justify-evenly">
      <CircleMesh circles={circles} nrows={10} ncolumns={35} gap="2px" width="10px" />
      <div className="flex relative px-5 ifr:px-6 gap-[13px] sifr:gap-[15px] ifr:gap-[32px] gap-y-[32px] ifr:gap-y-[44px] flex-wrap justify-center">
        {data.map((pacto, index) => (
          <Fragment key={`pactoGore-${pacto.cod_pacto}`}>
            <PactoInfo
              key={`pactoInfo-${pacto.cod_pacto}`}
              color={pacto.color}
              electos={pacto.electos}
              name={pacto.nombre}
              votos={pacto.votos}
              isGobernadorView={false}
            />
            {index === 5 && <VerticalDivider />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

const VerticalDivider = styled.div.attrs({
  className:
    'h-[80px] sifr:h-[100px] ifr:h-[124px] absolute top-[102px] sifr:top-[118px] ifr:top-[150px]',
})`
  width: 1px;
  background-color: ${colors.gray5Dch};
`
