import Comparacion, { type Data } from '@/components/MagicWall/Comparacion'
import { getResultsByPacto } from '@/lib/dataProcess'
import useElectionData from '@/stores/useElectionData'
import { ElectionType } from '@/types/Elections'
import { useMemo } from 'react'
import { buildComparacionData } from '@/lib/buildDataViews'
import { getResultsByPacto2021AG } from '@/lib/dataProcess2021'

export default function GComparacion() {
  const {
    files: { gore },
    comparador: { gore: gore2021 },
  } = useElectionData()

  const data: Data[] = useMemo(() => {
    const resultsByPacto2024 = getResultsByPacto(gore || [])
    const resultsByPacto2021 = gore2021 ? getResultsByPacto2021AG(gore2021) : {}

    return buildComparacionData(resultsByPacto2024, resultsByPacto2021, ElectionType.GORE)
  }, [gore, gore2021])

  return <Comparacion data={data} verticalSeparatorBefore="Otros" />
}
