import { ElectionType } from '@/types/Elections'
import { create } from 'zustand'

type RoutingState = {
  currentRegion?: number
  currentComuna?: number
  currentCircunscripcion?: string
  currentElection?: ElectionType
  currentPathname?: string
  currentTema?: string
  currentSubtema?: string
  isInTerritorio?: boolean
  isMagicChileEnDatos?: boolean
  isIframe?: boolean
  setIsIframe: (isIframe: boolean) => void
  setSelectedRegion: (region?: number) => void
  setSelectedComuna: (comuna?: number) => void
  setSelectedCircunscripcion: (circunscripcion?: string) => void
  setCurrentElection: (election?: ElectionType) => void
  setIsInTerritorio: (isInTerritorio: boolean) => void
  setCurrentPathname: (pathname: string) => void
  setIsMagicChileEnDatos: (isMagicChileEnDatos: boolean) => void
  setCurrentTema: (tema?: string) => void
  setCurrentSubtema: (subtema?: string) => void
}

const useRouting = create<RoutingState>((set) => ({
  setIsIframe: (isIframe: boolean) => set({ isIframe }),
  setSelectedRegion: (region?: number) =>
    set({ currentRegion: Number.isNaN(region) ? undefined : region }),
  setSelectedComuna: (comuna?: number) =>
    set({ currentComuna: Number.isNaN(comuna) ? undefined : comuna }),
  setSelectedCircunscripcion: (circunscripcion?: string) =>
    set({ currentCircunscripcion: circunscripcion }),
  setCurrentElection: (election?: ElectionType) => set({ currentElection: election }),
  setIsInTerritorio: (isInTerritorio: boolean) => set({ isInTerritorio }),
  setCurrentPathname: (pathname: string) => set({ currentPathname: pathname }),
  setIsMagicChileEnDatos: (isMagicChileEnDatos: boolean) => set({ isMagicChileEnDatos }),
  setCurrentTema: (tema?: string) =>
    set(({ isMagicChileEnDatos }) => ({ currentTema: isMagicChileEnDatos ? tema : undefined })),
  setCurrentSubtema: (subtema?: string) =>
    set(({ isMagicChileEnDatos }) => ({
      currentSubtema: isMagicChileEnDatos ? subtema : undefined,
    })),
}))

export default useRouting
