import Comparacion, { type Data } from '@/components/Web/Comparacion'
import useElectionData from '@/stores/useElectionData'
import { useMemo } from 'react'
import { getResultsByPacto } from '@/lib/dataProcess'
import { buildComparacionData } from '@/lib/buildDataViews'
import { ElectionType } from '@/types/Elections'
import { getResultsByPacto2021CC } from '@/lib/dataProcess2021'

export default function CoreComparacion() {
  const {
    files: { core },
    comparador: { core: core2021 },
  } = useElectionData()

  const data: Data[] = useMemo(() => {
    const resultsByPacto2024 = getResultsByPacto(core || [])
    const resultsByPacto2021 = core2021 ? getResultsByPacto2021CC(core2021) : {}

    return buildComparacionData(resultsByPacto2024, resultsByPacto2021, ElectionType.CORE, true)
  }, [core2021, core])

  return <Comparacion data={data} verticalSeparatorBefore="Otros" />
}
