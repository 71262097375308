export function voteToMillionString(votos: number) {
  if (votos < 999) {
    return votos.toString()
  } else if (votos < 999999) {
    return (votos / 1000).toFixed(1) + ' K'
  } else return (votos / 1000000).toFixed(1) + ' M'
}

// Due to variations in the spelling of 'circunscripción' across files
// where it serves as a key, we need to create a function to compare them.
// For other versions, we should generate a unique ID for each
export function removeTildesExceptN(text: string) {
  if (text === 'Antártica') return 'Antartica Chilena'
  if (text === 'Aysén') return 'Aysen'
  return text.normalize('NFD').replace(/(?![nN])[\u0300-\u036f]/g, '')
}

export function compareCORECircunscripcion(current: string, other: string) {
  if (current === 'Antártica' || current === 'Aysén') {
    const dict = {
      Antártica: 'Antartica Chilena',
      Aysén: 'Aisen',
    }
    const otherOption = dict[current] || ''
    return (
      removeTildesExceptN(current.toLowerCase()) === removeTildesExceptN(other.toLowerCase()) ||
      removeTildesExceptN(current.toLowerCase()) ===
        removeTildesExceptN(otherOption.toLowerCase()) ||
      removeTildesExceptN(otherOption.toLowerCase()) === removeTildesExceptN(other.toLowerCase())
    )
  }
  return removeTildesExceptN(current.toLowerCase()) === removeTildesExceptN(other.toLowerCase())
}

export function numberToPercent(
  number: number,
  fixed = 2,
  symbol = true,
  hideTrailingZero = false
): string {
  let formattedNumber = number.toFixed(fixed).replace('.', ',')
  if (hideTrailingZero) {
    formattedNumber = formattedNumber.replace(/,?0+$/, '')
  }
  return formattedNumber + (symbol ? '%' : '')
}

export function numberWithDots(number: number) {
  return new Intl.NumberFormat('es-CL').format(number)
}

const formatWord = (word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()

// Format Nombre Apellido A.
export function formatFullName(name: string) {
  const listName = name.trim().split(' ')
  const secondLastName = listName.pop() || ''
  const firstLastName = listName.pop() || ''
  const firstName = listName[0] || ''

  return `${formatWord(firstName)} ${formatWord(firstLastName)} ${secondLastName.charAt(0)}.`
}

// Format N. Apellido A.
export function formatNameLastName(name: string, includInitial = true) {
  const listName = name.trim().split(' ')
  const secondLastName = listName.pop() || ''
  const firstLastName = listName.pop() || ''
  const firstName = listName[0] || ''

  return includInitial
    ? `${formatWord(firstName).charAt(0)}. ${formatWord(firstLastName)} ${secondLastName.charAt(
        0
      )}.`
    : `${formatWord(firstName).charAt(0)}. ${formatWord(firstLastName).slice(0, 12)} ${
        firstLastName.length > 12 ? '.' : ''
      }`
}

export function hexToRGBA(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`
}

export function numberToShortVotes(value: number): string {
  if (value === 1) return '1 voto'
  if (value < 1000) return `${value} votos`
  if (value < 1000000) return `${(value / 1000).toFixed(1).replace(/\.0$/, '')}K votos`
  return `${(value / 1000000).toFixed(1).replace(/\.0$/, '')}M votos`
}
